import {
    message,
    Tabs,
    Select,
    PageHeader,
    Spin,
    Row,
    Col,
    Space,
    TreeSelect,
    Popconfirm,
} from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
    Form,
    FormItem,
    ButtonItem,
    InputItem,
    DatePickerItem,
    SelectItem,
    InputNumberItem,
    round10Decimals,
    round2Decimals,
    dineroDisabledMask,
    TableItem,
} from "../../../../Components/Items";
import moment from "moment";
import { CalculatorOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";


const { TabPane } = Tabs;
const { Option } = Select;


class PresupuestoPU extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        buscarInfoCode: false,
        archivosPrincipal: [],
        archivosTecnica: [],
        archivosFotos: [],
        todasMatrices: null,
        objetoMatrices: null,
        loadingExplosion: false,
        loadingTabla: false,
        todasInsumos: null,
        objetoInsumos: null,
        todasProyectos: null,
        objetoProyectos: null,
        insumosExplosionados: [],
        costoTotalIE: 0,
        partidasArbol: [],
        partidas: [],
        keyEditing: '',
        expandedRowKeys: [],
        confirmado: false,
        id: this.props?.location?.state?.id,
        valueExplosion: []
    };
    atras() {
        this.props.history.goBack();
    }
    componentDidMount() {
        this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "presupuestosPU/selectDatosParaPresupuestoPU",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasMatrices: res.data.matrices,
                        todasInsumos: res.data.insumos,
                        todasProyectos: res.data.proyectos,
                        objetoMatrices: res.data.matrices.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar + ' ' + dineroDisabledMask(value.costo)}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    })
                }
            });
        if (this.state.id) {
            await axios
                .post(
                    "presupuestosPU/datosPresupuesto",
                    { empresaId: this.props.empresaId, presupuestoId: this.state.id },
                    { headers: this.props.headersToken }
                )
                .then((res) => {
                    if (res.data.success === 2) {
                        this.formRef.current.setFieldsValue({
                            ...res.data.presupuesto,
                            fechaInicio: moment(res.data.presupuesto.fechaInicio),
                            fechaFinal: moment(res.data.presupuesto.fechaFinal)
                        });
                        const nuevasPartidas = res.data.presupuesto.confirmado ? res.data.presupuesto.partidas : this.calcularPresupuesto(res.data.presupuesto.partidas)
                        this.setState({
                            partidas: nuevasPartidas,
                            confirmado: res.data.presupuesto.confirmado
                        })
                    } else {
                        message.error("Error no se encontro informacion del presupuesto");
                    }
                });
        }
        this.setState({ loading: false });
    }

    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }

    onFinish(values) {
        const partidas = [...this.state.partidas]
        if (partidas) {
            for (const [indexP1, valueP1] of partidas.entries()) {
                if (valueP1.children) {
                    for (const [indexP2, valueP2] of valueP1.children.entries()) {
                        if (valueP2.children) {
                            for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                if (valueP3.children) {
                                    for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                        if (valueP4.children) {
                                            for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                if (valueP5.children) {
                                                    for (const [indexP6, valueP6] of valueP5.children.entries()) {
                                                        if (!valueP6.nombre || !valueP6.cantidad || valueP6.cantidad === 0 || (valueP6.tipo !== 0 && valueP6.tipo !== 1 && !valueP6.insumoMatriz)) {
                                                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1) + '-' + (indexP5 + 1) + '-' + (indexP6 + 1))
                                                        }
                                                    }
                                                }
                                                if (!valueP5.nombre || !valueP5.cantidad || valueP5.cantidad === 0 || (valueP5.tipo !== 0 && valueP5.tipo !== 1 && !valueP5.insumoMatriz)) {
                                                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1) + '-' + (indexP5 + 1))
                                                }
                                            }
                                        }
                                        if (!valueP4.nombre || !valueP4.cantidad || valueP4.cantidad === 0 || (valueP4.tipo !== 0 && valueP4.tipo !== 1 && !valueP4.insumoMatriz)) {
                                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1) + '-' + (indexP4 + 1))
                                        }
                                    }
                                }
                                if (!valueP3.nombre || !valueP3.cantidad || valueP3.cantidad === 0 || (valueP3.tipo !== 0 && valueP3.tipo !== 1 && !valueP3.insumoMatriz)) {
                                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1) + '-' + (indexP3 + 1))
                                }
                            }
                        }
                        if (!valueP2.nombre || !valueP2.cantidad || valueP2.cantidad === 0 || (valueP2.tipo !== 0 && valueP2.tipo !== 1 && !valueP2.insumoMatriz)) {
                            return message.warning('Falta un dato en la partida: ' + (indexP1 + 1) + '-' + (indexP2 + 1))
                        }
                    }
                }
                if (!valueP1.nombre || !valueP1.cantidad || valueP1.cantidad === 0 || (valueP1.tipo !== 0 && valueP1.tipo !== 1 && !valueP1.insumoMatriz)) {
                    return message.warning('Falta un dato en la partida: ' + (indexP1 + 1))
                }
            }
        }
        this.setState({ loading: true });
        if (this.state.id) {
            axios
                .post("presupuestosPU/editarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha editado un presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede editar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se edito");
                    }
                });
        } else {
            axios
                .post("presupuestosPU/agregarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha agregado un presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede agregar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se agrego");
                    }
                });
        }
    }

    // agregarAPartida1(key1) {
    //     const partidas = this.formRef.current.getFieldValue('partidas')

    //     const partidasDentro = partidas[key1].partidas ? partidas[key1].partidas.length : 0

    //     if (partidasDentro > 0) {
    //         partidas[key1].partidas.push({
    //             tipo: 0,
    //             nombre: undefined,
    //             cantidad: 1
    //         })
    //     } else {
    //         partidas[key1].partidas = [
    //             {
    //                 tipo: 0,
    //                 nombre: undefined,
    //                 cantidad: 1
    //             }
    //         ]
    //     }



    //     this.formRef.current.setFieldsValue({
    //         partidas: partidas
    //     })
    // }

    // agregarAPartida2(key1, key2) {
    //     const partidas = this.formRef.current.getFieldValue('partidas')

    //     const partidasDentro = partidas[key1].partidas[key2].partidas ? partidas[key1].partidas[key2].partidas.length : 0

    //     if (partidasDentro > 0) {
    //         partidas[key1].partidas[key2].partidas.push({
    //             tipo: 0,
    //             nombre: undefined,
    //             cantidad: 1
    //         })
    //     } else {
    //         partidas[key1].partidas[key2].partidas = [
    //             {
    //                 tipo: 0,
    //                 nombre: undefined,
    //                 cantidad: 1
    //             }
    //         ]
    //     }



    //     this.formRef.current.setFieldsValue({
    //         partidas: partidas
    //     })
    // }

    // agregarAPartida3(key1, key2, key3) {
    //     const partidas = this.formRef.current.getFieldValue('partidas')

    //     const partidasDentro = partidas[key1].partidas[key2].partidas[key3].partidas ? partidas[key1].partidas[key2].partidas[key3].partidas.length : 0

    //     if (partidasDentro > 0) {
    //         partidas[key1].partidas[key2].partidas[key3].partidas.push({
    //             tipo: 0,
    //             nombre: undefined,
    //             cantidad: 1
    //         })
    //     } else {
    //         partidas[key1].partidas[key2].partidas[key3].partidas = [
    //             {
    //                 tipo: 0,
    //                 nombre: undefined,
    //                 cantidad: 1
    //             }
    //         ]
    //     }



    //     this.formRef.current.setFieldsValue({
    //         partidas: partidas
    //     })
    // }

    // agregarAPartida4(key1, key2, key3, key4) {
    //     const partidas = this.formRef.current.getFieldValue('partidas')

    //     const partidasDentro = partidas[key1].partidas[key2].partidas[key3].partidas[key4].partidas ? partidas[key1].partidas[key2].partidas[key3].partidas[key4].partidas.length : 0

    //     if (partidasDentro > 0) {
    //         partidas[key1].partidas[key2].partidas[key3].partidas[key4].partidas.push({
    //             tipo: 1,
    //             nombre: undefined,
    //             cantidad: 1
    //         })
    //     } else {
    //         partidas[key1].partidas[key2].partidas[key3].partidas[key4].partidas = [
    //             {
    //                 tipo: 1,
    //                 nombre: undefined,
    //                 cantidad: 1
    //             }
    //         ]
    //     }



    //     this.formRef.current.setFieldsValue({
    //         partidas: partidas
    //     })
    // }

    // desplejarMMExplosion(matrizId, index){
    //     const matriz = this.state.todasInsumos.find(valueM => valueM._id.toString() === matrizId.toString())
    //     title1 += (matriz1.descripcion + ' - ' + (cantidad1 ? cantidad1 : ''))
    //     childrenMatriz1 = matriz1.insumosUtilizar.map((valueMatriz, indexMatriz) => {
    //         const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
    //         const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
    //         costoTotal1 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

    //         return {
    //             title: insumoBuscar?.descripcion + ' - ' + round10Decimals(valueMatriz.cantidad) + ' ' + dineroDisabledMask(costoTotal),
    //             costo: costoTotal,
    //             key: indexP1 + '-' + indexMatriz,
    //         }
    //     })
    // }

    async explosionInsumos() {
        await this.setState({ loadingExplosion: true })
        const partidas = [...this.state.partidas]

        let insumosExplosionados = []

        let costoTotalIE = 0

        if (partidas) {

            for (const valueP1 of partidas) {
                if (valueP1.children) {

                    for (const valueP2 of valueP1.children) {

                        if (valueP2.children) {
                            for (const valueP3 of valueP2.children) {
                                if (valueP3.children) {
                                    for (const valueP4 of valueP3.children) {
                                        if (valueP4.children) {
                                            for (const valueP5 of valueP4.children) {
                                                if (valueP5.tipo === 1) {
                                                    if (valueP5.nombre) {
                                                        if (valueP5.children) {

                                                            for (const insumo of valueP5.children) {
                                                                let cantidadTotal = 1
                                                                if (valueP5.cantidad && valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP5.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                                }
                                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                                if (indexIE === -1) {
                                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                                    insumosExplosionados.push({
                                                                        insumoId: insumo.insumoId,
                                                                        nombre: insumoBuscar.stringBuscar,
                                                                        unidad: insumoBuscar.unidad.nombre,
                                                                        costo: insumoBuscar.costo,
                                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                        cantidad: cantidadTotal
                                                                    })
                                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                                } else {
                                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                                }
                                                            }

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                        if (valueP4.tipo === 1) {
                                            if (valueP4.nombre) {
                                                if (valueP4.children) {

                                                    for (const insumo of valueP4.children) {
                                                        let cantidadTotal = 1
                                                        if (valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                        }
                                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                        if (indexIE === -1) {
                                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                            insumosExplosionados.push({
                                                                insumoId: insumo.insumoId,
                                                                nombre: insumoBuscar.stringBuscar,
                                                                unidad: insumoBuscar.unidad.nombre,
                                                                costo: insumoBuscar.costo,
                                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                cantidad: cantidadTotal
                                                            })
                                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                        } else {
                                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                        }
                                                    }

                                                }

                                            }
                                        }
                                    }
                                }

                                if (valueP3.tipo === 1) {
                                    if (valueP3.nombre) {
                                        if (valueP3.children) {

                                            for (const insumo of valueP3.children) {
                                                let cantidadTotal = 1
                                                if (valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                }
                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                if (indexIE === -1) {
                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                    insumosExplosionados.push({
                                                        insumoId: insumo.insumoId,
                                                        nombre: insumoBuscar.stringBuscar,
                                                        unidad: insumoBuscar.unidad.nombre,
                                                        costo: insumoBuscar.costo,
                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                        cantidad: cantidadTotal
                                                    })
                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                } else {
                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                }
                                            }

                                        }

                                    }
                                }
                            }

                        }

                        if (valueP2.tipo === 1) {
                            if (valueP2.nombre) {
                                if (valueP2.children) {

                                    for (const insumo of valueP2.children) {
                                        let cantidadTotal = 1
                                        if (valueP2.cantidad && valueP1.cantidad) {
                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP2.cantidad * valueP1.cantidad)
                                        }
                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                        if (indexIE === -1) {
                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                            insumosExplosionados.push({
                                                insumoId: insumo.insumoId,
                                                nombre: insumoBuscar.stringBuscar,
                                                unidad: insumoBuscar.unidad.nombre,
                                                costo: insumoBuscar.costo,
                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                cantidad: cantidadTotal
                                            })
                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                        } else {
                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                        }
                                    }

                                }

                            }
                        }
                    }

                }

                if (valueP1.tipo === 1) {
                    if (valueP1.nombre) {
                        if (valueP1.children) {

                            for (const insumo of valueP1.children) {
                                let cantidadTotal = 1
                                if (valueP1.cantidad) {
                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP1.cantidad)
                                }
                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                if (indexIE === -1) {
                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                    insumosExplosionados.push({
                                        insumoId: insumo.insumoId,
                                        nombre: insumoBuscar.stringBuscar,
                                        unidad: insumoBuscar.unidad.nombre,
                                        costo: insumoBuscar.costo,
                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                        cantidad: cantidadTotal
                                    })
                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                } else {
                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                }
                            }

                        }

                    }
                }
            }
        }

        const partidasArbol = partidas ? partidas.map((valueP1, indexP1) => {
            const nuevasPartidas2 = valueP1.children ? valueP1.children.map((valueP2, indexP2) => {
                const nuevasPartidas3 = valueP2.children ? valueP2.children.map((valueP3, indexP3) => {
                    const nuevasPartidas4 = valueP3.children ? valueP3.children.map((valueP4, indexP4) => {
                        const nuevasPartidas5 = valueP4.children ? valueP4.children.map((valueP5, indexP5) => {

                            const nuevasPartidas6 = valueP5.children ? valueP5.children.map((valueP6, indexP6) => {

                                let title6 = ''

                                if (valueP6.tipo === 0) {
                                    title6 = 'P : ' + valueP6.nombre + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                } else if (valueP6.tipo === 1 && valueP6.nombre) {
                                    const matriz6 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP6?.nombre.toString())
                                    title6 = 'M : ' + matriz6.descripcion + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                } else {
                                    title6 = valueP6.nombre + ' ' + dineroDisabledMask(valueP6.costoTotal)
                                }


                                return {
                                    ...valueP6,
                                    title: title6
                                }

                            }) : []

                            let title5 = ''

                            if (valueP5.tipo === 0) {
                                title5 = 'P : ' + valueP5.nombre + ' ' + dineroDisabledMask(valueP5.costoTotal)
                            } else if (valueP5.tipo === 1 && valueP5.nombre) {
                                const matriz5 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP5?.nombre.toString())
                                title5 = 'M : ' + matriz5.descripcion + ' ' + dineroDisabledMask(valueP5.costoTotal)
                            } else {
                                title5 = valueP5.nombre + ' ' + dineroDisabledMask(valueP5.costoTotal)
                            }


                            return {
                                ...valueP5,
                                children: nuevasPartidas6,
                                title: title5
                            }
                        }) : []

                        let title4 = ''

                        if (valueP4.tipo === 0) {
                            title4 = 'P : ' + valueP4.nombre + ' ' + dineroDisabledMask(valueP4.costoTotal)
                        } else if (valueP4.tipo === 1 && valueP4.nombre) {
                            const matriz4 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP4?.nombre.toString())
                            title4 = 'M : ' + matriz4.descripcion + ' ' + dineroDisabledMask(valueP4.costoTotal)
                        } else {
                            title4 = valueP4.nombre + ' ' + dineroDisabledMask(valueP4.costoTotal)
                        }


                        return {
                            ...valueP4,
                            children: nuevasPartidas5,
                            title: title4
                        }

                    }) : []


                    let title3 = ''

                    if (valueP3.tipo === 0) {
                        title3 = 'P : ' + valueP3.nombre + ' ' + dineroDisabledMask(valueP3.costoTotal)
                    } else if (valueP3.tipo === 1 && valueP3.nombre) {
                        const matriz3 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP3?.nombre.toString())
                        title3 = 'M : ' + matriz3.descripcion + ' ' + dineroDisabledMask(valueP3.costoTotal)
                    } else {
                        title3 = valueP3.nombre + ' ' + dineroDisabledMask(valueP3.costoTotal)
                    }


                    return {
                        ...valueP3,
                        children: nuevasPartidas4,
                        title: title3
                    }

                }) : []

                let title2 = ''

                if (valueP2.tipo === 0) {
                    title2 = 'P : ' + valueP2.nombre + ' ' + dineroDisabledMask(valueP2.costoTotal)
                } else if (valueP2.tipo === 1 && valueP2.nombre) {
                    const matriz2 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP2?.nombre.toString())
                    title2 = 'M : ' + matriz2.descripcion + ' ' + dineroDisabledMask(valueP2.costoTotal)
                } else {
                    title2 = valueP2.nombre + ' ' + dineroDisabledMask(valueP2.costoTotal)
                }


                return {
                    ...valueP2,
                    children: nuevasPartidas3,
                    title: title2
                }

            }) : []

            let title1 = ''

            if (valueP1.tipo === 0) {
                title1 = 'P : ' + valueP1.nombre + ' ' + dineroDisabledMask(valueP1.costoTotal)
            } else if (valueP1.tipo === 1 && valueP1.nombre) {
                const matriz1 = this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP1?.nombre.toString())
                title1 = 'M : ' + matriz1.descripcion + ' ' + dineroDisabledMask(valueP1.costoTotal)
            } else {
                title1 = valueP1.nombre + ' ' + dineroDisabledMask(valueP1.costoTotal)
            }


            return {
                ...valueP1,
                children: nuevasPartidas2,
                title: title1
            }

        }) : []

        let nuevosInsumosExplosionados = []

        await axios
            .post(
                "inventarios/inventarioInsumosProyecto",
                {
                    proyectoId: this.formRef.current.getFieldValue('proyectoId'),
                    insumos: insumosExplosionados,
                    empresaId: this.props.empresaId,
                },
                { headers: this.props.headersToken }
            ).then((res) => {
                if (res.data.insumos) {
                    nuevosInsumosExplosionados = res.data.insumos
                } else {
                    nuevosInsumosExplosionados = res.data.insumos
                }
            })


        await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, costoTotalIE, partidasArbol })

        this.cambioPedir()

    }

    async arbolPresupuesto() {
        await this.setState({ loadingArbol: true })

        const partidas = this.formRef.current.getFieldValue('partidas')
        const partidasArbol = partidas ? partidas.map((valueP1, indexP1) => {
            const nuevasPartidas2 = valueP1.partidas ? valueP1.partidas.map((valueP2, indexP2) => {
                const nuevasPartidas3 = valueP2.partidas ? valueP2.partidas.map((valueP3, indexP3) => {
                    const nuevasPartidas4 = valueP3.partidas ? valueP3.partidas.map((valueP4, indexP4) => {
                        const nuevasPartidas5 = valueP4.partidas ? valueP4.partidas.map((valueP5, indexP5) => {
                            const tipo5 = valueP5.tipo
                            const cantidad5 = valueP5.cantidad

                            let title5 = ''
                            let childrenMatriz5 = []
                            let costoTotal5 = 0

                            if (tipo5 === 0) {
                                title5 += 'P : '
                                const nombre5 = valueP5.nombre

                                if (nombre5) {
                                    title5 += (nombre5 + ' - ' + (cantidad5 ? cantidad5 : ''))
                                }
                            } else if (tipo5 === 1) {
                                title5 += 'M : '



                                if (valueP5?.matrizId) {
                                    const matriz5 = valueP5.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP5?.matrizId.toString()) : { descripcion: '' }
                                    title5 += (matriz5.descripcion + ' - ' + (cantidad5 ? cantidad5 : ''))
                                    childrenMatriz5 = matriz5.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                                        const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                                        const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                                        costoTotal5 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                                        return {
                                            title: insumoBuscar?.descripcion + ' - ' + round10Decimals(valueMatriz.cantidad) + ' ' + dineroDisabledMask(costoTotal),
                                            costo: costoTotal,
                                            key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5 + '-' + indexMatriz,
                                        }
                                    })
                                }
                            }

                            return {
                                title: title5 + ' ' + dineroDisabledMask(costoTotal5 * (valueP5.cantidad || 0)),
                                key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5,
                                costoTotal: (costoTotal5 * (valueP5.cantidad || 0)),
                                children: childrenMatriz5
                            }
                        }) : []

                        const tipo4 = valueP4.tipo
                        const cantidad4 = valueP4.cantidad

                        let title4 = ''
                        let childrenMatriz4 = []
                        let costoTotal4 = 0

                        if (tipo4 === 0) {
                            title4 += 'P : '
                            const nombre4 = valueP4.nombre

                            if (nombre4) {
                                title4 += (nombre4 + ' - ' + (cantidad4 ? cantidad4 : ''))
                            }

                            for (const partida5 of nuevasPartidas5) {
                                costoTotal4 += partida5.costoTotal
                            }

                        } else if (tipo4 === 1) {
                            title4 += 'M : '


                            if (valueP4?.matrizId) {
                                const matriz4 = valueP4.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP4?.matrizId.toString()) : { descripcion: '' }
                                title4 += (matriz4.descripcion + ' - ' + (cantidad4 ? cantidad4 : ''))
                                childrenMatriz4 = matriz4.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                                    const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                                    costoTotal4 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                                    return {
                                        title: insumoBuscar?.descripcion + ' - ' + round10Decimals(valueMatriz.cantidad) + ' ' + dineroDisabledMask(costoTotal),
                                        costo: costoTotal,
                                        key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexMatriz,
                                    }
                                })
                            }
                        }

                        return {
                            title: title4 + ' ' + dineroDisabledMask(costoTotal4 * (valueP4.cantidad || 0)),
                            key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4,
                            children: tipo4 === 0 ? nuevasPartidas5 : childrenMatriz4,
                            costoTotal: (costoTotal4 * (valueP4.cantidad || 0)),
                        }

                    }) : []


                    const tipo3 = valueP3.tipo
                    const cantidad3 = valueP3.cantidad

                    let title3 = ''
                    let childrenMatriz3 = []
                    let costoTotal3 = 0

                    if (tipo3 === 0) {
                        title3 += 'P : '
                        const nombre3 = valueP3.nombre

                        if (nombre3) {
                            title3 += (nombre3 + ' - ' + (cantidad3 ? cantidad3 : ''))
                        }

                        for (const partida4 of nuevasPartidas4) {
                            costoTotal3 += partida4.costoTotal
                        }
                    } else if (tipo3 === 1) {
                        title3 += 'M : '


                        if (valueP3?.matrizId) {
                            const matriz3 = valueP3.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP3?.matrizId.toString()) : { descripcion: '' }
                            title3 += (matriz3.descripcion + ' - ' + (cantidad3 ? cantidad3 : ''))
                            childrenMatriz3 = matriz3.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                                const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                                const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                                costoTotal3 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                                return {
                                    title: insumoBuscar?.descripcion + ' - ' + round10Decimals(valueMatriz.cantidad) + ' ' + dineroDisabledMask(costoTotal),
                                    costo: costoTotal,
                                    key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexMatriz,
                                }
                            })
                        }
                    }

                    return {
                        title: title3 + ' ' + dineroDisabledMask(costoTotal3 * (valueP3.cantidad || 0)),
                        key: indexP1 + '-' + indexP2 + '-' + indexP3,
                        children: tipo3 === 0 ? nuevasPartidas4 : childrenMatriz3,
                        costoTotal: (costoTotal3 * (valueP3.cantidad || 0)),
                    }

                }) : []

                const tipo2 = valueP2.tipo
                const cantidad2 = valueP2.cantidad

                let title2 = ''
                let childrenMatriz2 = []
                let costoTotal2 = 0

                if (tipo2 === 0) {
                    title2 += 'P : '
                    const nombre2 = valueP2.nombre

                    if (nombre2) {
                        title2 += (nombre2 + ' - ' + (cantidad2 ? cantidad2 : ''))
                    }
                    for (const partida3 of nuevasPartidas3) {
                        costoTotal2 += partida3.costoTotal
                    }
                } else if (tipo2 === 1) {
                    title2 += 'M : '


                    if (valueP2?.matrizId) {
                        const matriz2 = valueP2.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP2?.matrizId.toString()) : { descripcion: '' }
                        title2 += (matriz2.descripcion + ' - ' + (cantidad2 ? cantidad2 : ''))
                        childrenMatriz2 = matriz2.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                            const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                            costoTotal2 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                            return {
                                title: insumoBuscar?.descripcion + ' - ' + round10Decimals(valueMatriz.cantidad) + ' ' + dineroDisabledMask(costoTotal),
                                costo: costoTotal,
                                key: indexP1 + '-' + indexP2 + '-' + indexMatriz,
                            }
                        })
                    }
                }

                return {
                    title: title2 + ' ' + dineroDisabledMask(costoTotal2 * (valueP2.cantidad || 0)),
                    key: indexP1 + '-' + indexP2,
                    children: tipo2 === 0 ? nuevasPartidas3 : childrenMatriz2,
                    costoTotal: (costoTotal2 * (valueP2.cantidad || 0)),
                }

            }) : []

            const tipo1 = valueP1.tipo
            const cantidad1 = valueP1.cantidad

            let title1 = ''
            let childrenMatriz1 = []
            let costoTotal1 = 0

            if (tipo1 === 0) {
                title1 += 'P : '
                const nombre1 = valueP1.nombre

                if (nombre1) {
                    title1 += (nombre1 + ' - ' + (cantidad1 ? cantidad1 : ''))
                }

                for (const partida2 of nuevasPartidas2) {
                    costoTotal1 += partida2.costoTotal
                }
            } else if (tipo1 === 1) {
                title1 += 'M : '


                if (valueP1?.matrizId) {
                    const matriz1 = valueP1.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP1?.matrizId.toString()) : { descripcion: '' }
                    title1 += (matriz1.descripcion + ' - ' + (cantidad1 ? cantidad1 : ''))
                    childrenMatriz1 = matriz1.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                        const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                        const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                        costoTotal1 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                        return {
                            title: insumoBuscar?.descripcion + ' - ' + round10Decimals(valueMatriz.cantidad) + ' ' + dineroDisabledMask(costoTotal),
                            costo: costoTotal,
                            key: indexP1 + '-' + indexMatriz,
                        }
                    })
                }
            }

            return {
                title: title1 + ' ' + dineroDisabledMask(costoTotal1 * (valueP1.cantidad || 0)),
                key: indexP1,
                children: tipo1 === 0 ? nuevasPartidas2 : childrenMatriz1,
                costoTotal: (costoTotal1 * (valueP1.cantidad || 0)),
            }

        }) : []

        await this.setState({ loadingArbol: false, partidasArbol })
    }

    // async tablaPresupuesto() {
    //     await this.setState({ loadingTabla: true })

    //     const partidas = this.formRef.current.getFieldValue('partidas')
    //     const partidas = partidas ? partidas.map((valueP1, indexP1) => {
    //         const nuevasPartidas2 = valueP1.partidas ? valueP1.partidas.map((valueP2, indexP2) => {
    //             const nuevasPartidas3 = valueP2.partidas ? valueP2.partidas.map((valueP3, indexP3) => {
    //                 const nuevasPartidas4 = valueP3.partidas ? valueP3.partidas.map((valueP4, indexP4) => {
    //                     const nuevasPartidas5 = valueP4.partidas ? valueP4.partidas.map((valueP5, indexP5) => {
    //                         const tipo5 = valueP5.tipo
    //                         const cantidad5 = valueP5.cantidad

    //                         let title5 = ''
    //                         let childrenMatriz5 = []
    //                         let costoTotal5 = 0

    //                         if (tipo5 === 0) {
    //                             title5 += 'P : '
    //                             const nombre5 = valueP5.nombre

    //                             if (nombre5) {
    //                                 title5 += nombre5
    //                             }
    //                         } else if (tipo5 === 1) {
    //                             title5 += 'M : '



    //                             if (valueP5?.matrizId) {
    //                                 const matriz5 = valueP5.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP5?.matrizId.toString()) : { descripcion: '' }
    //                                 title5 += matriz5.descripcion
    //                                 childrenMatriz5 = matriz5.insumosUtilizar.map((valueMatriz, indexMatriz) => {
    //                                     const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
    //                                     const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
    //                                     costoTotal5 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

    //                                     return {
    //                                         title: insumoBuscar?.descripcion,
    //                                         costoTotal: costoTotal,
    //                                         costo: insumoBuscar.costo,
    //                                         cantidad: valueMatriz.cantidad,
    //                                         key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5 + '-' + indexMatriz,
    //                                     }
    //                                 })
    //                             }
    //                         }

    //                         return {
    //                             title: title5,
    //                             costoTotal: (costoTotal5 * (cantidad5 || 0)),
    //                             costo: costoTotal5,
    //                             cantidad: cantidad5,
    //                             key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5,
    //                             children: childrenMatriz5
    //                         }
    //                     }) : []

    //                     const tipo4 = valueP4.tipo
    //                     const cantidad4 = valueP4.cantidad

    //                     let title4 = ''
    //                     let childrenMatriz4 = []
    //                     let costoTotal4 = 0

    //                     if (tipo4 === 0) {
    //                         title4 += 'P : '
    //                         const nombre4 = valueP4.nombre

    //                         if (nombre4) {
    //                             title4 += nombre4
    //                         }

    //                         for (const partida5 of nuevasPartidas5) {
    //                             costoTotal4 += partida5.costoTotal
    //                         }

    //                     } else if (tipo4 === 1) {
    //                         title4 += 'M : '


    //                         if (valueP4?.matrizId) {
    //                             const matriz4 = valueP4.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP4?.matrizId.toString()) : { descripcion: '' }
    //                             title4 += matriz4.descripcion
    //                             childrenMatriz4 = matriz4.insumosUtilizar.map((valueMatriz, indexMatriz) => {
    //                                 const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
    //                                 const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
    //                                 costoTotal4 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

    //                                 return {
    //                                     title: insumoBuscar?.descripcion,
    //                                     costoTotal: costoTotal,
    //                                     costo: insumoBuscar.costo,
    //                                     cantidad: valueMatriz.cantidad,
    //                                     key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexMatriz,
    //                                 }
    //                             })
    //                         }
    //                     }

    //                     return {
    //                         title: title4,
    //                         key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4,
    //                         costoTotal: (costoTotal4 * (cantidad4 || 0)),
    //                         costo: costoTotal4,
    //                         cantidad: cantidad4,
    //                         children: tipo4 === 0 ? nuevasPartidas5 : childrenMatriz4,
    //                     }

    //                 }) : []


    //                 const tipo3 = valueP3.tipo
    //                 const cantidad3 = valueP3.cantidad

    //                 let title3 = ''
    //                 let childrenMatriz3 = []
    //                 let costoTotal3 = 0

    //                 if (tipo3 === 0) {
    //                     title3 += 'P : '
    //                     const nombre3 = valueP3.nombre

    //                     if (nombre3) {
    //                         title3 += nombre3
    //                     }

    //                     for (const partida4 of nuevasPartidas4) {
    //                         costoTotal3 += partida4.costoTotal
    //                     }
    //                 } else if (tipo3 === 1) {
    //                     title3 += 'M : '


    //                     if (valueP3?.matrizId) {
    //                         const matriz3 = valueP3.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP3?.matrizId.toString()) : { descripcion: '' }
    //                         title3 += matriz3.descripcion
    //                         childrenMatriz3 = matriz3.insumosUtilizar.map((valueMatriz, indexMatriz) => {
    //                             const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
    //                             const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
    //                             costoTotal3 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

    //                             return {
    //                                 title: insumoBuscar?.descripcion,
    //                                 costoTotal: costoTotal,
    //                                 costo: insumoBuscar.costo,
    //                                 cantidad: valueMatriz.cantidad,
    //                                 key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexMatriz,
    //                             }
    //                         })
    //                     }
    //                 }

    //                 return {
    //                     title: title3,
    //                     key: indexP1 + '-' + indexP2 + '-' + indexP3,
    //                     costoTotal: (costoTotal3 * (cantidad3 || 0)),
    //                     costo: costoTotal3,
    //                     cantidad: cantidad3,
    //                     children: tipo3 === 0 ? nuevasPartidas4 : childrenMatriz3,
    //                 }

    //             }) : []

    //             const tipo2 = valueP2.tipo
    //             const cantidad2 = valueP2.cantidad

    //             let title2 = ''
    //             let childrenMatriz2 = []
    //             let costoTotal2 = 0

    //             if (tipo2 === 0) {
    //                 title2 += 'P : '
    //                 const nombre2 = valueP2.nombre

    //                 if (nombre2) {
    //                     title2 += nombre2
    //                 }
    //                 for (const partida3 of nuevasPartidas3) {
    //                     costoTotal2 += partida3.costoTotal
    //                 }
    //             } else if (tipo2 === 1) {
    //                 title2 += 'M : '


    //                 if (valueP2?.matrizId) {
    //                     const matriz2 = valueP2.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP2?.matrizId.toString()) : { descripcion: '' }
    //                     title2 += matriz2.descripcion
    //                     childrenMatriz2 = matriz2.insumosUtilizar.map((valueMatriz, indexMatriz) => {
    //                         const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
    //                         const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
    //                         costoTotal2 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

    //                         return {
    //                             title: insumoBuscar?.descripcion,
    //                             costoTotal: costoTotal,
    //                             costo: insumoBuscar.costo,
    //                             cantidad: valueMatriz.cantidad,
    //                             key: indexP1 + '-' + indexP2 + '-' + indexMatriz,
    //                         }
    //                     })
    //                 }
    //             }

    //             return {
    //                 title: title2,
    //                 key: indexP1 + '-' + indexP2,
    //                 costoTotal: (costoTotal2 * (cantidad2 || 0)),
    //                 costo: costoTotal2,
    //                 cantidad: cantidad2,
    //                 children: tipo2 === 0 ? nuevasPartidas3 : childrenMatriz2,
    //             }

    //         }) : []

    //         const tipo1 = valueP1.tipo
    //         const cantidad1 = valueP1.cantidad

    //         let title1 = ''
    //         let childrenMatriz1 = []
    //         let costoTotal1 = 0

    //         if (tipo1 === 0) {
    //             title1 += 'P : '
    //             const nombre1 = valueP1.nombre

    //             if (nombre1) {
    //                 title1 += nombre1
    //             }

    //             for (const partida2 of nuevasPartidas2) {
    //                 costoTotal1 += partida2.costoTotal
    //             }
    //         } else if (tipo1 === 1) {
    //             title1 += 'M : '


    //             if (valueP1?.matrizId) {
    //                 const matriz1 = valueP1.tipo === 1 ? this.state.todasMatrices.find(valueM => valueM._id.toString() === valueP1?.matrizId.toString()) : { descripcion: '' }
    //                 title1 += matriz1.descripcion
    //                 childrenMatriz1 = matriz1.insumosUtilizar.map((valueMatriz, indexMatriz) => {
    //                     const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
    //                     const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
    //                     costoTotal1 += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

    //                     return {
    //                         title: insumoBuscar?.descripcion,
    //                         costoTotal: costoTotal,
    //                         costo: insumoBuscar.costo,
    //                         cantidad: valueMatriz.cantidad,
    //                         key: indexP1 + '-' + indexMatriz,
    //                     }
    //                 })
    //             }
    //         }

    //         return {
    //             title: title1,
    //             key: indexP1,
    //             costoTotal: (costoTotal1 * (cantidad1 || 0)),
    //             costo: costoTotal1,
    //             cantidad: cantidad1,
    //             children: tipo1 === 0 ? nuevasPartidas2 : childrenMatriz1,
    //         }

    //     }) : []

    //     console.log(partidas)

    //     await this.setState({ loadingTabla: false, partidas })
    // }

    async onChangeExplosion(valuesKeys) {

        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            newFielsValue['pedir' + insumoExplosion.insumoId] = undefined
        }

        this.formRef.current.setFieldsValue(newFielsValue)

        if (valuesKeys.length === 0) {
            this.explosionInsumos()
            await this.setState({ valueExplosion: valuesKeys })
        } else {

            await this.setState({ loadingExplosion: true })
            const partidas = [...this.state.partidas]

            let insumosExplosionados = []

            let costoTotalIE = 0

            if (partidas) {

                for (const [indexP1, valueP1] of partidas.entries()) {
                    const key1 = indexP1
                    const key1Explosion = valuesKeys.findIndex(valueTK => valueTK === key1 || valueTK.toString() === key1.toString()) !== -1

                    if (valueP1.children) {

                        for (const [indexP2, valueP2] of valueP1.children.entries()) {
                            const key2 = indexP1 + '-' + indexP2
                            const key2Explosion = valuesKeys.findIndex(valueTK => valueTK === key2) !== -1

                            if (valueP2.children) {
                                for (const [indexP3, valueP3] of valueP2.children.entries()) {
                                    const key3 = indexP1 + '-' + indexP2 + '-' + indexP3
                                    const key3Explosion = valuesKeys.findIndex(valueTK => valueTK === key3) !== -1

                                    if (valueP3.children) {
                                        for (const [indexP4, valueP4] of valueP3.children.entries()) {
                                            const key4 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4
                                            const key4Explosion = valuesKeys.findIndex(valueTK => valueTK === key4) !== -1

                                            if (valueP4.children) {
                                                for (const [indexP5, valueP5] of valueP4.children.entries()) {
                                                    const key5 = indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5
                                                    const key5Explosion = valuesKeys.findIndex(valueTK => valueTK === key5) !== -1

                                                    if (valueP5.tipo === 1) {
                                                        if (valueP5.nombre) {
                                                            if (valueP5.children) {

                                                                for (const [indexMatriz, insumo] of valueP5.children.entries()) {
                                                                    const keyMatriz = key5 + '-' + indexMatriz
                                                                    const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                                                    if (keyMatrizExplosion || key5Explosion || key4Explosion || key3Explosion || key2Explosion || key1Explosion) {
                                                                        let cantidadTotal = 1
                                                                        if (valueP5.cantidad && valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP5.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                                        }
                                                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                                        if (indexIE === -1) {
                                                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                                            insumosExplosionados.push({
                                                                                insumoId: insumo.insumoId,
                                                                                nombre: insumoBuscar.stringBuscar,
                                                                                unidad: insumoBuscar.unidad.nombre,
                                                                                costo: insumoBuscar.costo,
                                                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                                cantidad: cantidadTotal
                                                                            })
                                                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                                        } else {
                                                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                                        }
                                                                    }
                                                                }

                                                            }

                                                        }
                                                    }
                                                }
                                            }
                                            if (valueP4.tipo === 1) {
                                                if (valueP4.nombre) {
                                                    if (valueP4.children) {

                                                        for (const [indexMatriz, insumo] of valueP4.children.entries()) {

                                                            const keyMatriz = key4 + '-' + indexMatriz
                                                            const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                                            if (keyMatrizExplosion || key4Explosion || key3Explosion || key2Explosion || key1Explosion) {

                                                                let cantidadTotal = 1
                                                                if (valueP4.cantidad && valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP4.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                                }
                                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                                if (indexIE === -1) {
                                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                                    insumosExplosionados.push({
                                                                        insumoId: insumo.insumoId,
                                                                        nombre: insumoBuscar.stringBuscar,
                                                                        unidad: insumoBuscar.unidad.nombre,
                                                                        costo: insumoBuscar.costo,
                                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                        cantidad: cantidadTotal
                                                                    })
                                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                                } else {
                                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                                }
                                                            }
                                                        }

                                                    }

                                                }
                                            }
                                        }
                                    }

                                    if (valueP3.tipo === 1) {
                                        if (valueP3.nombre) {
                                            if (valueP3.children) {

                                                for (const [indexMatriz, insumo] of valueP3.children.entries()) {

                                                    const keyMatriz = key3 + '-' + indexMatriz
                                                    const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                                    if (keyMatrizExplosion || key3Explosion || key2Explosion || key1Explosion) {

                                                        let cantidadTotal = 1
                                                        if (valueP3.cantidad && valueP2.cantidad && valueP1.cantidad) {
                                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP3.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                        }
                                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                        if (indexIE === -1) {
                                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                            insumosExplosionados.push({
                                                                insumoId: insumo.insumoId,
                                                                nombre: insumoBuscar.stringBuscar,
                                                                unidad: insumoBuscar.unidad.nombre,
                                                                costo: insumoBuscar.costo,
                                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                                cantidad: cantidadTotal
                                                            })
                                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                        } else {
                                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                        }
                                                    }
                                                }

                                            }

                                        }
                                    }
                                }

                            }

                            if (valueP2.tipo === 1) {
                                if (valueP2.nombre) {
                                    if (valueP2.children) {

                                        for (const [indexMatriz, insumo] of valueP2.children.entries()) {
                                            const keyMatriz = key2 + '-' + indexMatriz
                                            const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                            if (keyMatrizExplosion || key2Explosion || key1Explosion) {

                                                let cantidadTotal = 1
                                                if (valueP2.cantidad && valueP1.cantidad) {
                                                    cantidadTotal = round10Decimals(insumo.cantidad * valueP2.cantidad * valueP1.cantidad)
                                                }
                                                const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                                if (indexIE === -1) {
                                                    const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                                    insumosExplosionados.push({
                                                        insumoId: insumo.insumoId,
                                                        nombre: insumoBuscar.stringBuscar,
                                                        unidad: insumoBuscar.unidad.nombre,
                                                        costo: insumoBuscar.costo,
                                                        costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                        cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                        cantidad: cantidadTotal
                                                    })
                                                    costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                                } else {
                                                    insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                                    insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                                    insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                                    costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                                }
                                            }
                                        }

                                    }

                                }
                            }
                        }

                    }

                    if (valueP1.tipo === 1) {
                        if (valueP1.nombre) {
                            if (valueP1.children) {

                                for (const [indexMatriz, insumo] of valueP1.children.entries()) {

                                    const keyMatriz = key1 + '-' + indexMatriz
                                    const keyMatrizExplosion = valuesKeys.findIndex(valueTK => valueTK === keyMatriz) !== -1
                                    if (keyMatrizExplosion || key1Explosion) {

                                        let cantidadTotal = 1
                                        if (valueP1.cantidad) {
                                            cantidadTotal = round10Decimals(insumo.cantidad * valueP1.cantidad)
                                        }
                                        const indexIE = insumosExplosionados.findIndex(valueTIE => valueTIE.insumoId.toString() === insumo.insumoId.toString())
                                        if (indexIE === -1) {
                                            const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === insumo.insumoId.toString())
                                            insumosExplosionados.push({
                                                insumoId: insumo.insumoId,
                                                nombre: insumoBuscar.stringBuscar,
                                                unidad: insumoBuscar.unidad.nombre,
                                                costo: insumoBuscar.costo,
                                                costoTotal: round2Decimals(insumoBuscar.costo * cantidadTotal),
                                                cantidadPedida: round10Decimals(insumo.cantidadPedida || 0),
                                                cantidad: cantidadTotal
                                            })
                                            costoTotalIE += round2Decimals(insumoBuscar.costo * cantidadTotal)
                                        } else {
                                            insumosExplosionados[indexIE].cantidad = round10Decimals(insumosExplosionados[indexIE].cantidad + cantidadTotal)
                                            insumosExplosionados[indexIE].costoTotal = round2Decimals(insumosExplosionados[indexIE].costoTotal + round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal))
                                            insumosExplosionados[indexIE].cantidadPedida = round10Decimals(insumosExplosionados[indexIE].cantidadPedida + (insumo.cantidadPedida || 0))
                                            costoTotalIE += round2Decimals(insumosExplosionados[indexIE].costo * cantidadTotal)
                                        }
                                    }
                                }

                            }

                        }
                    }
                }
            }

            let nuevosInsumosExplosionados = []

            await axios
                .post(
                    "inventarios/inventarioInsumosProyecto",
                    {
                        proyectoId: this.formRef.current.getFieldValue('proyectoId'),
                        insumos: insumosExplosionados,
                        empresaId: this.props.empresaId,
                    },
                    { headers: this.props.headersToken }
                ).then((res) => {
                    if (res.data.insumos) {
                        nuevosInsumosExplosionados = res.data.insumos
                    } else {
                        nuevosInsumosExplosionados = res.data.insumos
                    }
                })



            await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, costoTotalIE, valueExplosion: valuesKeys })
            this.cambioPedir()
        }
    }

    async confirmarPresupuesto() {
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true })
            axios
                .post("presupuestosPU/confirmarPresupuesto", { ...values, partidas: this.state.partidas, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then((res) => {
                    this.setState({ loading: false, id: res.data.presupuestoId });
                    if (res.data.success === 2) {
                        message.success("Se ha confirmado el presupuesto");
                        this.consultarTodo()
                    } else if (res.data.success === 1) {
                        message.error("No se puede confirmar el presupuesto");
                    } else {
                        message.error("Hubo un error y el presupuesto no se confirmo");
                    }
                });
        }).catch(() => this.onFinishFailed())
    }

    agregarPartida(key) {
        let partidas = [...this.state.partidas]
        let expandedRowKeys = [...this.state.expandedRowKeys]
        if (key) {
            const arrayKey = key.split("-").map(value => Number(value))
            if (arrayKey.length === 1) {
                if (partidas[arrayKey[0]].children) {
                    partidas[arrayKey[0]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 2) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 3) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            } else if (arrayKey.length === 4) {
                if (partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children) {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children.push({
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-' + partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children.length
                    })
                } else {
                    partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children[arrayKey[3]].children = [{
                        tipo: 0,
                        nombre: "",
                        cantidad: 1,
                        costo: 0,
                        costoTotal: 0,
                        key: key + '-0'
                    }]
                }
            }
        } else {
            partidas.push({
                tipo: 0,
                nombre: "",
                cantidad: 1,
                costo: 0,
                costoTotal: 0,
                key: partidas.length.toString()
            })
        }

        const keyExpand = expandedRowKeys.findIndex(value => value === key)

        if (keyExpand === -1 && key) {
            expandedRowKeys.push(key)
        }

        this.setState({ partidas: partidas, expandedRowKeys })
    }

    onChangeTipo(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].tipo = value
            partidas[arrayKeys[0]].costo = 0
            partidas[arrayKeys[0]].costoTotal = 0

            partidas[arrayKeys[0]].children = undefined
            partidas[arrayKeys[0]].nombre = undefined

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = undefined

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = undefined

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = undefined

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].tipo = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costo = 0
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotal = 0

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].children = undefined
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = undefined

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onChangeNombre(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].nombre = value.target.value

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = value.target.value

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = value.target.value

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = value.target.value

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = value.target.value

        }

        this.setState({ partidas: partidas })
    }

    calcularPresupuesto(partidas) {

        const nuevasPartidas = partidas ? partidas.map((valueP1, indexP1) => {
            let costo1 = valueP1.insumoMatriz ? valueP1.costo : 0

            const newChildren = valueP1.children ? valueP1.children.map((valueP2, indexP2) => {
                let costo2 = valueP2.insumoMatriz ? valueP2.costo : 0

                const newChildren = valueP2.children ? valueP2.children.map((valueP3, indexP3) => {

                    let costo3 = valueP3.insumoMatriz ? valueP3.costo : 0
                    const newChildren = valueP3.children ? valueP3.children.map((valueP4, indexP4) => {
                        let costo4 = valueP4.insumoMatriz ? valueP4.costo : 0
                        const newChildren = valueP4.children ? valueP4.children.map((valueP5, indexP5) => {
                            let costo5 = valueP5.insumoMatriz ? valueP5.costo : 0

                            const newChildren = valueP5.children ? valueP5.children.map((valueP6, indexP6) => {
                                costo5 += round2Decimals(valueP6.costo * valueP6.cantidad)

                                let cantidadTotal6 = valueP6.cantidad

                                if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad && valueP4.cantidad && valueP5.cantidad && valueP6.cantidad) {
                                    cantidadTotal6 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad * valueP4.cantidad * valueP5.cantidad * valueP6.cantidad)
                                }

                                return {
                                    ...valueP6,
                                    key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5 + '-' + indexP6,
                                    costoTotal: round2Decimals(valueP6.costo * valueP6.cantidad),
                                    cantidadTotal: cantidadTotal6
                                }

                            }) : undefined

                            costo4 += round2Decimals(costo5 * valueP5.cantidad)

                            let cantidadTotal5 = valueP5.cantidad

                            if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad && valueP4.cantidad && valueP5.cantidad) {
                                cantidadTotal5 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad * valueP4.cantidad * valueP5.cantidad)
                            }

                            return {
                                ...valueP5,
                                key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4 + '-' + indexP5,
                                costo: costo5,
                                costoTotal: round2Decimals(costo5 * valueP5.cantidad),
                                cantidadTotal: cantidadTotal5,
                                children: newChildren,
                            }
                        }) : undefined

                        costo3 += round2Decimals(costo4 * valueP4.cantidad)

                        let cantidadTotal4 = valueP4.cantidad

                        if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad && valueP4.cantidad) {
                            cantidadTotal4 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad * valueP4.cantidad)
                        }

                        return {
                            ...valueP4,
                            key: indexP1 + '-' + indexP2 + '-' + indexP3 + '-' + indexP4,
                            costo: costo4,
                            costoTotal: round2Decimals(costo4 * valueP4.cantidad),
                            children: newChildren,
                            cantidadTotal: cantidadTotal4
                        }
                    }) : undefined

                    costo2 += round2Decimals(costo3 * valueP3.cantidad)

                    let cantidadTotal3 = valueP3.cantidad

                    if (valueP1.cantidad && valueP2.cantidad && valueP3.cantidad) {
                        cantidadTotal3 = round10Decimals(valueP1.cantidad * valueP2.cantidad * valueP3.cantidad)
                    }

                    return {
                        ...valueP3,
                        key: indexP1 + '-' + indexP2 + '-' + indexP3,
                        costo: costo3,
                        costoTotal: round2Decimals(costo3 * valueP3.cantidad),
                        children: newChildren,
                        cantidadTotal: cantidadTotal3
                    }
                }) : undefined

                costo1 += round2Decimals(costo2 * valueP2.cantidad)

                let cantidadTotal2 = valueP2.cantidad

                if (valueP1.cantidad && valueP2.cantidad) {
                    cantidadTotal2 = round10Decimals(valueP1.cantidad * valueP2.cantidad)
                }

                return {
                    ...valueP2,
                    key: indexP1 + '-' + indexP2,
                    costo: costo2,
                    costoTotal: round2Decimals(costo2 * valueP2.cantidad),
                    children: newChildren,
                    cantidadTotal: cantidadTotal2
                }
            }) : undefined

            let cantidadTotal1 = valueP1.cantidad

            return {
                ...valueP1,
                key: indexP1.toString(),
                costo: costo1,
                costoTotal: round2Decimals(costo1 * valueP1.cantidad),
                children: newChildren,
                cantidadTotal: cantidadTotal1
            }
        }) : []

        return nuevasPartidas
    }

    onChangeMatrizId(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        let childrenMatriz = []
        let costoMatriz = 0
        let unidad = ''

        if (value) {
            const matriz = this.state.todasMatrices.find(valueM => valueM._id.toString() === value.toString())
            unidad = matriz.unidad.nombre
            childrenMatriz = matriz.insumosUtilizar.map((valueMatriz, indexMatriz) => {
                const insumoBuscar = this.state.todasInsumos.find(valueTI => valueTI._id.toString() === valueMatriz.insumoId.toString())
                const costoTotal = round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)
                costoMatriz += round10Decimals(insumoBuscar.costo * valueMatriz.cantidad)

                return {
                    insumoMatriz: true,
                    nombre: insumoBuscar?.descripcion,
                    unidad: insumoBuscar?.unidad?.nombre,
                    costoTotal: costoTotal,
                    costo: insumoBuscar.costo,
                    cantidad: valueMatriz.cantidad,
                    insumoId: valueMatriz.insumoId,
                    key: key + '-' + indexMatriz,
                }
            })
        }

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].nombre = value
            partidas[arrayKeys[0]].children = childrenMatriz
            partidas[arrayKeys[0]].costo = costoMatriz
            partidas[arrayKeys[0]].unidad = unidad
            partidas[arrayKeys[0]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].cantidad || 0))

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].cantidad || 0))

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].cantidad || 0))

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].cantidad || 0))

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].nombre = value
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].children = childrenMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costo = costoMatriz
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].unidad = unidad
            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].costoTotal = round2Decimals(costoMatriz * (partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].cantidad || 0))

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onChangeCantidad(value, key) {
        const partidas = [...this.state.partidas]

        const arrayKeys = key.split('-').map(valueKey => Number(valueKey))

        if (arrayKeys.length === 1) {

            partidas[arrayKeys[0]].cantidad = value.target.value

        } else if (arrayKeys.length === 2) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].cantidad = value.target.value

        } else if (arrayKeys.length === 3) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].cantidad = value.target.value

        } else if (arrayKeys.length === 4) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].cantidad = value.target.value

        } else if (arrayKeys.length === 5) {

            partidas[arrayKeys[0]].children[arrayKeys[1]].children[arrayKeys[2]].children[arrayKeys[3]].children[arrayKeys[4]].cantidad = value.target.value

        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    Cell(props) {
        if (props?.dataIndex === "tipo" && !props.record.insumoMatriz) {
            return (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                    <SelectItem
                        sinBorde
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        placeholder="Tipo"
                        value={props.record.tipo}
                        onChange={(value) => {
                            this.onChangeTipo(value, props.record.key)
                        }}
                    >
                        <Option value={0} key={0}>Partida</Option>
                        <Option value={1} key={1}>Matriz</Option>
                    </SelectItem>
                </td>
            )
        } else if (props?.dataIndex === "nombre" && !props.record.insumoMatriz) {
            if (props.record.tipo === 0) {
                return (
                    <td {...props} style={{ ...props.style, padding: 0 }}>
                        <InputItem
                            defaultValue={props.record.nombre}
                            style={{
                                backgroundColor: 'transparent'
                            }}
                            placeholder="Nombre"
                            sinBorde
                            onBlur={(value) => {
                                this.onChangeNombre(value, props.record.key)
                            }}
                        />
                    </td>
                )
            } else {
                return (
                    <td {...props} style={{ ...props.style, padding: 0 }}>
                        <SelectItem
                            sinBorde
                            style={{
                                backgroundColor: 'transparent'
                            }}
                            placeholder="Matriz"
                            width='100%'
                            value={props.record.nombre}
                            onChange={(value) => {
                                this.onChangeMatrizId(value, props.record.key)
                            }}
                        >
                            {
                                this.state
                                    .objetoMatrices
                            }
                        </SelectItem>
                    </td>
                )
            }
        } else if (props?.dataIndex === "cantidad" && !props.record.insumoMatriz) {
            return (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                    <InputNumberItem
                        sinBorde
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        placeholder="Cantidad"
                        defaultValue={props.record.cantidad}
                        onBlur={(value) => {
                            this.onChangeCantidad(value, props.record.key)
                        }}
                    />
                </td>
            )
        } else {
            return <td {...props} />
        }
    }

    quitarPartida(key) {
        let partidas = [...this.state.partidas]
        if (key) {
            const arrayKey = key.split("-").map(value => Number(value))
            if (arrayKey.length === 1) {
                partidas.splice(arrayKey[0], 1)
            } else if (arrayKey.length === 2) {
                partidas[arrayKey[0]].children.splice(arrayKey[1], 1)
            } else if (arrayKey.length === 3) {
                partidas[arrayKey[0]].children[arrayKey[1]].children.splice(arrayKey[2], 1)
            } else if (arrayKey.length === 4) {
                partidas[arrayKey[0]].children[arrayKey[1]].children[arrayKey[2]].children.splice(arrayKey[3], 1)
            }
        }

        const nuevasPartidas = this.calcularPresupuesto(partidas)

        this.setState({ partidas: nuevasPartidas })
    }

    onExpandedRowsChange(value) {
        this.setState({ expandedRowKeys: value })
    }

    requerirInsumos() {
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true })
            axios
                .post("presupuestosPU/requerirInsumos", { ...values, valueExplosion: this.state.valueExplosion, presupuestoId: this.state.id, empresaId: this.props.empresaId }, {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
                .then(async (res) => {
                    if (res.data.success === 2) {
                        message.success("Se ha generado la requisicion numero: " + res.data.numeroRequisicion);
                        await this.consultarTodo()
                        await this.onChangeExplosion(this.state.valueExplosion)
                        this.setState({ loading: false });
                    } else if (res.data.success === 1) {
                        message.error(res.data.message);
                        this.setState({ loading: false });
                    } else {
                        message.error("Hubo un error y la requisicion no se genero");
                        this.setState({ loading: false });
                    }
                });
        }).catch(() => this.onFinishFailed())
    }

    pedirTotales() {
        let newFielsValue = {}
        for (const insumoExplosion of this.state.insumosExplosionados) {
            newFielsValue['pedir' + insumoExplosion.insumoId] = round10Decimals(insumoExplosion.cantidad - insumoExplosion.cantidadPedida)
        }

        this.cambioPedir()

        this.formRef.current.setFieldsValue(newFielsValue)
    }

    async cambioPedir() {

        await this.setState({ loadingExplosion: true })

        let totalCostoPedir = 0

        const nuevosInsumosExplosionados = await Promise.all(this.state.insumosExplosionados.map(async (valueIE) => {

            const pedir = await this.formRef.current.getFieldValue('pedir' + valueIE.insumoId)

            totalCostoPedir = round2Decimals(totalCostoPedir + (valueIE.costo * (pedir || 0)))

            return {
                ...valueIE,
                costoPedir: round2Decimals(valueIE.costo * (pedir || 0))
            }
        }))

        await this.setState({ loadingExplosion: false, insumosExplosionados: nuevosInsumosExplosionados, totalCostoPedir })
    }


    render() {

        const columns = [
            {
                title: "Clave",
                dataIndex: "key",
                key: "key",
                width: "15%",
                render: (value) => {
                    const arrayKey = value.split('-').map((value) => Number(value) + 1)
                    let texto = ''

                    for (const [index, key] of arrayKey.entries()) {
                        if (index === (arrayKey.length - 1)) {
                            texto += key
                        } else {
                            texto += key + '-'
                        }
                    }

                    return texto
                }
            },
            {
                title: "Tipo",
                dataIndex: "tipo",
                key: "tipo",
                render: (value) => {
                    if (this.state.confirmado) {
                        return value === 0 ? 'Partida' : 'Matriz'
                    } else {
                        return value
                    }
                },
                width: "10%",
            },
            {
                title: "Nombre",
                dataIndex: "nombre",
                key: "nombre",
                render: (value, record) => {
                    if (this.state.confirmado) {
                        if (record.tipo === 1) {
                            const matriz = this.state.todasMatrices.find(valueTM => valueTM._id.toString() === value.toString())
                            if (matriz) {
                                return matriz.descripcion
                            } else {
                                return ''
                            }
                        } else {
                            return value
                        }
                    } else {
                        return value
                    }
                },
                width: "25%",
            },
            {
                title: "Costo",
                dataIndex: "costo",
                key: "costo",
                sorter: (a, b) => {
                    if (a.costo < b.costo) {
                        return -1;
                    }
                    if (a.costo > b.costo) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "10%",
                align: 'right'
            },
            {
                title: "Unidad",
                dataIndex: "unidad",
                key: "unidad",
                width: "10%",
                align: 'left'
            },
            {
                title: "Cantidad",
                dataIndex: "cantidad",
                key: "cantidad",
                width: "10%",
                align: 'left'
            },
            {
                title: "Costo",
                dataIndex: "costoTotal",
                key: "costoTotal",
                sorter: (a, b) => {
                    if (a.costoTotal < b.costoTotal) {
                        return -1;
                    }
                    if (a.costoTotal > b.costoTotal) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "10%",
                align: 'right'
            },
            {
                title: "Accion",
                dataIndex: "key",
                key: "key",
                width: "10%",
                render: (value, record) => {
                    if (record.tipo === 0 && !record?.insumoMatriz && value.split('-').length < 5 && !this.state.confirmado) {
                        return (
                            <>
                                <PlusCircleOutlined
                                    onClick={() => this.agregarPartida(value)}
                                    style={{
                                        color: "green",
                                        marginRight: 20
                                    }}
                                />
                                <MinusCircleOutlined
                                    onClick={() => this.quitarPartida(value)}
                                    style={{
                                        color: "red"
                                    }}
                                />
                            </>
                        )
                    } else if (!record?.insumoMatriz && !this.state.confirmado) {
                        return (
                            <>
                                <MinusCircleOutlined
                                    onClick={() => this.quitarPartida(value)}
                                    style={{
                                        color: "red"
                                    }}
                                />
                            </>
                        )
                    } else {
                        return null
                    }
                }
            }
        ]


        const mergedColumns = columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    dataIndex: col?.dataIndex,
                    title: col?.title,
                }),
            };
        });

        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Presupuesto"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="presupuestoPU"
                            layout
                            bottom={
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    <Space>
                                        {!this.state.confirmado ?
                                            <Popconfirm
                                                title="Seguro que quieres confirmar este presupuesto, al confirmarlo no se podran editar los datos de las partidas"
                                                onConfirm={() => this.confirmarPresupuesto()}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <ButtonItem
                                                    verde
                                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                                >
                                                    Confirmar
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                        <ButtonItem
                                            type="primary"
                                            htmlType="submit"
                                            style={{ display: "block", margin: "0 0 0 auto" }}
                                        >
                                            Guardar
                                        </ButtonItem>
                                    </Space>
                                </div>
                            }
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed}
                        // onFieldsChange={this.onFieldsChange.bind(this)}
                        >
                            <Tabs defaultActiveKey={1} onTabClick={(key) => {
                                if (key === '3') {
                                    this.arbolPresupuesto()
                                }
                                if (key === '4') {
                                    this.explosionInsumos()
                                }
                                // if (key === '5') {
                                //     this.tablaPresupuesto()
                                // }
                            }}>
                                <TabPane tab="General" tabKey={1} key={1} forceRender>
                                    <Row>
                                        <Col span={12}>

                                            <FormItem
                                                label="Nombre"
                                                name="nombre"
                                                required
                                                margin
                                                pattern
                                            >
                                                <InputItem
                                                    placeholder="Nombre"
                                                />
                                            </FormItem>
                                            <FormItem
                                                label="Fecha Inicio"
                                                name="fechaInicio"
                                                required
                                                margin
                                                initialValue={moment()}
                                            >
                                                <DatePickerItem allowClear={false} placeholder="Fecha Inicio" />
                                            </FormItem>
                                            <FormItem
                                                label="Fecha Final"
                                                name="fechaFinal"
                                                required
                                                margin
                                                initialValue={moment()}
                                            >
                                                <DatePickerItem allowClear={false} placeholder="Fecha Final" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Proyecto" name="proyectoId" margin required>
                                                <SelectItem disabled={this.state.confirmado} placeholder="Proyecto">
                                                    {this.state.objetoProyectos}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>
                                {/* <TabPane tab="Partidas" tabKey={2} key={2} forceRender>


                                    <FormInitial.List noStyle name="partidas">
                                        {(fields, { add, remove }) => (
                                            <>
                                                <Timeline>
                                                    {fields.map(
                                                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                                                            <>
                                                                <Timeline.Item style={{ margin: 0, padding: 0 }} color="green">
                                                                    <Row>
                                                                        <Col span={24} offset={0}>
                                                                            <Space direction="horizontal">
                                                                                <FormItem
                                                                                    {...restField}
                                                                                    name={[name, "tipo"]}
                                                                                    fieldKey={[arrayKey, "tipo"]}
                                                                                    required
                                                                                    noStyle
                                                                                    margin
                                                                                    initialValue={0}
                                                                                >
                                                                                    <SelectItem
                                                                                        sinBorde
                                                                                        autoFocus
                                                                                        placeholder="Tipo"
                                                                                    >
                                                                                        <Option value={0} key={0}>Partida</Option>
                                                                                        <Option value={1} key={1}>Matriz</Option>
                                                                                    </SelectItem>
                                                                                </FormItem>
                                                                                <FormItem shouldUpdate noStyle>
                                                                                    {({ getFieldValue }) => {
                                                                                        const partidas = getFieldValue('partidas')
                                                                                        const value = partidas[arrayKey]

                                                                                        if (value.tipo === 0) {
                                                                                            return (
                                                                                                <FormItem
                                                                                                    {...restField}
                                                                                                    name={[name, "nombre"]}
                                                                                                    fieldKey={[arrayKey, "nombre"]}
                                                                                                    required
                                                                                                    margin
                                                                                                    pattern
                                                                                                >
                                                                                                    <InputItem
                                                                                                        width='100px'
                                                                                                        placeholder="Nombre"
                                                                                                    />
                                                                                                </FormItem>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <FormItem
                                                                                                    {...restField}
                                                                                                    name={[name, "matrizId"]}
                                                                                                    fieldKey={[arrayKey, "matrizId"]}
                                                                                                    margin
                                                                                                    required
                                                                                                    noStyle
                                                                                                >
                                                                                                    <SelectItem
                                                                                                        sinBorde
                                                                                                        autoFocus
                                                                                                        placeholder="Matriz"
                                                                                                    >
                                                                                                        {
                                                                                                            this.state
                                                                                                                .objetoMatrices
                                                                                                        }
                                                                                                    </SelectItem>
                                                                                                </FormItem>
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                </FormItem>
                                                                                <FormItem
                                                                                    {...restField}
                                                                                    name={[name, "cantidad"]}
                                                                                    fieldKey={[arrayKey, "cantidad"]}
                                                                                    number
                                                                                    noStyle
                                                                                    required
                                                                                    margin
                                                                                    initialValue={1}
                                                                                >
                                                                                    <InputNumberItem sinBorde />
                                                                                </FormItem>
                                                                                <FormItem shouldUpdate noStyle>
                                                                                    {({ getFieldValue }) => {
                                                                                        const partidas = getFieldValue('partidas')
                                                                                        const value = partidas[arrayKey]

                                                                                        if (value.tipo === 0) {
                                                                                            return (
                                                                                                <>
                                                                                                    <PlusCircleOutlined
                                                                                                        onClick={() => this.agregarAPartida1(arrayKey)}
                                                                                                        style={{
                                                                                                            color: "green",
                                                                                                            marginRight: 20
                                                                                                        }}
                                                                                                    />
                                                                                                    <MinusCircleOutlined
                                                                                                        onClick={() => remove(name)}
                                                                                                        style={{
                                                                                                            color: "red"
                                                                                                        }}
                                                                                                    />
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <MinusCircleOutlined
                                                                                                    onClick={() => remove(name)}
                                                                                                    style={{
                                                                                                        color: "red"
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                </FormItem>
                                                                            </Space>
                                                                        </Col>
                                                                    </Row>

                                                                    <FormItem shouldUpdate noStyle>
                                                                        {({ getFieldValue }) => {
                                                                            const partidas = getFieldValue('partidas')
                                                                            const value = partidas[arrayKey]


                                                                            if (value.tipo === 0) {
                                                                                return (


                                                                                    <FormInitial.List noStyle name={[name, "partidas"]} fieldKey={[arrayKey, "partidas"]}>
                                                                                        {(fields2, funciones2) => (
                                                                                            <Timeline>
                                                                                                {fields2.map(
                                                                                                    (fieldValue2, arrayKey2) => (
                                                                                                        <>
                                                                                                            <Timeline.Item style={{ margin: 0, padding: 0 }} color="blue">
                                                                                                                <Row>
                                                                                                                    <Col span={24} offset={0}>
                                                                                                                        <Space direction="horizontal">
                                                                                                                            <FormItem
                                                                                                                                {...fieldValue2}
                                                                                                                                name={[fieldValue2.name, "tipo"]}
                                                                                                                                fieldKey={[arrayKey2, "tipo"]}
                                                                                                                                required
                                                                                                                                noStyle
                                                                                                                                margin
                                                                                                                                initialValue={0}
                                                                                                                            >
                                                                                                                                <SelectItem
                                                                                                                                    sinBorde
                                                                                                                                    autoFocus
                                                                                                                                    placeholder="Tipo"
                                                                                                                                >
                                                                                                                                    <Option value={0} key={0}>Partida</Option>
                                                                                                                                    <Option value={1} key={1}>Matriz</Option>
                                                                                                                                </SelectItem>
                                                                                                                            </FormItem>
                                                                                                                            <FormItem shouldUpdate noStyle>
                                                                                                                                {({ getFieldValue }) => {
                                                                                                                                    const partidas = getFieldValue('partidas')
                                                                                                                                    const value = partidas[arrayKey].partidas[arrayKey2]

                                                                                                                                    if (value.tipo === 0) {
                                                                                                                                        return (
                                                                                                                                            <FormItem
                                                                                                                                                {...fieldValue2}
                                                                                                                                                name={[fieldValue2.name, "nombre"]}
                                                                                                                                                fieldKey={[arrayKey2, "nombre"]}
                                                                                                                                                required
                                                                                                                                                margin
                                                                                                                                                pattern
                                                                                                                                            >
                                                                                                                                                <InputItem
                                                                                                                                                    placeholder="Nombre"
                                                                                                                                                />
                                                                                                                                            </FormItem>
                                                                                                                                        )
                                                                                                                                    } else {
                                                                                                                                        return (
                                                                                                                                            <FormItem
                                                                                                                                                {...fieldValue2}
                                                                                                                                                name={[fieldValue2.name, "matrizId"]}
                                                                                                                                                fieldKey={[arrayKey2, "matrizId"]}
                                                                                                                                                margin
                                                                                                                                                required
                                                                                                                                                noStyle
                                                                                                                                            >
                                                                                                                                                <SelectItem
                                                                                                                                                    sinBorde
                                                                                                                                                    autoFocus
                                                                                                                                                    placeholder="Matriz"
                                                                                                                                                >
                                                                                                                                                    {
                                                                                                                                                        this.state
                                                                                                                                                            .objetoMatrices
                                                                                                                                                    }
                                                                                                                                                </SelectItem>
                                                                                                                                            </FormItem>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            </FormItem>
                                                                                                                            <FormItem
                                                                                                                                {...fieldValue2}
                                                                                                                                name={[fieldValue2.name, "cantidad"]}
                                                                                                                                fieldKey={[arrayKey2, "cantidad"]}
                                                                                                                                number
                                                                                                                                noStyle
                                                                                                                                required
                                                                                                                                margin
                                                                                                                                initialValue={1}
                                                                                                                            >
                                                                                                                                <InputNumberItem sinBorde />
                                                                                                                            </FormItem>
                                                                                                                            <FormItem shouldUpdate noStyle>
                                                                                                                                {({ getFieldValue }) => {
                                                                                                                                    const partidas = getFieldValue('partidas')
                                                                                                                                    const value = partidas[arrayKey].partidas[arrayKey2]

                                                                                                                                    if (value.tipo === 0) {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <PlusCircleOutlined
                                                                                                                                                    onClick={() => this.agregarAPartida2(arrayKey, arrayKey2)}
                                                                                                                                                    style={{
                                                                                                                                                        color: "green",
                                                                                                                                                        marginRight: 20
                                                                                                                                                    }}
                                                                                                                                                />
                                                                                                                                                <MinusCircleOutlined
                                                                                                                                                    onClick={() => funciones2.remove(fieldValue2.name)}
                                                                                                                                                    style={{
                                                                                                                                                        color: "red"
                                                                                                                                                    }}
                                                                                                                                                />
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    } else {
                                                                                                                                        return (
                                                                                                                                            <MinusCircleOutlined
                                                                                                                                                onClick={() => funciones2.remove(fieldValue2.name)}
                                                                                                                                                style={{
                                                                                                                                                    color: "red"
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            </FormItem>
                                                                                                                        </Space>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                <FormItem shouldUpdate noStyle>
                                                                                                                    {({ getFieldValue }) => {
                                                                                                                        const partidas = getFieldValue('partidas')
                                                                                                                        const value = partidas[arrayKey].partidas[arrayKey2]


                                                                                                                        if (value.tipo === 0) {
                                                                                                                            return (


                                                                                                                                <FormInitial.List noStyle name={[fieldValue2.name, "partidas"]} fieldKey={[arrayKey2, "partidas"]}>
                                                                                                                                    {(fields3, funciones3) => (
                                                                                                                                        <Timeline>
                                                                                                                                            {fields3.map(
                                                                                                                                                (fieldValue3, arrayKey3) => (
                                                                                                                                                    <>
                                                                                                                                                        <Timeline.Item style={{ margin: 0, padding: 0 }} color="orange">
                                                                                                                                                            <Row>
                                                                                                                                                                <Col span={24} offset={0}>
                                                                                                                                                                    <Space direction="horizontal">
                                                                                                                                                                        <FormItem
                                                                                                                                                                            {...fieldValue3}
                                                                                                                                                                            name={[fieldValue3.name, "tipo"]}
                                                                                                                                                                            fieldKey={[arrayKey3, "tipo"]}
                                                                                                                                                                            required
                                                                                                                                                                            noStyle
                                                                                                                                                                            margin
                                                                                                                                                                            initialValue={0}
                                                                                                                                                                        >
                                                                                                                                                                            <SelectItem
                                                                                                                                                                                sinBorde
                                                                                                                                                                                autoFocus
                                                                                                                                                                                placeholder="Tipo"
                                                                                                                                                                            >
                                                                                                                                                                                <Option value={0} key={0}>Partida</Option>
                                                                                                                                                                                <Option value={1} key={1}>Matriz</Option>
                                                                                                                                                                            </SelectItem>
                                                                                                                                                                        </FormItem>
                                                                                                                                                                        <FormItem shouldUpdate noStyle>
                                                                                                                                                                            {({ getFieldValue }) => {
                                                                                                                                                                                const partidas = getFieldValue('partidas')
                                                                                                                                                                                const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3]

                                                                                                                                                                                if (value.tipo === 0) {
                                                                                                                                                                                    return (
                                                                                                                                                                                        <FormItem
                                                                                                                                                                                            {...fieldValue3}
                                                                                                                                                                                            name={[fieldValue3.name, "nombre"]}
                                                                                                                                                                                            fieldKey={[arrayKey3, "nombre"]}
                                                                                                                                                                                            required
                                                                                                                                                                                            margin
                                                                                                                                                                                            pattern
                                                                                                                                                                                        >
                                                                                                                                                                                            <InputItem
                                                                                                                                                                                                placeholder="Nombre"
                                                                                                                                                                                            />
                                                                                                                                                                                        </FormItem>
                                                                                                                                                                                    )
                                                                                                                                                                                } else {
                                                                                                                                                                                    return (
                                                                                                                                                                                        <FormItem
                                                                                                                                                                                            {...fieldValue3}
                                                                                                                                                                                            name={[fieldValue3.name, "matrizId"]}
                                                                                                                                                                                            fieldKey={[arrayKey3, "matrizId"]}
                                                                                                                                                                                            margin
                                                                                                                                                                                            required
                                                                                                                                                                                            noStyle
                                                                                                                                                                                        >
                                                                                                                                                                                            <SelectItem
                                                                                                                                                                                                sinBorde
                                                                                                                                                                                                autoFocus
                                                                                                                                                                                                placeholder="Matriz"
                                                                                                                                                                                            >
                                                                                                                                                                                                {
                                                                                                                                                                                                    this.state
                                                                                                                                                                                                        .objetoMatrices
                                                                                                                                                                                                }
                                                                                                                                                                                            </SelectItem>
                                                                                                                                                                                        </FormItem>
                                                                                                                                                                                    )
                                                                                                                                                                                }
                                                                                                                                                                            }}
                                                                                                                                                                        </FormItem>
                                                                                                                                                                        <FormItem
                                                                                                                                                                            {...fieldValue3}
                                                                                                                                                                            name={[fieldValue3.name, "cantidad"]}
                                                                                                                                                                            fieldKey={[arrayKey3, "cantidad"]}
                                                                                                                                                                            number
                                                                                                                                                                            noStyle
                                                                                                                                                                            required
                                                                                                                                                                            margin
                                                                                                                                                                            initialValue={1}
                                                                                                                                                                        >
                                                                                                                                                                            <InputNumberItem sinBorde />
                                                                                                                                                                        </FormItem>
                                                                                                                                                                        <FormItem shouldUpdate noStyle>
                                                                                                                                                                            {({ getFieldValue }) => {
                                                                                                                                                                                const partidas = getFieldValue('partidas')
                                                                                                                                                                                const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3]

                                                                                                                                                                                if (value.tipo === 0) {
                                                                                                                                                                                    return (
                                                                                                                                                                                        <>
                                                                                                                                                                                            <PlusCircleOutlined
                                                                                                                                                                                                onClick={() => this.agregarAPartida3(arrayKey, arrayKey2, arrayKey3)}
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: "green",
                                                                                                                                                                                                    marginRight: 20
                                                                                                                                                                                                }}
                                                                                                                                                                                            />
                                                                                                                                                                                            <MinusCircleOutlined
                                                                                                                                                                                                onClick={() => funciones3.remove(fieldValue3.name)}
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color: "red"
                                                                                                                                                                                                }}
                                                                                                                                                                                            />
                                                                                                                                                                                        </>
                                                                                                                                                                                    )
                                                                                                                                                                                } else {
                                                                                                                                                                                    return (
                                                                                                                                                                                        <MinusCircleOutlined
                                                                                                                                                                                            onClick={() => funciones3.remove(fieldValue3.name)}
                                                                                                                                                                                            style={{
                                                                                                                                                                                                color: "red"
                                                                                                                                                                                            }}
                                                                                                                                                                                        />
                                                                                                                                                                                    )
                                                                                                                                                                                }
                                                                                                                                                                            }}
                                                                                                                                                                        </FormItem>
                                                                                                                                                                    </Space>
                                                                                                                                                                </Col>
                                                                                                                                                            </Row>
                                                                                                                                                            <FormItem shouldUpdate noStyle>
                                                                                                                                                                {({ getFieldValue }) => {
                                                                                                                                                                    const partidas = getFieldValue('partidas')
                                                                                                                                                                    const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3]


                                                                                                                                                                    if (value.tipo === 0) {
                                                                                                                                                                        return (


                                                                                                                                                                            <FormInitial.List noStyle name={[fieldValue3.name, "partidas"]} fieldKey={[arrayKey3, "partidas"]}>
                                                                                                                                                                                {(fields4, funciones4) => (
                                                                                                                                                                                    <Timeline>
                                                                                                                                                                                        {fields4.map(
                                                                                                                                                                                            (fieldValue4, arrayKey4) => (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    <Timeline.Item style={{ margin: 0, padding: 0 }} color="yellow">
                                                                                                                                                                                                        <Row>
                                                                                                                                                                                                            <Col span={24} offset={0}>
                                                                                                                                                                                                                <Space direction="horizontal">
                                                                                                                                                                                                                    <FormItem
                                                                                                                                                                                                                        {...fieldValue4}
                                                                                                                                                                                                                        name={[fieldValue4.name, "tipo"]}
                                                                                                                                                                                                                        fieldKey={[arrayKey4, "tipo"]}
                                                                                                                                                                                                                        required
                                                                                                                                                                                                                        noStyle
                                                                                                                                                                                                                        margin
                                                                                                                                                                                                                        initialValue={0}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <SelectItem
                                                                                                                                                                                                                            sinBorde
                                                                                                                                                                                                                            autoFocus
                                                                                                                                                                                                                            placeholder="Tipo"
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <Option value={0} key={0}>Partida</Option>
                                                                                                                                                                                                                            <Option value={1} key={1}>Matriz</Option>
                                                                                                                                                                                                                        </SelectItem>
                                                                                                                                                                                                                    </FormItem>
                                                                                                                                                                                                                    <FormItem shouldUpdate noStyle>
                                                                                                                                                                                                                        {({ getFieldValue }) => {
                                                                                                                                                                                                                            const partidas = getFieldValue('partidas')
                                                                                                                                                                                                                            const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3].partidas[arrayKey4]

                                                                                                                                                                                                                            if (value.tipo === 0) {
                                                                                                                                                                                                                                return (
                                                                                                                                                                                                                                    <FormItem
                                                                                                                                                                                                                                        {...fieldValue4}
                                                                                                                                                                                                                                        name={[fieldValue4.name, "nombre"]}
                                                                                                                                                                                                                                        fieldKey={[arrayKey4, "nombre"]}
                                                                                                                                                                                                                                        required
                                                                                                                                                                                                                                        margin
                                                                                                                                                                                                                                        pattern
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <InputItem
                                                                                                                                                                                                                                            placeholder="Nombre"
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    </FormItem>
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                            } else {
                                                                                                                                                                                                                                return (
                                                                                                                                                                                                                                    <FormItem
                                                                                                                                                                                                                                        {...fieldValue4}
                                                                                                                                                                                                                                        name={[fieldValue4.name, "matrizId"]}
                                                                                                                                                                                                                                        fieldKey={[arrayKey4, "matrizId"]}
                                                                                                                                                                                                                                        margin
                                                                                                                                                                                                                                        required
                                                                                                                                                                                                                                        noStyle
                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                        <SelectItem
                                                                                                                                                                                                                                            sinBorde
                                                                                                                                                                                                                                            autoFocus
                                                                                                                                                                                                                                            placeholder="Matriz"
                                                                                                                                                                                                                                        >
                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                this.state
                                                                                                                                                                                                                                                    .objetoMatrices
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                        </SelectItem>
                                                                                                                                                                                                                                    </FormItem>
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    </FormItem>
                                                                                                                                                                                                                    <FormItem
                                                                                                                                                                                                                        {...fieldValue4}
                                                                                                                                                                                                                        name={[fieldValue4.name, "cantidad"]}
                                                                                                                                                                                                                        fieldKey={[arrayKey4, "cantidad"]}
                                                                                                                                                                                                                        number
                                                                                                                                                                                                                        noStyle
                                                                                                                                                                                                                        required
                                                                                                                                                                                                                        margin
                                                                                                                                                                                                                        initialValue={1}
                                                                                                                                                                                                                    >
                                                                                                                                                                                                                        <InputNumberItem sinBorde />
                                                                                                                                                                                                                    </FormItem>
                                                                                                                                                                                                                    <FormItem shouldUpdate noStyle>
                                                                                                                                                                                                                        {({ getFieldValue }) => {
                                                                                                                                                                                                                            const partidas = getFieldValue('partidas')
                                                                                                                                                                                                                            const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3].partidas[arrayKey4]

                                                                                                                                                                                                                            if (value.tipo === 0) {
                                                                                                                                                                                                                                return (
                                                                                                                                                                                                                                    <>
                                                                                                                                                                                                                                        <PlusCircleOutlined
                                                                                                                                                                                                                                            onClick={() => this.agregarAPartida4(arrayKey, arrayKey2, arrayKey3, arrayKey4)}
                                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                                color: "green",
                                                                                                                                                                                                                                                marginRight: 20
                                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                        <MinusCircleOutlined
                                                                                                                                                                                                                                            onClick={() => funciones4.remove(fieldValue4.name)}
                                                                                                                                                                                                                                            style={{
                                                                                                                                                                                                                                                color: "red"
                                                                                                                                                                                                                                            }}
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    </>
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                            } else {
                                                                                                                                                                                                                                return (
                                                                                                                                                                                                                                    <MinusCircleOutlined
                                                                                                                                                                                                                                        onClick={() => funciones4.remove(fieldValue4.name)}
                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                            color: "red"
                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                    </FormItem>
                                                                                                                                                                                                                </Space>
                                                                                                                                                                                                            </Col>
                                                                                                                                                                                                        </Row>
                                                                                                                                                                                                        <FormItem shouldUpdate noStyle>
                                                                                                                                                                                                            {({ getFieldValue }) => {
                                                                                                                                                                                                                const partidas = getFieldValue('partidas')
                                                                                                                                                                                                                const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3].partidas[arrayKey4]


                                                                                                                                                                                                                if (value.tipo === 0) {
                                                                                                                                                                                                                    return (


                                                                                                                                                                                                                        <FormInitial.List noStyle name={[fieldValue4.name, "partidas"]} fieldKey={[arrayKey4, "partidas"]}>
                                                                                                                                                                                                                            {(fields5) => (
                                                                                                                                                                                                                                <Timeline>
                                                                                                                                                                                                                                    {fields5.map(
                                                                                                                                                                                                                                        (fieldValue5, arrayKey5) => (
                                                                                                                                                                                                                                            <>
                                                                                                                                                                                                                                                <Timeline.Item style={{ margin: 0, padding: 0 }} color="red">
                                                                                                                                                                                                                                                    <Row>
                                                                                                                                                                                                                                                        <Col span={24} offset={0}>
                                                                                                                                                                                                                                                            <Space direction="horizontal">
                                                                                                                                                                                                                                                                <FormItem
                                                                                                                                                                                                                                                                    {...fieldValue5}
                                                                                                                                                                                                                                                                    name={[fieldValue5.name, "tipo"]}
                                                                                                                                                                                                                                                                    fieldKey={[arrayKey5, "tipo"]}
                                                                                                                                                                                                                                                                    required
                                                                                                                                                                                                                                                                    noStyle
                                                                                                                                                                                                                                                                    margin
                                                                                                                                                                                                                                                                    initialValue={1}
                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                    <SelectItem
                                                                                                                                                                                                                                                                        sinBorde
                                                                                                                                                                                                                                                                        autoFocus
                                                                                                                                                                                                                                                                        placeholder="Tipo"
                                                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                                                        <Option value={1} key={1}>Matriz</Option>
                                                                                                                                                                                                                                                                    </SelectItem>
                                                                                                                                                                                                                                                                </FormItem>
                                                                                                                                                                                                                                                                <FormItem shouldUpdate noStyle>
                                                                                                                                                                                                                                                                    {({ getFieldValue }) => {
                                                                                                                                                                                                                                                                        const partidas = getFieldValue('partidas')
                                                                                                                                                                                                                                                                        const value = partidas[arrayKey].partidas[arrayKey2].partidas[arrayKey3].partidas[arrayKey4].partidas[arrayKey5]

                                                                                                                                                                                                                                                                        if (value.tipo === 0) {
                                                                                                                                                                                                                                                                            return (
                                                                                                                                                                                                                                                                                <FormItem
                                                                                                                                                                                                                                                                                    {...fieldValue5}
                                                                                                                                                                                                                                                                                    name={[fieldValue5.name, "nombre"]}
                                                                                                                                                                                                                                                                                    fieldKey={[arrayKey5, "nombre"]}
                                                                                                                                                                                                                                                                                    required
                                                                                                                                                                                                                                                                                    margin
                                                                                                                                                                                                                                                                                    pattern
                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                    <InputItem
                                                                                                                                                                                                                                                                                        placeholder="Nombre"
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                </FormItem>
                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                        } else {
                                                                                                                                                                                                                                                                            return (
                                                                                                                                                                                                                                                                                <FormItem
                                                                                                                                                                                                                                                                                    {...fieldValue5}
                                                                                                                                                                                                                                                                                    name={[fieldValue5.name, "matrizId"]}
                                                                                                                                                                                                                                                                                    fieldKey={[arrayKey5, "matrizId"]}
                                                                                                                                                                                                                                                                                    margin
                                                                                                                                                                                                                                                                                    required
                                                                                                                                                                                                                                                                                    noStyle
                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                    <SelectItem
                                                                                                                                                                                                                                                                                        sinBorde
                                                                                                                                                                                                                                                                                        autoFocus
                                                                                                                                                                                                                                                                                        placeholder="Matriz"
                                                                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                                                            this.state
                                                                                                                                                                                                                                                                                                .objetoMatrices
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                    </SelectItem>
                                                                                                                                                                                                                                                                                </FormItem>
                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                    }}
                                                                                                                                                                                                                                                                </FormItem>
                                                                                                                                                                                                                                                                <FormItem
                                                                                                                                                                                                                                                                    {...fieldValue5}
                                                                                                                                                                                                                                                                    name={[fieldValue5.name, "cantidad"]}
                                                                                                                                                                                                                                                                    fieldKey={[arrayKey5, "cantidad"]}
                                                                                                                                                                                                                                                                    number
                                                                                                                                                                                                                                                                    noStyle
                                                                                                                                                                                                                                                                    required
                                                                                                                                                                                                                                                                    margin
                                                                                                                                                                                                                                                                    initialValue={1}
                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                    <InputNumberItem sinBorde />
                                                                                                                                                                                                                                                                </FormItem>
                                                                                                                                                                                                                                                            </Space>
                                                                                                                                                                                                                                                        </Col>
                                                                                                                                                                                                                                                    </Row>
                                                                                                                                                                                                                                                </Timeline.Item>

                                                                                                                                                                                                                                            </>
                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                    )}
                                                                                                                                                                                                                                </Timeline>
                                                                                                                                                                                                                            )}
                                                                                                                                                                                                                        </FormInitial.List>








                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            }}
                                                                                                                                                                                                        </FormItem>
                                                                                                                                                                                                    </Timeline.Item>
                                                                                                                                                                                                </>
                                                                                                                                                                                            )
                                                                                                                                                                                        )}
                                                                                                                                                                                    </Timeline>
                                                                                                                                                                                )}
                                                                                                                                                                            </FormInitial.List>








                                                                                                                                                                        )
                                                                                                                                                                    }
                                                                                                                                                                }}
                                                                                                                                                            </FormItem>
                                                                                                                                                        </Timeline.Item>
                                                                                                                                                    </>
                                                                                                                                                )
                                                                                                                                            )}
                                                                                                                                        </Timeline>
                                                                                                                                    )}
                                                                                                                                </FormInitial.List>








                                                                                                                            )
                                                                                                                        }
                                                                                                                    }}
                                                                                                                </FormItem>
                                                                                                            </Timeline.Item>
                                                                                                        </>
                                                                                                    )
                                                                                                )}
                                                                                            </Timeline>
                                                                                        )}
                                                                                    </FormInitial.List>








                                                                                )
                                                                            }
                                                                        }}
                                                                    </FormItem>

                                                                </Timeline.Item>
                                                            </>
                                                        )
                                                    )}

                                                </Timeline>
                                                <ButtonItem type="dashed" onClick={() => add()} block>
                                                    Agregar partida
                                                </ButtonItem>
                                            </>
                                        )}
                                    </FormInitial.List>

                                </TabPane> */}
                                {/* <TabPane tab="Arbol" tabKey='3' key='3' forceRender>
                                    <Spin spinning={this.state.loadingTabla}>
                                        <Tree expandable={{defaultExpandAllRows: true}} showLine treeData={this.state.partidasArbol} />
                                    </Spin>
                                </TabPane> */}

                                <TabPane tab="Partidas" tabKey='5' key='5' forceRender>
                                    <Spin spinning={this.state.loadingExplosion}>
                                        {!this.state.confirmado ?
                                            <ButtonItem onClick={() => this.agregarPartida()}>Agregar partida</ButtonItem>
                                            : undefined}
                                        <TableItem
                                            components={this.state.confirmado ? undefined : {
                                                body: {
                                                    cell: this.Cell.bind(this)
                                                },
                                            }}
                                            rowKey={(value) => value.key}
                                            size="small"
                                            expandable={{
                                                expandedRowKeys: this.state.expandedRowKeys,
                                                onExpandedRowsChange: this.onExpandedRowsChange.bind(this)
                                            }}
                                            dataSource={this.state.partidas}
                                            columns={mergedColumns}
                                        />
                                    </Spin>
                                </TabPane>

                                <TabPane tab='Explosion de insumos' tabKey='4' key='4'>
                                    <Spin spinning={this.state.loadingExplosion}>
                                        <Row>
                                            <Col span={24}>
                                                <TreeSelect
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    // value={value}
                                                    dropdownStyle={{
                                                        maxHeight: 400,
                                                        overflow: 'auto',
                                                    }}
                                                    multiple
                                                    placeholder="Seleccionar"
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    value={this.state.valueExplosion}
                                                    onChange={this.onChangeExplosion.bind(this)}
                                                    treeData={this.state.partidasArbol}
                                                />
                                            </Col>
                                        </Row>
                                        <table className="table">
                                            {this.state.insumosExplosionados.length > 0 ?
                                                <thead>
                                                    <tr>
                                                        <th className="th-border" style={{ width: "35%" }}>
                                                            Insumo
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Unidad
                                                        </th>
                                                        <th className="th-border" style={{ width: "15%" }}>
                                                            Costo Unitario
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Inventario
                                                        </th>
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Cantidad
                                                        </th>
                                                        {this.state.confirmado ?
                                                            <>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Pedido
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Pedir
                                                                    <CalculatorOutlined onClick={() => this.pedirTotales()} style={{ marginLeft: '5px', color: '#00b5e2' }} />
                                                                </th>
                                                                <th className="th-border" style={{ width: "10%" }}>
                                                                    Costo
                                                                </th>
                                                            </>
                                                            : null}
                                                        <th className="th-border" style={{ width: "10%" }}>
                                                            Costo T
                                                        </th>
                                                    </tr>
                                                </thead>
                                                : null}
                                            {this.state.insumosExplosionados.map((valueInsumo, index) => {
                                                return (
                                                    <tbody key={index}>
                                                        <tr>
                                                            <td className="td-border" style={{ textAlign: 'left', wordBreak: 'keep-all', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.nombre}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'center' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.unidad}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costo)}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.cantidadProyecto}</p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{valueInsumo.cantidad}</p>
                                                            </td>
                                                            {this.state.confirmado ?
                                                                <>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <p style={{ margin: 5 }}>{valueInsumo.cantidadPedida}</p>
                                                                    </td>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <FormItem
                                                                            name={"pedir" + valueInsumo.insumoId}
                                                                            noStyle
                                                                            numberCero
                                                                            type='number'
                                                                            max={valueInsumo.cantidad - valueInsumo.cantidadPedida}
                                                                        >
                                                                            <InputNumberItem
                                                                                onChange={this.cambioPedir.bind(this)}
                                                                                sinBorde
                                                                                style={{
                                                                                    backgroundColor: 'transparent'
                                                                                }}
                                                                                placeholder={"Max: " + (valueInsumo.cantidad - (valueInsumo.cantidadPedida || 0))}
                                                                            />
                                                                        </FormItem>
                                                                    </td>
                                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoPedir)}</p>
                                                                    </td>
                                                                </>
                                                                : null}
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}>{dineroDisabledMask(valueInsumo.costoTotal)}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })}

                                            <tbody key='T'>
                                                <tr>
                                                    <td className="td-border" style={{ textAlign: 'left' }}>
                                                        <p style={{ margin: 5 }}>Total:</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'center' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}></p>
                                                    </td>
                                                    {this.state.confirmado ?
                                                        <>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                            <td className="td-border" style={{ textAlign: 'right' }}>
                                                                <p style={{ margin: 5 }}></p>
                                                            </td>
                                                        </>
                                                        : null}
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(this.state.totalCostoPedir)}</p>
                                                    </td>
                                                    <td className="td-border" style={{ textAlign: 'right' }}>
                                                        <p style={{ margin: 5 }}>{dineroDisabledMask(this.state.costoTotalIE)}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.confirmado && this.state.partidasArbol.length > 0 ?
                                            <Popconfirm
                                                title="Seguro que quieres generar una requisicion de estos insumos"
                                                onConfirm={() => this.requerirInsumos()}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <ButtonItem
                                                    blanco
                                                    style={{ display: "block", margin: "10px 0px 0 auto" }}
                                                >
                                                    Requerir insumos
                                                </ButtonItem>
                                            </Popconfirm>
                                            : null}
                                    </Spin>
                                </TabPane>
                            </Tabs>
                        </Form>
                    </Spin>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        envios: state.empresa.envios,
    };
};

export default connect(mapStateToProps)(PresupuestoPU);
