import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Select,
  Checkbox,
} from "antd";
import {
  dineroDisabledMask,
  Form,
  SelectItem,
  FormItem,
  InputNumberItem,
  round2Decimals,
  DatePickerItem,
  BoxItem,
  InputItem,
  HeadersItemSmall,
  HeaderItem,
  ButtonItem,
  NotificacionPolizas,
  SwitchItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { Alert } from "antd5";

const { Option } = Select;

moment.locale("es");

class PagarFactura extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosFactura: undefined,
    todasBancos: null,
    objetoBancos: null,
    todasMonedas: null,
    objetoMonedas: null,
    todasFormasDePago: null,
    objetoFormasDePago: null,
    id: this.props.location.state.id,
    notasDeCreditoPendiente: [],
    objetoNotasPendientes: null
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "bancos/selectBancos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: res.data });
          this.setState({
            objetoBancos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cajaefectivo/selectCajaEfectivo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: [...this.state.todasBancos, ...res.data] });
          this.setState({
            objetoBancos: [...this.state.objetoBancos, ...res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            })],
          });
        }
      });
      await axios
      .post(
        "formasdepago/selectFormasDePago",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasFormasDePago: res.data });
          this.setState({
            objetoFormasDePago: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("monedas/selectMonedas", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMonedas: res.data });
          this.setState({
            objetoMonedas: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cuentasporpagar/datosPagarFactura",
        {
          empresaId: this.props.empresaId,
          facturaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la factura");
        } else {
          this.formRef.current.setFieldsValue({ monedaId: res.data[0].monedaId, tipoDeCambioCompra: res.data[0].tipoDeCambio, tipoDeCambioPago: res.data[0].tipoDeCambio })
          this.setState({ datosFactura: res.data[0], 
            notasDeCreditoPendiente: res.data.notasDeCreditoPendiente,
            objetoNotasPendiente: res.data.notasDeCreditoPendiente.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {moment(value.fechaFactura).format('L') + " - " + dineroDisabledMask(value.totalPagar - value.totalPagado)+ ' - '+ value.moneda.abreviacion}
                </Option>
              );
            }),
           });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    if(values.pagarConNotas){
      const notaCredito = this.state.notasDeCreditoPendiente.find((valueTodasNotas) => valueTodasNotas._id.toString() === values.notaId.toString())
      if(values.monto > notaCredito.totalPagar){
        return message.info('No se puede pagar mas del saldo pendiente de la nota de credito')
      }
    }
    this.setState({ loading: true });
    axios
      .post(
        "cuentasporpagar/pagarFactura",
        {
          ...values,
          empresaId: this.props.empresaId,
          facturaId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado un pago");
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error(res.data.message);
        } else {
          message.error("Hubo un error y no se efectuo el pago");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }

  onChangeBanco(bancoId) {
    if (bancoId) {
      const banco = this.state.todasBancos.find((valueTodosBancos) => valueTodosBancos._id.toString() === bancoId.toString())
      if (banco.caja) {
        message.info('Se tendra que reponer el saldo en la caja chica')
      }
    }
  }

  onChangeNotaDeCredito(notaId) {
    if (notaId) {
      const notaCredito = this.state.notasDeCreditoPendiente.find((valueTodasNotas) => valueTodasNotas._id.toString() === notaId.toString())
      this.formRef.current.setFieldsValue({monedaId: notaCredito.monedaId, tipoDeCambioPago: notaCredito.tipoDeCambio})
    }
  }

  onChangeCheque(value){
    if(value.target.checked){
      this.formRef.current.setFieldsValue({formaDePagoId: '6125149afe07662acc100d59'})
    }else{
      this.formRef.current.setFieldsValue({formaDePagoId: '6125149afe07662acc100d5a'})
    }
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Pagar Factura"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha Vencimiento:{" "}
              {moment(this.state.datosFactura?.fechaPago).format("LL")}
            </h1>
          }
        />
        {this.state.notasDeCreditoPendiente.length > 0 &&
          <Alert onClick={() => this.formRef.current.setFieldsValue({pagarConNotas: true})} style={{ marginBottom: 10 }} description='Cambie a notas de credito para pagar con una de ellas' showIcon message="Este proveedor contiene notas de credito pendientes" type="warning" />
        }
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="pagarFactura"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Pagar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={6}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                    <p style={{ textAlign: 'center' }}>Moneda de la factura: {this.state.datosFactura?.moneda?.abreviacion}</p>
                    <FormItem
                      required
                      number
                      margin
                      label={`T. C. ${this.state.datosFactura?.moneda?.abreviacion ? this.state.datosFactura?.moneda?.abreviacion : ''}`}
                      name="tipoDeCambioCompra"
                      type="number"
                      initialValue={1}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumberItem
                        disabled={this.state.datosFactura?.monedaId === '60c7e4396465ad440c41c15a'}
                        dinero
                        placeholder={`T. C. ${this.state.datosFactura?.moneda?.abreviacion ? this.state.datosFactura?.moneda?.abreviacion : ''}`}
                      />
                    </FormItem>
                    <p style={{ textAlign: 'center' }}>Tipo de cambio factura: {dineroDisabledMask(this.state.datosFactura?.tipoDeCambio)}</p>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const pagarConNotas = getFieldValue('pagarConNotas')
                        return (
                          <FormItem
                          required
                          margin
                          name="monedaId"
                          label="Moneda Pago"
                          labelCol={{ span: 12 }}
                          wrapperCol={{ span: 12 }}
                          initialValue={'60c7e4396465ad440c41c15a'}
                        >
                          <SelectItem onChange={(value) => {
                            if (value === '60c7e4396465ad440c41c15a') {
                              this.formRef.current.setFieldsValue({ tipoDeCambioPago: 1 })
                            }
                          }} placeholder="Moneda" disabled={pagarConNotas}>
                            {this.state.objetoMonedas}
                          </SelectItem>
                        </FormItem>
                        )
                      }}  
                      </FormItem>
                    <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.pagarConNotas !== curr.pagarConNotas}>
                      {({ getFieldValue }) => {

                        const monedaId = getFieldValue('monedaId')

                        const pagarConNotas = getFieldValue('pagarConNotas')

                        const moneda = this.state.todasMonedas ? this.state.todasMonedas.find((valueTodasMonedas) => valueTodasMonedas._id.toString() === monedaId.toString()) : null

                        return (
                          <FormItem
                            required
                            number
                            margin
                            label={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            name="tipoDeCambioPago"
                            type="number"
                            initialValue={1}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            <InputNumberItem
                              disabled={monedaId === '60c7e4396465ad440c41c15a' || pagarConNotas}
                              dinero
                              placeholder={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                  </BoxItem>
                </Col>
                <Col span={10}>
                  <HeadersItemSmall>DATOS DE PAGO</HeadersItemSmall>
                  <BoxItem>
                    <FormItem
                      label="Fecha de Pago"
                      name="fechaPago"
                      required
                      margin
                      labelCol={{ span: 7 }}
                      wrapperCol={{ span: 10 }}
                    >
                      <DatePickerItem
                        placeholder="Fecha de Pago"
                        allowClear={false}
                      />
                    </FormItem>
                    {this.state.notasDeCreditoPendiente.length > 0 &&
                      <FormItem
                        name="pagarConNotas"
                        label="Pagar C/Notas"
                        tooltip={{ title: "Pagar la factura con notas de credito" }}
                        margin
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 17 }}
                        pattern
                        valuePropName="checked"
                      >
                        <SwitchItem placeholder="Pagar Con Notas" />
                      </FormItem>
                    }
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const pagarConNotas = getFieldValue('pagarConNotas')

                        if (pagarConNotas) {
                          return (
                            <FormItem
                              label="Nota"
                              name="notaId"
                              margin
                              required
                              labelCol={{ span: 7 }}
                              wrapperCol={{ span: 17 }}
                            >
                              <SelectItem placeholder="Nota" onChange={this.onChangeNotaDeCredito.bind(this)} >
                                {this.state.objetoNotasPendiente}
                              </SelectItem>
                            </FormItem>
                          )
                        } else {
                          return (
                            <>
                            <Row>
                              <Col span={14}>
                                <FormItem
                                  label="Banco"
                                  name="bancoId"
                                  margin
                                  labelCol={{ span: 12 }}
                                  wrapperCol={{ span: 12 }}
                                  required
                                >
                                  <SelectItem placeholder="Banco" onChange={this.onChangeBanco.bind(this)}>
                                    {this.state.objetoBancos}
                                  </SelectItem>
                                </FormItem>
                              </Col>
                              <Col span={10}>
                              <Row>
                              <Col span={12}>
                                <FormItem
                                  label="Cheque"
                                  name="cheque"
                                  margin
                                  labelCol={{ span: 20 }}
                                  wrapperCol={{ span: 4 }} 
                                  valuePropName="checked"
                                  initialValue={this.props.location.state.cheque}
                                >
                                  <Checkbox onChange={this.onChangeCheque.bind(this)} />
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem shouldUpdate noStyle>
                                  {({getFieldValue}) => {
                                    const cheque = getFieldValue('cheque')
                                    const bancoId = getFieldValue('bancoId')
                                    if(cheque && bancoId){
                                      let consecutivoCheque = this.state.todasBancos.find(tBancos => tBancos._id.toString() === bancoId.toString()).consecutivoCheque
                                      if(!consecutivoCheque){
                                        consecutivoCheque = 1
                                      }
                                      return (
                                        <h1 style={{textAlign: "center", justifyContent: "center"}}>N°: {consecutivoCheque}</h1>
                                      )
                                    }
                                  }}
                                </FormItem>
                              </Col>
                            </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <FormItem shouldUpdate noStyle>
                                  {({getFieldValue}) => {
                                    const cheque = getFieldValue('cheque')
                                      return (
                                        <FormItem
                                        name="formaDePagoId"
                                        label="F Pago"
                                        initialValue="6125149afe07662acc100d5a"
                                        margin
                                        required
                                        labelCol={{ span: 7 }}
                                        wrapperCol={{ span: 17 }} 
                                      >
                                        <SelectItem disabled={cheque} placeholder="Forma de Pago">
                                          {this.state.objetoFormasDePago}
                                        </SelectItem>
                                      </FormItem>
                                      )
                                  }}
                                </FormItem>
                              </Col>
                            </Row>
                            </>
                          )
                        }

                      }}
                    </FormItem>
                    <Row>
                      <Col span={14}>
                        <FormItem noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            let tipoDeCambioCompra = getFieldValue('tipoDeCambioCompra')
                            let tipoDeCambioPago = getFieldValue('tipoDeCambioPago')
                            const equivalencia = tipoDeCambioPago / tipoDeCambioCompra
                            let totalPagar =
                              this.state.datosFactura?.totalPagar || 0;
                            let totalPagado =
                              this.state.datosFactura?.totalPagado || 0;
                            let saldoPendiente = round2Decimals(
                              (totalPagar - totalPagado) / equivalencia
                            );
                            return (
                              <FormItem
                                required
                                number
                                margin
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                label="Monto a pagar"
                                name="monto"
                                type="number"
                                max={saldoPendiente}
                              >
                                <InputNumberItem dinero placeholder="Monto" />
                              </FormItem>
                            );
                          }}
                        </FormItem>
                      </Col>
                      <Col span={10}>
                        <ButtonItem
                          style={{ marginLeft: 10 }}
                          size="small"
                          type="primary"
                          onClick={() => {
                            let tipoDeCambioCompra = this.formRef.current.getFieldValue('tipoDeCambioCompra')
                            let tipoDeCambioPago = this.formRef.current.getFieldValue('tipoDeCambioPago')
                            const equivalencia = tipoDeCambioPago / tipoDeCambioCompra
                            let totalPagar =
                              this.state.datosFactura?.totalPagar || 0;
                            let totalPagado =
                              this.state.datosFactura?.totalPagado || 0;
                            let saldoPendiente = round2Decimals(
                              (totalPagar - totalPagado) / equivalencia
                            );
                            this.formRef.current.setFieldsValue({
                              monto: saldoPendiente,
                            });
                          }}
                        >
                          Pendiente
                        </ButtonItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <FormItem
                          name="referencia"
                          label="Referencia"
                          margin
                          labelCol={{ span: 7 }}
                          wrapperCol={{ span: 17 }}
                          pattern
                        >
                          <InputItem placeholder="Referencia" />
                        </FormItem>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                      <Col span={24}>
                        <table className="table" style={{ marginTop: 0 }}>
                          <tbody>
                            <tr>
                              <th
                                className="th-totales"
                                style={{ fontSize: 15 }}
                              >
                                Total a Pagar
                              </th>
                              <td
                                className="td-totales-right"
                                style={{ width: "50%", fontSize: 15 }}
                              >
                                {dineroDisabledMask(
                                  this.state.datosFactura?.totalPagar
                                )}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th
                                className="th-totales"
                                style={{ fontSize: 15 }}
                              >
                                Total Pagado
                              </th>
                              <td
                                className="td-totales-right"
                                style={{ fontSize: 15 }}
                              >
                                {dineroDisabledMask(
                                  this.state.datosFactura?.totalPagado
                                )}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th
                                className="th-totales"
                                style={{ fontSize: 15 }}
                              >
                                Saldo Pendiente
                              </th>
                              <td
                                className="td-totales-right"
                                style={{ color: "red", fontSize: 15 }}
                              >
                                {dineroDisabledMask(
                                  this.state.datosFactura?.totalPagar -
                                  this.state.datosFactura?.totalPagado
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </BoxItem>
                </Col>
                <Col span={8}>
                  <HeaderItem>PROVEEDOR</HeaderItem>
                  <BoxItem>
                    <h1
                      style={{ margin: 0, fontSize: 15, textAlign: "center" }}
                    >
                      {this.state.datosFactura?.proveedor.nombre
                        ? this.state.datosFactura?.proveedor.nombre +
                        " " +
                        this.state.datosFactura?.proveedor.apellidoPaterno +
                        " " +
                        this.state.datosFactura?.proveedor.apellidoMaterno
                        : this.state.datosFactura?.proveedor.razonSocial}
                    </h1>
                    <Row>
                      <Col span={12}>
                        <p style={{ margin: 0 }}>
                          RFC: {this.state.datosFactura?.proveedor.rfc}
                        </p>
                      </Col>
                      <Col span={12}>
                        <p style={{ margin: 0 }}>
                          Correo: {this.state.datosFactura?.proveedor.correo}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <p style={{ margin: 0 }}>
                          Telefono:{" "}
                          {this.state.datosFactura?.proveedor.telefono}
                        </p>
                      </Col>
                      <Col span={12}>
                        <p style={{ margin: 0 }}>
                          Celular: {this.state.datosFactura?.proveedor.celular}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <h1 style={{ margin: 0 }}>
                          Proyecto: {this.state.datosFactura?.proyecto.nombre}
                        </h1>
                        {/* <p>
                          Descripcion:{" "}
                          {this.state.datosFactura?.proyecto.descripcion}
                        </p> */}
                      </Col>
                    </Row>
                  </BoxItem>
                  <HeadersItemSmall>CONTACTO</HeadersItemSmall>
                  <BoxItem>
                    <p style={{ margin: 0 }}>
                      Contacto:{" "}
                      {this.state.datosFactura?.proveedor.nombreContacto}
                    </p>
                    <Row>
                      <Col span={12}>
                        <p style={{ margin: 0 }}>
                          Teléfono:{" "}
                          {this.state.datosFactura?.proveedor.telefonoContacto}
                        </p>
                      </Col>
                      <Col span={12}>
                        <p style={{ margin: 0 }}>
                          Correo:{" "}
                          {this.state.datosFactura?.proveedor.correoContacto}
                        </p>
                      </Col>
                    </Row>
                    <p style={{ margin: 0 }}>
                      Dias de crédito:{" "}
                      {this.state.datosFactura?.proveedor.diasCredito}
                    </p>
                  </BoxItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(PagarFactura);
