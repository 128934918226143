import {
  Input,
  message,
  Popconfirm,
  Tabs,
  Upload,
  Select,
  PageHeader,
  Spin,
  Row,
  Col,
  Form as FormInitial,
  Divider,
} from "antd";
import {
  DeleteOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  ModalItem,
  DatePickerItem,
  InputNumberItem,
  SelectItem,
  dineroDisabledMask,
  HeaderItem,
  BoxItem,
  ButtonItem,
  TextAreaItem,
  InputItem,
  cuentaMask,
  SwitchItem,
} from "../../../../Components/Items";
import AgregarUnidad from "./AgregarUnidad/AgregarUnidad";
import AgregarFamilia from "../../Familias/AgregarFamilia/AgregarFamilia";
import moment from "moment";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";

const { TabPane } = Tabs;
const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AgregarInsumo extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    buscarInfoCode: false,
    archivosPrincipal: [],
    archivosTecnica: [],
    archivosFotos: [],
    todasMonedas: null,
    objetoMonedas: null,
    todasUnidades: null,
    objetoUnidades: null,
    todasImpuestos: null,
    objetoImpuestos: null,
    todasFamilia: null,
    objetoFamilia: null,
    todasProveedores: null,
    objetoProveedores: null,
    todasGrupoSat: null,
    objetoGrupoSat: null,
    todasClaseSat: null,
    objetoClaseSat: null,
    todasCodigoSat: null,
    objetoCodigoSat: null,
    todasRetenciones: null,
    objetoRetenciones: null,
    modalAgregarUnidad: false,
    modalAgregarFamilia: false,
    precios: [],
    calcularPreciosAutomaticamente: false,
    calcularPreciosSobre: false,
    impuestoCombustible: false,
    modalAgregarCuentaContable: false,
    todasCuentasContablesFinales: null,
    objetoCuentasContablesFinales: null,
    todasMaterialesPeligrosos: null,
    objetoMaterialesPeligrosos: null,
    todasEmbalajes: null,
    objetoEmbalajes: null,
    todasSectoresCOF: null,
    objetoSectoresCOF: null,
    todasFormasFarmaceuticas: null,
    objetoFormasFarmaceuticas: null,
    todasCondicionesEspeciales: null,
    objetoCondicionesEspeciales: null,
    todasTiposMaterias: null,
    objetoTiposMaterias: null,
    familiaGastoNoDeducible: false,
    unidadPeso: null
  };
  atras() {
    this.props.history.goBack();
  }
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "insumos/selectDatosParaInsumo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState(
            {
              todasGrupoSat: res.data.subclases,
              todasProveedores: res.data.proveedores,
              todasRetenciones: res.data.retenciones,
              todasImpuestos: res.data.impuestos,
              todasFamilia: res.data.familias,
              todasMonedas: res.data.monedas,
              todasUnidades: res.data.unidades,
              todasMaterialesPeligrosos: res.data.materialesPeligrosos,
              todasEmbalajes: res.data.embalajes,
              unidadPeso: res.data.unidadPeso,
              todasSectoresCOF: res.data.sectoresCOF,
              todasFormasFarmaceuticas: res.data.formasFarmaceuticas,
              todasCondicionesEspeciales: res.data.condicionesEspeciales,
              todasTiposMaterias: res.data.tiposMaterias,
              calcularPreciosAutomaticamente:
                res.data.configuracion.calcularPreciosAutomaticamente,
              calcularPreciosSobre: res.data.configuracion.calcularPreciosSobre,
              precios: res.data.configuracion.precios.map((value) => {
                return {
                  porcentaje: value.porcentaje,
                  nombre: value.nombre,
                  precioId: value._id,
                };
              }),
            },
            () => {
              this.formRef.current.setFieldsValue({
                codigo: res.data.sigNumero,
                clave: res.data.sigNumero,
                precios: res.data.configuracion.precios.map((value) => {
                  return {
                    porcentaje: value.porcentaje,
                    nombre: value.nombre,
                    precioId: value._id,
                  };
                }),
              });
            }
          );
          this.setState({
            objetoUnidades: res.data.unidades.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.unidadSat.c_ClaveUnidad + " - " + value.nombre}
                </Option>
              );
            }),
            objetoMonedas: res.data.monedas.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
            objetoFamilia: res.data.familias.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoImpuestos: res.data.impuestos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoRetenciones: res.data.retenciones.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoProveedores: res.data.proveedores.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
            objetoGrupoSat: res.data.subclases.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.codigo + " - " + value.descripcion}
                </Option>
              );
            }),
            objetoMaterialesPeligrosos: res.data.materialesPeligrosos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
            objetoEmbalajes: res.data.embalajes.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
            objetoSectoresCOF: res.data.sectoresCOF.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
            objetoFormasFarmaceuticas: res.data.formasFarmaceuticas.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
            objetoCondicionesEspeciales: res.data.condicionesEspeciales.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
            objetoTiposMaterias: res.data.tiposMaterias.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentasContablesFinales: res.data });
          this.setState({
            objetoCuentasContablesFinales: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }

  onChangeGrupo(e, conservar) {
    if (!this.formRef.current) return
    if (conservar !== 'true') {
      this.formRef.current.setFieldsValue({
        claseSatId: undefined,
        codigoSatId: undefined,
      });
    }
    axios
      .post(
        "codigoinsumos/selectClases",
        { empresaId: this.props.empresaId, grupoId: e },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasClaseSat: res.data });
          this.setState({
            objetoClaseSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.codigo + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
  }

  onChangeClase(e, conservar) {
    if (!this.formRef.current) return
    if (conservar !== 'true') { this.formRef.current.setFieldsValue({ codigoSatId: undefined }); }
    axios
      .post(
        "codigoinsumos/selectSubclases",
        { empresaId: this.props.empresaId, claseId: e },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCodigoSat: res.data });
          this.setState({
            objetoCodigoSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.c_ClaveProdServ + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      })
  }

  onFinishFailed(e) {
    console.log(e)
    message.error("Porfavor ingrese bien los datos");
  }

  onFinish(values) {
    this.setState({ loading: true });
    let fd = new FormData();
    fd.append("empresaId", this.props.empresaId);
    for (var key in values) {
      if (values[key] === undefined || values[key] === "") {
      } else {
        if (key === "precios") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "equivalencias") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "conversiones") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "impuestos") {
          fd.append(key, JSON.stringify(values[key]));
        } else if (key === "retenciones") {
          fd.append(key, JSON.stringify(values[key]));
        } else {
          fd.append(key, values[key]);
        }
      }
    }
    this.state.archivosPrincipal.forEach((element, index) => {
      fd.append("archivoPrincipal" + (index + 1), element);
    });
    this.state.archivosTecnica.forEach((element, index) => {
      fd.append("archivoTecnica" + (index + 1), element);
    });
    this.state.archivosFotos.forEach((element, index) => {
      fd.append("archivoFoto" + (index + 1), element);
    });
    axios
      .post("insumos/agregarInsumo", fd, {
        headers: {
          ...this.props.headersToken,
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado un insumo");
          this.formRef.current.resetFields();
          this.setState({
            archivosPrincipal: [],
            archivosTecnica: [],
            archivosFotos: [],
          });
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede registrar el insumo");
        } else {
          message.error("Hubo un error y el insumo no se registro");
        }
      });
  }

  onBlurDescripcion() {
    if (!this.formRef.current.getFieldValue("descripcionLarga")) {
      let value = this.formRef.current.getFieldValue("descripcion");
      this.formRef.current.setFieldsValue({
        descripcionLarga: value,
      });
    }
  }

  customRequestFicha(e) {
    this.setState({ archivosTecnica: [...this.state.archivosTecnica, e.file] });
  }

  onRemoveFileFicha(e) {
    let index = this.state.archivosTecnica.indexOf(e);
    let nuevoArray = this.state.archivosTecnica;
    nuevoArray.splice(index, 1);
    this.setState({ archivosTecnica: nuevoArray });
  }

  customRequestPrincipal(e) {
    this.setState({
      archivosPrincipal: [...this.state.archivosPrincipal, e.file],
    });
  }

  onRemoveFilePrincipal(e) {
    let index = this.state.archivosPrincipal.indexOf(e);
    let nuevoArray = this.state.archivosPrincipal;
    nuevoArray.splice(index, 1);
    this.setState({ archivosPrincipal: nuevoArray });
  }

  customRequestFotos(e) {
    this.setState({ archivosFotos: [...this.state.archivosFotos, e.file] });
  }

  onRemoveFileFotos(e) {
    let index = this.state.archivosFotos.indexOf(e);
    let nuevoArray = this.state.archivosFotos;
    nuevoArray.splice(index, 1);
    this.setState({ archivosFotos: nuevoArray });
  }
  abrirAgregarUnidad() {
    this.setState({ modalAgregarUnidad: true });
  }
  cerrarAgregarUnidad(codigo) {
    this.setState({ modalAgregarUnidad: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ unidadId: codigo });
      }
    });
  }
  abrirAgregarFamilia() {
    this.setState({ modalAgregarFamilia: true });
  }
  cerrarAgregarFamilia(codigo) {
    this.setState({ modalAgregarFamilia: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ familiaId: codigo });
      }
    });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo()
  }
  onChangeCosto() {
    const valueCosto = this.formRef.current.getFieldValue("costo");
    if (!valueCosto) return;
    let arrayValues = this.formRef.current.getFieldValue("precios");
    let equivalencias = this.formRef.current.getFieldValue("equivalencias");
    let arrayNuevo = arrayValues.map((value, index) => {
      let precio;
      if (this.state.calcularPreciosSobre) {
        precio = ((valueCosto * (100 + value.porcentaje)) / 100).toFixed(2);
      } else {
        precio = (valueCosto / ((100 - value.porcentaje) / 100)).toFixed(2);
      }
      return { ...value, precio };
    });
    let equivalenciasNuevas;
    if (!equivalencias) {
      equivalenciasNuevas = equivalencias;
    } else {
      equivalenciasNuevas = equivalencias.map((valueE, index) => {
        let arrayPrecios = valueE.precios;
        if (!valueE.equivalencia) return { ...valueE };
        if (!arrayPrecios) return { ...valueE };
        let arrayNuevoPrecios = arrayPrecios.map((valueP, index) => {
          let precio;
          if (this.state.calcularPreciosSobre) {
            precio = (
              ((valueCosto / valueE.equivalencia) * (100 + valueP.porcentaje)) /
              100
            ).toFixed(2);
          } else {
            precio = (
              valueCosto /
              valueE.equivalencia /
              ((100 - valueP.porcentaje) / 100)
            ).toFixed(2);
          }
          return { ...valueP, precio };
        });
        return { ...valueE, precios: arrayNuevoPrecios };
      });
    }
    this.formRef.current.setFieldsValue({
      precios: arrayNuevo,
      equivalencias: equivalenciasNuevas,
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file);
    }
    let preview;
    if (file.base64) {
      preview = file.base64;
    } else {
      preview = file.preview;
    }
    this.setState({
      previewImage: preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  onChangeImpuesto() {
    const impuestos = this.formRef.current.getFieldValue('impuestos')
    let combustible = false
    let impuestoIdCombustible
    impuestos.map((valueImpuesto) => {
      if (valueImpuesto?.impuestoId) {
        const impuesto = this.state.todasImpuestos.find((valueTodasImpuesto) => valueTodasImpuesto._id.toString() === valueImpuesto.impuestoId.toString())
        if (impuesto.combustible) {
          combustible = true
          impuestoIdCombustible = impuesto._id
        }
      }
      return null
    })
    if (combustible) {
      message.info('Si el impuesto es especial solamente se puede elegir uno y no se puede vender')
      this.setState({ impuestoCombustible: combustible })
      this.formRef.current.setFieldsValue({ impuestos: [{ impuestoId: impuestoIdCombustible }], venta: false })
    } else {
      this.setState({ impuestoCombustible: combustible })
    }
  }

  onChangeVenta(e) {
    if (e) {
      if(!this.state.objetoCodigoSat){
        this.setState({ buscarInfoCode: true })
        axios
          .post(
            "codigoinsumos/buscarCodigoLista",
            { empresaId: this.props.empresaId },
            { headers: this.props.headersToken, }
          )
          .then((res) => {
            if (res.data.status === 404) {
            } else {
              this.setState({
                todasCodigoSat: res.data,
                buscarInfoCode: false,
                objetoCodigoSat: res.data.map((value) => {
                  return (
                    <Option value={value._id} key={value._id}>
                      {value.c_ClaveProdServ + " - " + value.descripcion}
                    </Option>
    
                  );
                }),
              });
            }
          })
      }
    }
  }

  onChangeFamlia(familiaId) {
    const familia = this.state.todasFamilia.find((valueTodasFamilia) => valueTodasFamilia._id.toString() === familiaId.toString())
    if (familia.gastoNoDeducible) {
      message.info('Si la familia es gasto no deducible no se puede inventariar ni vender')
      this.setState({ familiaGastoNoDeducible: true })
      this.formRef.current.setFieldsValue({ inventariable: false, venta: false })
    } else {
      this.setState({ familiaGastoNoDeducible: false })
    }
  }

  onChangeCodigo(value) {          /*Fragmento que regresa la lista de busqueda del codigo*/
    this.setState({ buscarInfoCode: true })
    axios
      .post(
        "codigoinsumos/buscarCodigoLista",
        { empresaId: this.props.empresaId, codigo: value },
        { headers: this.props.headersToken, }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasCodigoSat: res.data,
            buscarInfoCode: false,
            objetoCodigoSat: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.c_ClaveProdServ + " - " + value.descripcion}
                </Option>

              );
            }),
          });
        }
      })
  }

  render() {
    const { previewVisible, previewImage, previewTitle } = this.state;
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Insumo"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarInsumo"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar
                </ButtonItem>
              }
              initialValues={{
                fechaCalculo: moment(),
                monedaCompraId: "60c7e4396465ad440c41c15a",
              }}
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Tabs defaultActiveKey={1}>
                <TabPane tab="General" tabKey={1} key={1} forceRender>
                  <Row>
                    <Col span={14}>
                      <HeaderItem>DATOS GENERALES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label="Permitir Venta"
                              name="venta"
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 10 }}
                              initialValue={false}
                              valuePropName="checked"
                            >
                              <SwitchItem disabled={this.state.impuestoCombustible || this.state.familiaGastoNoDeducible} onChange={(e) => this.onChangeVenta(e)} />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="Permitir Compra"
                              name="compra"
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 10 }}
                              initialValue={true}
                              valuePropName="checked"
                            >
                              <SwitchItem />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={9}>
                            <FormItem
                              name="codigo"
                              label="Código"
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              pattern
                            >
                              <InputItem disabled placeholder="Código" />
                            </FormItem>
                          </Col>
                          <Col span={7}>
                            <FormItem
                              name="clave"
                              label="Clave"
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 14 }}
                              pattern
                            >
                              <InputItem placeholder="Clave" />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              name="codigoProveedor"
                              label="Código Proveedor"
                              margin
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 17 }}
                              pattern
                            >
                              <InputItem placeholder="Código Proveedor" />
                            </FormItem>
                          </Col>
                        </Row>
                        <FormItem
                          label="Cod. barras"
                          name="codigoBarras"
                          margin
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 21 }}
                          pattern
                        >
                          <InputItem
                            placeholder="Codigo de barras"
                          />
                        </FormItem>
                        <FormItem
                          label="Descripción"
                          name="descripcion"
                          required
                          margin
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 21 }}
                          pattern
                        >
                          <InputItem
                            placeholder="Descripción"
                            onBlur={this.onBlurDescripcion.bind(this)}
                          />
                        </FormItem>
                        <FormItem
                          label="Desc. Larga"
                          name="descripcionLarga"
                          required
                          margin
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 21 }}
                          pattern
                        >
                          <TextAreaItem placeholder="Descripción larga" />
                        </FormItem>
                        <Row>
                          <Col span={8}>
                            <FormItem
                              label="Costo"
                              name="costo"
                              required
                              margin
                              labelCol={{ span: 9 }}
                              wrapperCol={{ span: 15 }}
                              numberCero
                            >
                              <InputNumberItem
                                placeholder="Costo"
                                dinero
                                onChange={this.onChangeCosto.bind(this)}
                              />
                            </FormItem>
                          </Col>
                          <Col span={11}>
                            <FormItem
                              label="Unidad"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <Input.Group compact>
                                <FormItem name="unidadId" required noStyle>
                                  <SelectItem placeholder="Unidad" width="70%">
                                    {this.state.objetoUnidades}
                                  </SelectItem>
                                </FormItem>
                                <ButtonItem
                                  icon={<PlusOutlined />}
                                  style={{ width: "15%" }}
                                  onClick={this.abrirAgregarUnidad.bind(this)}
                                />
                              </Input.Group>
                            </FormItem>
                          </Col>
                          <Col span={4}>
                            <FormItem
                              name="fechaCalculo"
                              required
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <DatePickerItem
                                placeholder="Fecha de Calculo"
                                allowClear={false}
                                disabled
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                    <Col span={10}>
                      <FormItem noStyle shouldUpdate={(prev, curr) => prev.venta !== curr.venta} >
                        {({ getFieldValue }) => {
                          let venta = getFieldValue('venta')
                          if (venta) {
                            return (
                              <>
                                <HeaderItem>LISTAS DE PRECIOS</HeaderItem>
                                <BoxItem>
                                  <FormInitial.List name="precios">
                                    {(fields, { add, remove }) => (
                                      <>
                                        {fields.map(
                                          (
                                            { key, name, fieldKey, ...restField },
                                            otro
                                          ) => (
                                            <FormItem
                                              key={key}
                                              label={
                                                "Precio " + this.state.precios[key].nombre
                                              }
                                              margin
                                              shouldUpdate
                                            >
                                              <Input.Group compact>
                                                <FormItem
                                                  name={[name, "porcentaje"]}
                                                  required
                                                  numberCero
                                                  noStyle
                                                >
                                                  <InputNumberItem
                                                    porcentaje
                                                    sinWidth
                                                    onChange={(value) => {
                                                      let arrayValue =
                                                        this.formRef.current.getFieldValue(
                                                          "precios"
                                                        );
                                                      let valueCosto =
                                                        this.formRef.current.getFieldValue(
                                                          "costo"
                                                        );
                                                      if (!valueCosto) return;
                                                      if (
                                                        this.state.calcularPreciosSobre
                                                      ) {
                                                        arrayValue[key].precio = (
                                                          (valueCosto * (100 + value)) /
                                                          100
                                                        ).toFixed(2);
                                                      } else {
                                                        arrayValue[key].precio = (
                                                          valueCosto /
                                                          ((100 - value) / 100)
                                                        ).toFixed(2);
                                                      }
                                                      this.formRef.current.setFieldsValue(
                                                        {
                                                          precios: arrayValue,
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </FormItem>
                                                <FormItem
                                                  name={[name, "precio"]}
                                                  required
                                                  numberCero
                                                  noStyle
                                                >
                                                  <InputNumberItem
                                                    sinWidth
                                                    placeholder={
                                                      "Precio de Venta " +
                                                      this.state.precios[key].nombre
                                                    }
                                                    dinero
                                                    onChange={(value) => {
                                                      let arrayValue =
                                                        this.formRef.current.getFieldValue(
                                                          "precios"
                                                        );
                                                      let valueCosto =
                                                        this.formRef.current.getFieldValue(
                                                          "costo"
                                                        );
                                                      if (!valueCosto) return;
                                                      if (
                                                        this.state.calcularPreciosSobre
                                                      ) {
                                                        arrayValue[key].porcentaje = (
                                                          ((value - valueCosto) /
                                                            valueCosto) *
                                                          100
                                                        ).toFixed(2);
                                                      } else {
                                                        arrayValue[key].porcentaje = (
                                                          ((value - valueCosto) / value) *
                                                          100
                                                        ).toFixed(2);
                                                      }
                                                      this.formRef.current.setFieldsValue(
                                                        {
                                                          precios: arrayValue,
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </FormItem>
                                              </Input.Group>
                                            </FormItem>
                                          )
                                        )}
                                      </>
                                    )}
                                  </FormInitial.List>
                                </BoxItem>
                              </>
                            )
                          } else {
                            return null
                          }
                        }}
                      </FormItem>
                      <HeaderItem>ARCHIVO GRAFICO</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={9}>
                            <FormItem
                              name="fotoPrincipal"
                              margin
                            >
                              <Upload
                                showUploadList={{
                                  showRemoveIcon: true,
                                  showPreviewIcon: true,
                                  removeIcon: (file) => (
                                    <Popconfirm
                                      title="Seguro que quieres eliminar el archivo"
                                      onConfirm={() => {
                                        this.onRemoveFilePrincipal(file);
                                      }}
                                      okText="Si"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        onClick={(e) => e.preventDefault()}
                                      />
                                    </Popconfirm>
                                  ),
                                }}
                                fileList={this.state.archivosPrincipal}
                                customRequest={this.customRequestPrincipal.bind(
                                  this
                                )}
                                onPreview={this.handlePreview}
                              >
                                <ButtonItem icon={<UploadOutlined />} style={{ padding: '0px 20px' }}>
                                  Foto Principal
                                </ButtonItem>
                              </Upload>
                            </FormItem>
                          </Col>
                          <Col span={9}>
                            <FormItem
                              name="fichaTecnica"
                              margin
                            >
                              <Upload
                                showUploadList={{
                                  showRemoveIcon: true,
                                  showPreviewIcon: true,
                                  removeIcon: (file) => (
                                    <Popconfirm
                                      title="Seguro que quieres eliminar el archivo"
                                      onConfirm={() => {
                                        this.onRemoveFileFicha(file);
                                      }}
                                      okText="Si"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        onClick={(e) => e.preventDefault()}
                                      />
                                    </Popconfirm>
                                  ),
                                }}
                                fileList={this.state.archivosTecnica}
                                customRequest={this.customRequestFicha.bind(this)}
                                multiple={true}
                                onPreview={this.handlePreview}
                              >
                                <ButtonItem icon={<UploadOutlined />} style={{ padding: '10px 20px' }}>
                                  Ficha Técnica
                                </ButtonItem>
                              </Upload>
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem name="fotos" margin>
                              <Upload
                                showUploadList={{
                                  showRemoveIcon: true,
                                  showPreviewIcon: true,
                                  removeIcon: (file) => (
                                    <Popconfirm
                                      title="Seguro que quieres eliminar el archivo"
                                      onConfirm={() => {
                                        this.onRemoveFileFotos(file);
                                      }}
                                      okText="Si"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        onClick={(e) => e.preventDefault()}
                                      />
                                    </Popconfirm>
                                  ),
                                }}
                                fileList={this.state.archivosFotos}
                                customRequest={this.customRequestFotos.bind(this)}
                                multiple={true}
                                onPreview={this.handlePreview}
                              >
                                <ButtonItem icon={<UploadOutlined />} style={{ padding: '0px 45px' }}>
                                  Fotos
                                </ButtonItem>
                              </Upload>
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <HeaderItem>INVENTARIO</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={6}>
                            <FormItem
                              name="inventariable"
                              label="Inventariable"
                              margin
                              valuePropName="checked"
                              initialValue={true}
                              specialLayoutSwitch
                            >
                              <SwitchItem onChange={(value) => {
                                if (!value) {
                                  this.formRef.current.setFieldsValue({ marbetes: false })
                                }
                              }} disabled={this.state.familiaGastoNoDeducible}></SwitchItem>
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            {this.props.marbetes ?
                              <FormItem shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const inventariable = getFieldValue('inventariable')
                                  return (
                                    <FormItem
                                      name="marbetes"
                                      label="Marbetes"
                                      margin
                                      valuePropName="checked"
                                      specialLayoutSwitch
                                    >
                                      <SwitchItem disabled={!inventariable || this.state.familiaGastoNoDeducible}></SwitchItem>
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                              : null}
                          </Col>
                          <Col span={12}>
                            <FormItem
                              name="repetirRelaciones"
                              label="Repetir Relaciones"
                              margin
                              valuePropName="checked"
                              specialLayoutSwitch
                              tooltip={{ title: "Utilizar para la repetición de relaciones del proveedor. Activar solamente cuando sea necesario y en insumos que se necesite. Esto afecta al momento de pedir al proveedor." }}
                            >
                              <SwitchItem></SwitchItem>
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={14}>
                            <FormItem
                              label="Proveedor"
                              name="proveedorDefaultId"
                              margin
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <SelectItem placeholder="Proveedor default">
                                {this.state.objetoProveedores}
                              </SelectItem>
                            </FormItem>
                          </Col>
                          <Col span={10}>
                            <FormItem
                              label="Compra Mínima"
                              name="catidadMinCompra"
                              margin
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 18 }}
                              numberCero
                            >
                              <InputNumberItem placeholder="Compra Mínima" />
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <HeaderItem>IMPUESTOS Y RETENCIONES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={12}>
                            <h1 style={{ textAlign: 'center' }}>Impuestos</h1>
                            <FormInitial.List
                              name="impuestos"
                            >
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ name }, arrayKey) => (
                                    <Row>
                                      <Col span={22}>
                                        <FormItem
                                          name={[name, "impuestoId"]}
                                          fieldKey={[arrayKey, "impuestoId"]}
                                          required
                                          margin
                                          noStyle
                                        >
                                          <SelectItem width='100%' placeholder="Impuesto" onChange={this.onChangeImpuesto.bind(this)}>
                                            {this.state.objetoImpuestos}
                                          </SelectItem>
                                        </FormItem>
                                      </Col>
                                      <Col span={2}>
                                        <div style={{ textAlign: 'center' }}>
                                          <MinusCircleOutlined
                                            onClick={() => {
                                              remove(name)
                                              this.onChangeImpuesto()
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                  {!this.state.impuestoCombustible ?
                                    <div style={{ textAlign: 'center' }}>
                                      <ButtonItem
                                        type="primary"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                      >
                                        Agregar
                                      </ButtonItem>
                                    </div>
                                    : null}
                                </>
                              )}
                            </FormInitial.List>
                          </Col>
                          <Col span={12}>
                            <h1 style={{ textAlign: 'center' }}>Retenciones</h1>
                            <FormInitial.List
                              name="retenciones"
                            >
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ name }, arrayKey) => (
                                    <Row>
                                      <Col span={22}>
                                        <FormItem
                                          name={[name, "retencionId"]}
                                          fieldKey={[arrayKey, "retencionId"]}
                                          required
                                          margin
                                          noStyle
                                        >
                                          <SelectItem width='100%' placeholder="Retención">
                                            {this.state.objetoRetenciones}
                                          </SelectItem>
                                        </FormItem>
                                      </Col>
                                      <Col span={2}>
                                        <div style={{ textAlign: 'center' }}>
                                          <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                  <div style={{ textAlign: 'center' }}>
                                    <ButtonItem
                                      type="primary"
                                      onClick={() => add()}
                                      icon={<PlusOutlined />}
                                    >
                                      Agregar
                                    </ButtonItem>
                                  </div>
                                </>
                              )}
                            </FormInitial.List>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <HeaderItem>DATOS FISCALES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={24}>
                            <FormItem
                              label="Familia"
                              margin
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 21 }}
                            >
                              <Input.Group compact>
                                <FormItem name="familiaId" required noStyle>
                                  <SelectItem
                                    placeholder="Familia"
                                    width="90%"
                                    onChange={this.onChangeFamlia.bind(this)}
                                  >
                                    {this.state.objetoFamilia}
                                  </SelectItem>
                                </FormItem>
                                <ButtonItem
                                  icon={<PlusOutlined />}
                                  style={{ width: "10%" }}
                                  onClick={this.abrirAgregarFamilia.bind(this)}
                                />
                              </Input.Group>
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                    <Col span={12}>
                      {/* <FormItem
                        label="Es subinsumo"
                        name="subinsumo"
                        margin
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox></Checkbox>
                      </FormItem> */}
                    </Col>
                  </Row>
                  <FormItem noStyle shouldUpdate={(prev, curr) => prev.venta !== curr.venta || prev.familiaId !== curr.familiaId} >
                    {({ getFieldValue }) => {
                      let venta = getFieldValue('venta')
                      let familiaId = getFieldValue('familiaId')
                      let familia
                      if(familiaId){
                        familia = this.state?.todasFamilia?.find(valueTF => valueTF._id.toString() === familiaId.toString())
                      }
                      if (venta && (!familia?.cuentaIngresoContadoId || !familia?.cuentaIngresoCreditoId)) {
                        return (
                        <Row>
                          <Col span={14}>
                            <HeaderItem>CUENTAS DE INGRESO</HeaderItem>
                            <BoxItem>
                              <Row>
                                <Col span={24}>
                                  <FormItem label="Cuenta Contado" margin>
                                    <Input.Group compact>
                                      <FormItem name="cuentaIngresoContadoId" required noStyle>
                                        <SelectItem placeholder="Cuenta Ingreso Contado" width="80%">
                                          {this.state.objetoCuentasContablesFinales}
                                        </SelectItem>
                                      </FormItem>
                                      <ButtonItem
                                        icon={<PlusOutlined />}
                                        style={{ width: "20%" }}
                                        blanco
                                        onClick={this.abrirAgregarCuentaContable.bind(this)}
                                      />
                                    </Input.Group>
                                  </FormItem>
                                  <FormItem label="Cuenta Credito" margin>
                                    <Input.Group compact>
                                      <FormItem name="cuentaIngresoCreditoId" required noStyle>
                                        <SelectItem placeholder="Cuenta Ingreso Credito" width="80%">
                                          {this.state.objetoCuentasContablesFinales}
                                        </SelectItem>
                                      </FormItem>
                                      <ButtonItem
                                        icon={<PlusOutlined />}
                                        style={{ width: "20%" }}
                                        blanco
                                        onClick={this.abrirAgregarCuentaContable.bind(this)}
                                      />
                                    </Input.Group>
                                  </FormItem>
                                </Col>
                              </Row>
                            </BoxItem>
                          </Col>
                        </Row>)
                      } else {
                        return null
                      }
                    }}
                  </FormItem>
                  <FormItem noStyle shouldUpdate={(prev, curr) => prev.venta !== curr.venta} >
                    {({ getFieldValue }) => {
                      let venta = getFieldValue('venta')
                      if (venta) {
                        return (
                          <Row>
                            <Col span={14}>
                              <HeaderItem>CODIGOS SAT</HeaderItem>
                              <BoxItem>
                                <Row>
                                  <Col span={24}>
                                    <FormItem
                                      label="Codigo Sat"
                                      name="codigoSatId"
                                      required
                                      margin
                                    >
                                      <SelectItem
                                        mostrarCarga
                                        loading={this.state.buscarInfoCode}
                                        placeholder="Codigo Sat" onSearch={(value) => { this.onChangeCodigo(value) }
                                        }>
                                          <Option disabled>Escriba para buscar el codigo</Option>
                                        {this.state.objetoCodigoSat}
                                      </SelectItem>
                                    </FormItem>
                                  </Col>
                                </Row>
                                <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
                                  <ButtonItem
                                    style={{ width: "50%", marginTop: "10px" }}
                                    onClick={() => window.open("http://pys.sat.gob.mx/PyS/catPyS.aspx", "_blank")} // Abre en una nueva pestaña
                                  >Conoce tu codigo sat</ButtonItem>
                                </Row>
                              </BoxItem>
                            </Col>
                          </Row>
                        )
                      } else {
                        return null
                      }
                    }}
                  </FormItem>
                </TabPane>
                { /*<TabPane tab="Codigo Sat" tabKey={2} key={2} forceRender>
                  <FormItem label="Grupo Sat" name="grupoSatId" required margin>
                    <SelectItem
                      placeholder="Grupo Sat"
                      onChange={this.onChangeGrupo.bind(this)}
                    >
                      {this.state.objetoGrupoSat}
                    </SelectItem>
                  </FormItem>
                  <FormItem label="Clase Sat" name="claseSatId" required margin>
                    <SelectItem
                      placeholder="Clase Sat"
                      onChange={this.onChangeClase.bind(this)}
                    >
                      {this.state.objetoClaseSat === null ? (
                        <Option disabled>Eliga primero un grupo</Option>
                      ) : (
                        this.state.objetoClaseSat
                      )}
                    </SelectItem>
                  </FormItem>
                  <FormItem
                    label="Codigo Sat"
                    name="codigoSatId"
                    required
                    margin
                  >
                    <SelectItem placeholder="Codigo Sat">
                      {this.state.objetoCodigoSat === null ? (
                        <Option disabled>Eliga primero una clase</Option>
                      ) : (
                        this.state.objetoCodigoSat
                      )}
                    </SelectItem>
                  </FormItem>
                </TabPane>*/}
                <TabPane tab="Equivalencias" tabKey={3} key={3} forceRender>
                  <FormInitial.List name="equivalencias">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <>
                              <Row>
                                <FormItem shouldUpdate noStyle>
                                  {({ getFieldValue }) => {
                                    const descripcionProducto =
                                      getFieldValue("descripcion");
                                    const costo = getFieldValue("costo");
                                    const equivalencias =
                                      getFieldValue("equivalencias");
                                    if (!equivalencias[arrayKey]) return;
                                    return (
                                      <div style={{ width: "100%" }}>
                                        <h1 style={{ textAlign: "center" }}>
                                          1: "{descripcionProducto}" equivale a{" "}
                                          {equivalencias[arrayKey].equivalencia}
                                          : "
                                          {equivalencias[arrayKey].descripcion}"
                                        </h1>
                                        <h1 style={{ textAlign: "center" }}>
                                          Costo:{" "}
                                          {dineroDisabledMask(
                                            costo /
                                            equivalencias[arrayKey]
                                              .equivalencia || 0
                                          )}
                                        </h1>
                                      </div>
                                    );
                                  }}
                                </FormItem>
                                <Col span={12}>
                                  <FormItem
                                    label="Permitir Venta"
                                    name={[name, "venta"]}
                                    fieldKey={[arrayKey, "venta"]}
                                    margin
                                    initialValue={true}
                                    valuePropName="checked"
                                  >
                                    <SwitchItem />
                                  </FormItem>
                                  <FormItem
                                    {...restField}
                                    label="Descripción"
                                    name={[name, "descripcion"]}
                                    fieldKey={[arrayKey, "descripcion"]}
                                    required
                                    max={200}
                                    margin
                                    pattern
                                  >
                                    <InputItem placeholder="Descripción" />
                                  </FormItem>
                                  <FormItem
                                    {...restField}
                                    label="Equivalencia"
                                    name={[name, "equivalencia"]}
                                    fieldKey={[arrayKey, "equivalencia"]}
                                    required
                                    margin
                                    number
                                  >
                                    <InputNumberItem
                                      placeholder="Equivalencia"
                                      onChange={this.onChangeCosto.bind(this)}
                                    />
                                  </FormItem>
                                  <FormItem label="Unidad" margin>
                                    <Input.Group compact>
                                      <FormItem
                                        {...restField}
                                        name={[name, "unidadId"]}
                                        fieldKey={[arrayKey, "unidadId"]}
                                        required
                                        margin
                                        pattern
                                        noStyle
                                      >
                                        <SelectItem
                                          placeholder="Unidad"
                                          width="90%"
                                        >
                                          {this.state.objetoUnidades}
                                        </SelectItem>
                                      </FormItem>
                                      <ButtonItem
                                        icon={<PlusOutlined />}
                                        style={{ width: "10%" }}
                                        onClick={this.abrirAgregarUnidad.bind(
                                          this
                                        )}
                                      />
                                    </Input.Group>
                                  </FormItem>
                                </Col>
                                <Col span={12}>
                                  <FormInitial.List
                                    name={[name, "precios"]}
                                    fieldKey={[arrayKey, "precios"]}
                                    initialValue={this.state.precios.map(
                                      (value) => {
                                        return {
                                          porcentaje: value.porcentaje,
                                          nombre: value.nombre,
                                          precioId: value.precioId,
                                        };
                                      }
                                    )}
                                  >
                                    {(fields, { add, remove }) => (
                                      <>
                                        {fields.map(
                                          (
                                            {
                                              key,
                                              name,
                                              fieldKey,
                                              ...restField
                                            },
                                            otro
                                          ) => (
                                            <FormItem
                                              key={key}
                                              label={
                                                "Precio " +
                                                this.state.precios[key].nombre
                                              }
                                              margin
                                              shouldUpdate
                                            >
                                              <Input.Group compact>
                                                <FormItem
                                                  name={[name, "porcentaje"]}
                                                  required
                                                  numberCero
                                                  noStyle
                                                >
                                                  <InputNumberItem
                                                    sinWidth
                                                    porcentaje
                                                    onChange={(value) => {
                                                      let equivalencias =
                                                        this.formRef.current.getFieldValue(
                                                          "equivalencias"
                                                        );
                                                      let valueCosto =
                                                        this.formRef.current.getFieldValue(
                                                          "costo"
                                                        );
                                                      if (!valueCosto) return;
                                                      if (
                                                        !equivalencias[arrayKey]
                                                      )
                                                        return;
                                                      if (
                                                        !equivalencias[arrayKey]
                                                          .equivalencia
                                                      )
                                                        return;
                                                      if (
                                                        this.state
                                                          .calcularPreciosSobre
                                                      ) {
                                                        equivalencias[
                                                          arrayKey
                                                        ].precios[key].precio =
                                                          (
                                                            ((valueCosto /
                                                              equivalencias[
                                                                arrayKey
                                                              ].equivalencia) *
                                                              (100 + value)) /
                                                            100
                                                          ).toFixed(2);
                                                      } else {
                                                        equivalencias[
                                                          arrayKey
                                                        ].precios[key].precio =
                                                          (
                                                            valueCosto /
                                                            equivalencias[
                                                              arrayKey
                                                            ].equivalencia /
                                                            ((100 - value) /
                                                              100)
                                                          ).toFixed(2);
                                                      }
                                                      this.formRef.current.setFieldsValue(
                                                        {
                                                          equivalencias,
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </FormItem>
                                                <FormItem
                                                  name={[name, "precio"]}
                                                  required
                                                  numberCero
                                                  noStyle
                                                >
                                                  <InputNumberItem
                                                    sinWidth
                                                    placeholder={
                                                      "Precio de Venta " +
                                                      this.state.precios[key]
                                                        .nombre
                                                    }
                                                    dinero
                                                    onChange={(value) => {
                                                      let equivalencias =
                                                        this.formRef.current.getFieldValue(
                                                          "equivalencias"
                                                        );
                                                      let valueCosto =
                                                        this.formRef.current.getFieldValue(
                                                          "costo"
                                                        );
                                                      if (!valueCosto) return;
                                                      if (
                                                        !equivalencias[arrayKey]
                                                      )
                                                        return;
                                                      if (
                                                        !equivalencias[arrayKey]
                                                          .equivalencia
                                                      )
                                                        return;
                                                      if (
                                                        this.state
                                                          .calcularPreciosSobre
                                                      ) {
                                                        equivalencias[
                                                          arrayKey
                                                        ].precios[
                                                          key
                                                        ].porcentaje = (
                                                          ((value -
                                                            valueCosto /
                                                            equivalencias[
                                                              arrayKey
                                                            ].equivalencia) /
                                                            (valueCosto /
                                                              equivalencias[
                                                                arrayKey
                                                              ].equivalencia)) *
                                                          100
                                                        ).toFixed(2);
                                                      } else {
                                                        equivalencias[
                                                          arrayKey
                                                        ].precios[
                                                          key
                                                        ].porcentaje = (
                                                          ((value -
                                                            valueCosto /
                                                            equivalencias[
                                                              arrayKey
                                                            ].equivalencia) /
                                                            value) *
                                                          100
                                                        ).toFixed(2);
                                                      }
                                                      this.formRef.current.setFieldsValue(
                                                        {
                                                          equivalencias,
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </FormItem>
                                              </Input.Group>
                                            </FormItem>
                                          )
                                        )}
                                      </>
                                    )}
                                  </FormInitial.List>
                                  <FormItem>
                                    <ButtonItem
                                      style={{
                                        float: "right",
                                        width: "auto",
                                      }}
                                      type="dashed"
                                      onClick={() => remove(name)}
                                      block
                                      icon={<MinusCircleOutlined />}
                                    >
                                      Eliminar Equivalencia
                                    </ButtonItem>
                                  </FormItem>
                                </Col>
                              </Row>
                              <Divider
                                style={{ marginBottom: 10, marginTop: 5 }}
                              />
                            </>
                          )
                        )}
                        <FormItem noStyle>
                          <ButtonItem
                            style={{ marginTop: 10, marginBottom: 10 }}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Equivalencia
                          </ButtonItem>
                        </FormItem>
                      </>
                    )}
                  </FormInitial.List>
                </TabPane>
                {this.props.envios ?
                  <TabPane tab="Envio" tabKey={4} key={4} forceRender>
                    <HeaderItem>DATOS MERCANCIA</HeaderItem>
                    <BoxItem>
                      <Row>
                        <Col span={12}>
                          <FormItem
                            name="dimensiones"
                            label="Dimensiones"
                            margin
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            pattern
                          >
                            <InputItem placeholder="Ejemplo:  59/40/36plg" />
                          </FormItem>
                          <FormItem
                            label="Descripcion Materia"
                            name="descripcionMateria"
                            margin
                            pattern
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            tooltip={{ title: "Atributo opcional para expresar la clave de producto de la STCC (por sus siglas en inglés, Standard Transportation Commodity Code), cuando el medio de transporte utilizado para el traslado de los bienes y/o mercancías sea ferroviario." }}
                          >
                            <InputItem placeholder="Descripcion Materia" />
                          </FormItem>
                          <FormItem
                            label="Material Peligroso"
                            name="materialPeligroso"
                            margin
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <SwitchItem />
                          </FormItem>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              if (getFieldValue('materialPeligroso')) {
                                return (
                                  <>
                                    <FormItem label="Material Peligroso" name="materialPeligrosoId" margin required>
                                      <SelectItem placeholder="Material Peligroso" width="114%">
                                        {this.state.objetoMaterialesPeligrosos}
                                      </SelectItem>
                                    </FormItem>
                                    <FormItem
                                      name="numCAS"
                                      label="Numero CAS"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el número Chemical Abstracts Service (CAS) con el que se identifica el compuesto químico de la sustancia tóxica." }}
                                    >
                                      <InputItem placeholder="Numero CAS" />
                                    </FormItem>
                                  </>
                                )
                              }
                            }}
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            label="Peso en KGM"
                            name="pesoEnKGM"
                            margin
                            numberCero
                          >
                            <InputNumberItem placeholder="Peso en KGM" />
                          </FormItem>
                          <FormItem
                            label="Clave STCC"
                            name="claveSTCC"
                            margin
                            numberCero
                            tooltip={{ title: "Atributo opcional para expresar la clave de producto de la STCC (por sus siglas en inglés, Standard Transportation Commodity Code), cuando el medio de transporte utilizado para el traslado de los bienes y/o mercancías sea ferroviario." }}
                          >
                            <InputNumberItem placeholder="Clave STCC" />
                          </FormItem>
                          <FormItem label="Tipo Materia" name="tipoMateriaId" margin tooltip={{ title: "Atributo condicional para expresar el estado de la materia o producto al realizar una operación de comercio exterior a través de los distintos medios de transporte." }}>
                            <SelectItem placeholder="Tipo Materia" width="100%">
                              {this.state.objetoTiposMaterias}
                            </SelectItem>
                          </FormItem>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              if (getFieldValue('materialPeligroso')) {
                                return (
                                  <>
                                    <FormItem label="Embalaje" name="embalajeId" margin required>
                                      <SelectItem placeholder="Embalaje" width="100%">
                                        {this.state.objetoEmbalajes}
                                      </SelectItem>
                                    </FormItem>
                                    <FormItem
                                      name="descripcionEmbalaje"
                                      label="Descripcion Embalaje"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      required
                                    >
                                      <InputItem placeholder="Ejemplo: Cajas de madera contrachapada" />
                                    </FormItem>
                                  </>
                                )
                              }
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                    </BoxItem>
                    <HeaderItem>SECTOR COFEPRIS</HeaderItem>
                    <BoxItem>
                      <Row>
                        <Col span={12}>
                          <FormItem
                            label="Sector COFEPRIS"
                            name="sectorCOFEPRIS"
                            margin
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <SwitchItem />
                          </FormItem>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              if (getFieldValue('sectorCOFEPRIS')) {
                                return (
                                  <>
                                    <FormItem label="Sector COFEPRIS" name="sectorCOFEPRISId" margin required>
                                      <SelectItem placeholder="Sector COFEPRIS" width="100%">
                                        {this.state.objetoSectoresCOF}
                                      </SelectItem>
                                    </FormItem>
                                    <FormItem
                                      name="nombreIngredienteActivo"
                                      label="Ingrediente Activo"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el nombre común del ingrediente activo de los precursores, químicos de uso dual, plaguicidas o fertilizantes que se trasladan a través de los distintos medios de transporte" }}
                                    >
                                      <InputItem placeholder="Ingrediente Activo" />
                                    </FormItem>
                                    <FormItem
                                      name="nombreQuimico"
                                      label="Nombre Quimico"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el nombre de la sustancia activa de los precursores, químicos de uso dual o sustancias tóxicas que se traslada a través de los distintos medios de transporte." }}
                                    >
                                      <InputItem placeholder="Nombre Quimico" />
                                    </FormItem>
                                    <FormItem
                                      name="denominacionGenericaProd"
                                      label="Denominacion Generica"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el fármaco o la sustancia activa del medicamento, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                    >
                                      <InputItem placeholder="Denominacion Generica" />
                                    </FormItem>
                                    <FormItem
                                      name="denominacionDistintivaProd"
                                      label="Denominacion Distintiva"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar la marca con la que se comercializa el producto o nombre que le asigna el laboratorio o fabricante a sus especialidades farmacéuticas con el fin de distinguirlas de otras similares del medicamento, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                    >
                                      <InputItem placeholder="Denominacion Distintiva" />
                                    </FormItem>
                                  </>
                                )
                              }
                            }}
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              if (getFieldValue('sectorCOFEPRIS')) {
                                return (
                                  <>
                                    <FormItem
                                      name="fabricante"
                                      label="Fabricante"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el nombre o razón social del establecimiento que realiza la fabricación o manufactura del medicamento, precursor, químico de uso dual, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                    >
                                      <InputItem placeholder="Fabricante" />
                                    </FormItem>
                                    <FormItem label="Forma Farmaceutica" name="formaFarmaceuticaId" margin>
                                      <SelectItem placeholder="Forma Farmaceutica" width="100%">
                                        {this.state.objetoFormasFarmaceuticas}
                                      </SelectItem>
                                    </FormItem>
                                    <FormItem label="Condiciones Especiales" tooltip={{ title: "Atributo condicional para expresar la condición en la cual es necesario mantener el medicamento, precursor, químico de uso dual, psicotrópicos o estupefacientes durante el traslado y almacenamiento." }} name="condicionEspecialId" margin>
                                      <SelectItem placeholder="Condiciones Especiales" width="100%">
                                        {this.state.objetoCondicionesEspeciales}
                                      </SelectItem>
                                    </FormItem>
                                    <FormItem
                                      name="registroSanitarioFolioAutorizacion"
                                      label="Registro Sanitario"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el registro sanitario o folio de autorización con el que cuenta la empresa para el traslado del medicamento, psicotrópico o estupefaciente." }}
                                    >
                                      <InputItem placeholder="Registro Sanitario" />
                                    </FormItem>
                                    <FormItem
                                      name="numRegSanPlagCOFEPRIS"
                                      label="Numero Registro"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para expresar el número de registro sanitario para plaguicidas o fertilizantes cuya importación, comercialización y uso están permitidos en México, mismo que emite la Comisión Intersecretarial para el Control del Proceso y Uso de Plaguicidas, Fertilizantes y Sustancias Tóxicas (CICLOPLAFEST)." }}
                                    >
                                      <InputItem placeholder="Numero Registro" />
                                    </FormItem>
                                    <FormItem
                                      name="datosFabricante"
                                      label="Datos Fabricante"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien produce o fabrica el ingrediente activo del plaguicida o fertilizante." }}
                                    >
                                      <InputItem placeholder="Datos Fabricante" />
                                    </FormItem>
                                    <FormItem
                                      name="datosFormulador"
                                      label="Datos Formulador"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien formula el ingrediente activo del plaguicida o fertilizante." }}
                                    >
                                      <InputItem placeholder="Datos Formulador" />
                                    </FormItem>
                                    <FormItem
                                      name="datosMaquilador"
                                      label="Datos Maquilador"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien maquila el ingrediente activo del plaguicida o fertilizante." }}
                                    >
                                      <InputItem placeholder="Datos Maquilador" />
                                    </FormItem>
                                    <FormItem
                                      name="usoAutorizado"
                                      label="Uso Autorizado"
                                      margin
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      pattern
                                      tooltip={{ title: "Atributo condicional para registrar el uso autorizado del plaguicida o fertilizante de acuerdo a la regulación del país." }}
                                    >
                                      <InputItem placeholder="Uso Autorizado" />
                                    </FormItem>
                                  </>
                                )
                              }
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                    </BoxItem>
                    <HeaderItem>DETALLE MERCANCIA</HeaderItem>
                    <BoxItem>
                      {this.state.unidadPeso ?
                        <p style={{ textAlign: 'center' }}>Unidad de peso: {this.state.unidadPeso.nombre} - {this.state.unidadPeso.unidadSat.c_ClaveUnidad}</p>
                        : null}
                      <Row>
                        <Col span={12}>
                          <FormItem
                            label="Peso Bruto"
                            name="pesoBruto"
                            margin
                            numberCero
                          >
                            <InputNumberItem placeholder="Peso Bruto" />
                          </FormItem>
                          <FormItem
                            label="Peso Neto"
                            name="pesoNeto"
                            margin
                            numberCero
                          >
                            <InputNumberItem placeholder="Peso Neto" />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            label="Peso Tara"
                            name="pesoTara"
                            margin
                            numberCero
                          >
                            <InputNumberItem placeholder="Peso Tara" />
                          </FormItem>
                        </Col>
                      </Row>
                    </BoxItem>
                  </TabPane>
                  : null}
                {/* <TabPane tab="Conversiones" tabKey={4} key={5} forceRender>
                  <FormInitial.List name="conversiones">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <>
                              <Row>
                                <FormItem shouldUpdate noStyle>
                                  {({ getFieldValue }) => {
                                    const descripcionProducto =
                                      getFieldValue("descripcion");
                                    const conversiones =
                                      getFieldValue("conversiones");
                                    if (!conversiones[arrayKey]) return;
                                    return (
                                      <div style={{ width: "100%" }}>
                                        <h1 style={{ textAlign: "center" }}>
                                          1: "{descripcionProducto}" equivale a{" "}
                                          {conversiones[arrayKey].conversion}
                                          : "
                                          {conversiones[arrayKey].descripcion}"
                                        </h1>
                                      </div>
                                    );
                                  }}
                                </FormItem>
                                <Col span={12}>
                                  <FormItem
                                    label="Permitir Compra"
                                    name={[name, "compra"]}
                                    fieldKey={[arrayKey, "compra"]}
                                    margin
                                    initialValue={true}
                                    valuePropName="checked"
                                  >
                                    <SwitchItem />
                                  </FormItem>
                                  <FormItem
                                    {...restField}
                                    label="Descripción"
                                    name={[name, "descripcion"]}
                                    fieldKey={[arrayKey, "descripcion"]}
                                    required
                                    max={200}
                                    margin
                                    pattern
                                  >
                                    <InputItem placeholder="Descripción" />
                                  </FormItem>
                                  <FormItem
                                    {...restField}
                                    label="Conversion"
                                    name={[name, "conversion"]}
                                    fieldKey={[arrayKey, "conversion"]}
                                    required
                                    margin
                                    number
                                  >
                                    <InputNumberItem
                                      placeholder="Conversion"
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={12}>
                                  <FormItem label="Unidad" margin>
                                    <Input.Group compact>
                                      <FormItem
                                        {...restField}
                                        name={[name, "unidadId"]}
                                        fieldKey={[arrayKey, "unidadId"]}
                                        required
                                        margin
                                        pattern
                                        noStyle
                                      >
                                        <SelectItem
                                          placeholder="Unidad"
                                          width="90%"
                                        >
                                          {this.state.objetoUnidades}
                                        </SelectItem>
                                      </FormItem>
                                      <ButtonItem
                                        icon={<PlusOutlined />}
                                        style={{ width: "10%" }}
                                        onClick={this.abrirAgregarUnidad.bind(
                                          this
                                        )}
                                      />
                                    </Input.Group>
                                  </FormItem>
                                  <FormItem
                                    {...restField}
                                    label="Costo"
                                    name={[name, "costo"]}
                                    fieldKey={[arrayKey, "costo"]}
                                    required
                                    margin
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    numberCero
                                  >
                                    <InputNumberItem
                                      placeholder="Costo"
                                      dinero
                                      onChange={this.onChangeCosto.bind(this)}
                                    />
                                  </FormItem>
                                  <FormItem>
                                    <ButtonItem
                                      style={{
                                        alignSelf: "center",
                                        justifySelf: "center",
                                      }}
                                      type="dashed"
                                      onClick={() => remove(name)}
                                      block
                                      icon={<MinusCircleOutlined />}
                                    >
                                      Eliminar Conversion
                                    </ButtonItem>
                                  </FormItem>
                                </Col>
                              </Row>
                              <Divider
                                style={{ marginBottom: 10, marginTop: 5 }}
                              />
                            </>
                          )
                        )}
                        <FormItem noStyle>
                          <ButtonItem
                            style={{ marginTop: 10, marginBottom: 10 }}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Conversion
                          </ButtonItem>
                        </FormItem>
                      </>
                    )}
                  </FormInitial.List>
                </TabPane> */}
              </Tabs>
            </Form>
          </Spin>
        </div>
        <ModalItem
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
          spinning={false}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </ModalItem>
        <AgregarUnidad
          visible={this.state.modalAgregarUnidad}
          cerrarAgregarUnidad={this.cerrarAgregarUnidad.bind(this)}
        />
        <AgregarFamilia
          visible={this.state.modalAgregarFamilia}
          cerrarAgregarFamilia={this.cerrarAgregarFamilia.bind(this)}
        />
        <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    envios: state.empresa.envios,
    marbetes: state.empresa.marbetes,
  };
};

export default connect(mapStateToProps)(AgregarInsumo);
