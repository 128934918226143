import { PageHeader, Space, Tag, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {
  TableItem,
  dineroDisabledMask,
  ButtonItem,
  EditableTableDatePickerItem
} from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

moment.locale("es");

const {
  EditableRow,
  EditableCell
} = EditableTableDatePickerItem

class CuentasPorCobrar extends Component {
  state = {
    todasCuentasPorCobrar: [],
    todasCuentasPorCobrarCliente: [],
    agruparPorCliente: false,
    totalPagar: 0,
    loading: false,
  };
  componentDidMount() {
    this.consultarCuentasPorCobrar();
  }

  async consultarCuentasPorCobrar() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentasporcobrar/selectVentas",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          let totalPagar = 0;
          this.setState({
            todasCuentasPorCobrar: res.data.map((value) => {
              totalPagar += value.totalPagar - value.totalPagado;
              let numero = ''
              if (value.remision) {
                numero += (value.remisionSerie ? value.remisionSerie : 'R') + ' ' + value.remisionNumero
              }
              if (value.factura) {
                if (numero !== '') {
                  numero += ' '
                }
                numero += (value.facturaSerie ? value.facturaSerie : 'F') + ' ' + value.facturaNumero
              }
              return {
                ...value,
                proyectoNombre: value.proyecto.nombre,
                numero,
                clienteNombre: value.cliente.nombre
                  ? value.cliente.nombre +
                    " " +
                    value.cliente.apellidoPaterno +
                    " " +
                    value.cliente.apellidoMaterno
                  : value.cliente.razonSocial,
                saldoPendiente: value.totalPagar - value.totalPagado,
                accion: {
                  _id: value._id,
                  estadoPago: value.estadoPago,
                  fechaPago: value.fechaPago,
                  fechaVencimiento: value.fechaVencimiento,
                  cuentasXC: value.cuentasXC,
                },
              };
            }),
          });
          this.setState({ totalPagar: dineroDisabledMask(totalPagar) });
        }
      });
  }
  async consultarCuentasPorCobrarCliente() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentasporcobrar/selectCuentasPCliente",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          let totalPagar = 0;
          this.setState({
            todasCuentasPorCobrarCliente: res.data.map((value) => {
              totalPagar += (value.totalPagar - value.totalPagado)
              const clienteNombre =
                (value.cliente.persona
                  ? value.cliente.razonSocial
                  : value.cliente.nombre +
                    " " +
                    value.cliente.apellidoPaterno +
                    " " +
                    value.cliente.apellidoMaterno) +
                " - " +
                value.cliente.rfc;
              return {
                ...value,
                clienteNombre: clienteNombre,
                saldoPendiente: value.totalPagar - value.totalPagado,
                accion: {
                  _id: value._id,
                  fechaPago: value.fechaPago,
                  nombre: clienteNombre,
                  fechaVencimiento: value.fechaVencimiento,
                },
              };
            }),
          });
          this.setState({ totalPagar: dineroDisabledMask(totalPagar) });
        }
      });
  }
  cobrarVenta(id, cheque) {
    this.props.history.push("/panel/cobrarVenta", { id, cheque });
  }
  verCliente(id, nombre, cheque) {
    this.props.history.push("/panel/verVentasCliente", { id, nombre, cheque });
  }
  async agrupar() {
    const agrupar = !this.state.agruparPorCliente;
    if (agrupar) {
      await this.consultarCuentasPorCobrarCliente();
    } else {
      await this.consultarCuentasPorCobrar();
    }
    this.setState({ agruparPorCliente: agrupar });
  }

  async cambiarFechaPago(row) {
    await axios
    .post(
      "cuentasporcobrar/editarFechaPago",
      { ventaId: row._id, fechaPago: row.fechaPago,empresaId: this.props.empresaId },
      { headers: this.props.headersToken }
    )
    .then((res) => {
      if (res.data.success === 2) {
        message.success("Fecha Editada");
      } else {
        message.error("Sucedio un error y no se pudo editar la fecha");
      }
      this.consultarCuentasPorCobrar();
    });
  }

  render() {

    const fechaVencimiento = this.props.permisoCambiarFecha ? 
    {
      title: "Fecha de Vencimiento",
      dataIndex: "fechaPago",
      key: "fechaPago",
      sorter: (a, b) => {
        if (moment(a.fechaPago) < moment(b.fechaPago)) {
          return -1;
        }
        if (moment(a.fechaPago) > moment(b.fechaPago)) {
          return 1;
        }
        return 0;
      },
      render: (value) => moment(value).locale("es").format("DD-MMMM-YYYY"),
      wdith: "15%",
      editable: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "fechaPago",
        title: "Fecha de Vencimiento",
        handleSave: this.cambiarFechaPago.bind(this),
      }),
    } :
    {
      title: "Fecha de Vencimiento",
      dataIndex: "fechaPago",
      key: "fechaPago",
      sorter: (a, b) => {
        if (moment(a.fechaPago) < moment(b.fechaPago)) {
          return -1;
        }
        if (moment(a.fechaPago) > moment(b.fechaPago)) {
          return 1;
        }
        return 0;
      },
      render: (value) => moment(value).locale("es").format("DD-MMMM-YYYY"),
      wdith: "15%",
    }

    const columns = this.state.agruparPorCliente
      ? [
          {
            title: "Cliente",
            dataIndex: "clienteNombre",
            key: "clienteNombre",
            sorter: (a, b) => {
              if (a.clienteNombre < b.clienteNombre) {
                return -1;
              }
              if (a.clienteNombre > b.clienteNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "clienteNombre",
            width: "30%",
          },
          {
            title: "Fecha de Vencimiento",
            dataIndex: "fechaPago",
            key: "fechaPago",
            sorter: (a, b) => {
              if (moment(a.fechaPago) < moment(b.fechaPago)) {
                return -1;
              }
              if (moment(a.fechaPago) > moment(b.fechaPago)) {
                return 1;
              }
              return 0;
            },
            render: (value) => moment(value).locale("es").format("DD-MMMM-YYYY"),
            wdith: "15%",
          },
          {
            title: "Total a Pagar",
            dataIndex: "totalPagar",
            key: "totalPagar",
            sorter: (a, b) => {
              if (a.totalPagar < b.totalPagar) {
                return -1;
              }
              if (a.totalPagar > b.totalPagar) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "10%",
          },
          {
            title: "Total Pagado",
            dataIndex: "totalPagado",
            key: "totalPagado",
            sorter: (a, b) => {
              if (a.totalPagado < b.totalPagado) {
                return -1;
              }
              if (a.totalPagado > b.totalPagado) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "10%",
            align: "right",
          },
          {
            title: "Saldo Pendiente",
            dataIndex: "saldoPendiente",
            key: "saldoPendiente",
            sorter: (a, b) => {
              if (a.saldoPendiente < b.saldoPendiente) {
                return -1;
              }
              if (a.saldoPendiente > b.saldoPendiente) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "10%",
            align: "right",
          },
          {
            title: "Acción",
            dataIndex: "accion",
            key: "accion",
            render: (value, record, index) => {
              let buttons = [];
              if (this.props.permisoCobrar) {
                buttons.push(
                  <ButtonItem
                    key={index.toString()}
                    type="primary"
                    size="small"
                    onClick={() =>
                      this.verCliente(value._id, value.nombre, value.cheque)
                    }
                  >
                    Cobrar
                  </ButtonItem>
                );
              }
              if (moment() > moment(value.fechaVencimiento)) {
                buttons.push(
                  <Tag key={index.toString()} color="red">
                    Vencido {moment().diff(moment(value.fechaVencimiento), 'days')} dias
                  </Tag>
                );
              }
              return <Space>{buttons}</Space>;
            },
            width: "25%",
          },
        ]
      : [
          {
            title: "Codigo",
            dataIndex: "numero",
            key: "numero",
            sorter: (a, b) => {
              if (a.numero < b.numero) {
                return -1;
              }
              if (a.numero > b.numero) {
                return 1;
              }
              return 0;
            },
            buscar: "numero",
            width: "10%",
          },
          {
            title: "Proyecto",
            dataIndex: "proyectoNombre",
            key: "proyectoNombre",
            sorter: (a, b) => {
              if (a.proyectoNombre < b.proyectoNombre) {
                return -1;
              }
              if (a.proyectoNombre > b.proyectoNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "proyectoNombre",
            width: "13%",
          },
          {
            title: "Cliente",
            dataIndex: "clienteNombre",
            key: "clienteNombre",
            sorter: (a, b) => {
              if (a.clienteNombre < b.clienteNombre) {
                return -1;
              }
              if (a.clienteNombre > b.clienteNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "clienteNombre",
            width: "13%",
          },
          fechaVencimiento,
          {
            title: "Total a Cobrar",
            dataIndex: "totalPagar",
            key: "totalPagar",
            sorter: (a, b) => {
              if (a.totalPagar < b.totalPagar) {
                return -1;
              }
              if (a.totalPagar > b.totalPagar) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "8%",
          },
          {
            title: "Total Cobrado",
            dataIndex: "totalPagado",
            key: "totalPagado",
            sorter: (a, b) => {
              if (a.totalPagado < b.totalPagado) {
                return -1;
              }
              if (a.totalPagado > b.totalPagado) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "8%",
            align: "right",
          },
          {
            title: "Saldo Pendiente",
            dataIndex: "saldoPendiente",
            key: "saldoPendiente",
            sorter: (a, b) => {
              if (a.saldoPendiente < b.saldoPendiente) {
                return -1;
              }
              if (a.saldoPendiente > b.saldoPendiente) {
                return 1;
              }
              return 0;
            },
            render: (value) => dineroDisabledMask(value),
            width: "8%",
            align: "right",
          },
          {
            title: "Estado",
            dataIndex: "estadoPago",
            key: "estadoPago",
            render: (value, record, index) => {
              if (value === 0) {
                return (
                  <Tag key={index.toString()} color="blue">
                    No cobrada
                  </Tag>
                );
              }
              if (value === 2) {
                return (
                  <Tag key={index.toString()} color="orange">
                    Cobrado parcialmente
                  </Tag>
                );
              }
            },
            width: "10%",
          },
          {
            title: "Acción",
            dataIndex: "accion",
            key: "accion",
            render: (value, record, index) => {
              let buttons = [];
              if (
                (value.estadoPago === 0 || value.estadoPago === 2) &&
                this.props.permisoCobrar
              ) {
                buttons.push(
                  <ButtonItem
                    key={index.toString()}
                    type="primary"
                    size="small"
                    onClick={() => this.cobrarVenta(value._id, value.cheque)}
                  >
                    Cobrar
                  </ButtonItem>
                );
              }
              if(value.cuentasXC && moment().diff(moment(value.fechaVencimiento), 'days') > 0){
                buttons.push(
                  <Tag key={index.toString()} color="blue">
                    PUE {moment().diff(moment(value.fechaVencimiento), 'days')} dias
                  </Tag>
                );
              }else if(value.cuentasXC){
                buttons.push(
                  <Tag key={index.toString()} color="green">
                    PUE
                  </Tag>
                );
              }else if (moment() > moment(value.fechaVencimiento)) {
                buttons.push(
                  <Tag key={index.toString()} color="red">
                    Vencido {moment().diff(moment(value.fechaVencimiento), 'days')} dias
                  </Tag>
                );
              }
              return <Space>{buttons}</Space>;
            },
            width: "15%",
          },
        ];
    return (
      <>
        <PageHeader
          title={"Cuentas por Cobrar: " + this.state.totalPagar}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem onClick={this.agrupar.bind(this)} type="primary">
              {this.state.agruparPorCliente ? "Ventas" : "Agrupar por cliente"}
            </ButtonItem>
          }
        />
        <TableItem
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          loading={this.state.loading}
          size="small"
          dataSource={
            this.state.agruparPorCliente
              ? this.state.todasCuentasPorCobrarCliente
              : this.state.todasCuentasPorCobrar
          }
          columns={columns}
          rowClassName={() => 'editable-row'}
          pagination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoCobrar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cbbc8f30e81b54183cf57"
      )?.activado,
    permisoCambiarFecha:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472e5f74b2da7c85c1a8d18"
      )?.activado,
  };
};

export default connect(mapStateToProps)(CuentasPorCobrar);
