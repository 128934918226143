import { PageHeader, Space, Tooltip } from "antd";
import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { ButtonItem, TableItem, dineroDisabledMask } from "../../../Components/Items";
import { EyeOutlined } from "@ant-design/icons";
import MandarConsignacion from "../InventarioConsignaciones/MandarConsignacion/MandarConsignacion";

moment.locale("es");

class Consignaciones extends Component {
  state = {
    todasConsignaciones: [],
    loading: false,
    modalCancelacion: false,
    modalCancelacionId: null,
    modalEnvios: false,
    modalEnviosId: null
  };
  componentDidMount() {
    this.consultarOrdenes();
  }

  consultarOrdenes() {
    this.setState({ loading: true });
    axios
      .post(
        "consignaciones/selectOrdenes",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasConsignaciones: res.data.map((value) => {
              return {
                ...value,
                numero: (value.consignacionSerie + ' ' + value.consignacionNumero),
                cliente: (value.cliente.persona ? value.cliente.razonSocial : (value.cliente.nombre + ' ' + value.cliente.apellidoPaterno + ' ' + value.cliente.apellidoMaterno)) + " - " + value.cliente.rfc,
                accion: {
                  _id: value._id,
                  dataFactura: !!value.dataFactura,
                  estado: value.estado,
                  estadoCosteo: value.estadoCosteo,
                  servicios: value.servicios,
                  estadoPago: value.estadoPago,
                  facturaGlobal: value.facturaGlobal,
                  factura: value.factura,
                  contado: value.contado,
                  remision: value.remision,
                  cuentasXC: value.cuentasXC,
                  envios: value.envios,
                },
              };
            }),
          });
        }
      });
  }

  agregarConsignacion() {
    this.props.history.push('/panel/agregarConsignacion')
  }

  bajaConsignacion() {
    this.props.history.push('/panel/bajaConsignacion')
  }

  cerrarMandarConsignacion() {
    this.setState({
      modalCorreo: false,
      modalCorreoId: null,
    });
  }

  abrirVistaPrevia(id) {
    this.setState({
      modalCorreo: true,
      modalCorreoId: id,
    });
  }

  render() {
    const columns = [
      {
        title: "Numero",
        dataIndex: "numero",
        key: "numero",
        buscar: 'numero',
        width: "7%",
      },
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        sorter: (a, b) => {
          if (a.proyecto.nombre < b.proyecto.nombre) {
            return -1;
          }
          if (a.proyecto.nombre > b.proyecto.nombre) {
            return 1;
          }
          return 0;
        },
        render: (value) => value.nombre,
        width: "16%",
        ellipsis: true,
      },
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "cliente",
        sorter: (a, b) => {
          if (a.cliente < b.cliente) {
            return -1;
          }
          if (a.cliente > b.cliente) {
            return 1;
          }
          return 0;
        },
        width: "16%",
        buscar: 'cliente',
        ellipsis: true,
      },
      {
        title: "Fecha",
        dataIndex: "fechaRegistro",
        key: "fechaRegistro",
        sorter: (a, b) => {
          if (moment(a.fechaRegistro) < moment(b.fechaRegistro)) {
            return -1;
          }
          if (moment(a.fechaRegistro) > moment(b.fechaRegistro)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "13%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Subtotal",
        dataIndex: "subtotal",
        key: "subtotal",
        sorter: (a, b) => {
          if (a.subtotal < b.subtotal) {
            return -1;
          }
          if (a.subtotal > b.subtotal) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        align: "right",
        buscar: "subtotal",
        width: "8%",
        ellipsis: true,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => {
          if (a.total < b.total) {
            return -1;
          }
          if (a.total > b.total) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        buscar: "total",
        width: "8%",
        ellipsis: true,
        align: "right",
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (value, index) => {
          return (
            <Space direction="horizontal">
              <Tooltip title="Vista Previa">
                <EyeOutlined
                  size="small"
                  style={{ color: '#00b5e2' }}
                  key={index.toString()}
                  onClick={() => this.abrirVistaPrevia(value._id)}
                />
              </Tooltip>

            </Space>
          );
        },
        width: "22%",
      },
    ];
    return (
      <>
        <PageHeader
          title="Consignaciones"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Space>
              <ButtonItem
                key="agregarConsignacion"
                onClick={this.agregarConsignacion.bind(this)}
              >
                Agregar Consignacion
              </ButtonItem>
              <ButtonItem
                blanco
                key="bajaConsignacion"
                onClick={this.bajaConsignacion.bind(this)}
              >
                Baja Consignacion
              </ButtonItem>
            </Space>
          }
        />
        <TableItem
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verVenta", { id: record._id });
              },
            };
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasConsignaciones}
          columns={columns}
          pagination={false}
        />
                <MandarConsignacion
            visible={this.state.modalCorreo}
            id={this.state.modalCorreoId}
            cerrarMandarConsignacion={this.cerrarMandarConsignacion.bind(this)}
          />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    certificada: state.empresa.certificada,
    envios: state.empresa.envios,
  };
};

export default connect(mapStateToProps)(Consignaciones);
