import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import { Provider } from "react-redux";

import axios from "axios";
import store from "./store/store";


//Heroku: https://erp-rafa.herokuapp.com/erp/api
//Google: https://erp-backend-370000.wl.r.appspot.com/erp/api
//Localhost: http://192.168.100.6:3001/erp/api
// axios.defaults.baseURL = "http://192.168.100.6:3001/erp/api";

axios.defaults.baseURL = "/erp/api";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["credentials"] = "include";
axios.defaults.withCredentials = true


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
