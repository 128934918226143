import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { PageHeader, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { cuentaMask, TableItem, ButtonItem } from "../../../Components/Items";
import AgregarProyecto from "./AgregarProyecto/AgregarProyecto";
import EditarProyecto from "./EditarProyecto/EditarProyecto";

class Proyectos extends Component {
  state = {
    todasProyectos: [],
    modalAgregarProyecto: false,
    modalEditarProyecto: false,
    modalEditarProyectoId: null,
    loading: false
  }
  componentDidMount() {
    this.consultarProyectos();
  }

  consultarProyectos() {
    this.setState({loading: true})
    axios
    .post(
      "proyectos/selectTodosProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasProyectos: res.data.map(value => {
            return {...value, accion: { _id: value._id, estado: value.estado, consignacion: value.consignacion }}
          }),
        });
      }
    });
  }

  deshabilitarProyecto(value) {
    axios
      .post(
        "proyectos/deshabilitarProyecto",
        { proyectoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Proyecto deshabilitado");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarProyectos();
      });
  }
  habilitarProyecto(value) {
    axios
      .post(
        "proyectos/habilitarProyecto",
        { proyectoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Proyecto habilitado");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarProyectos();
      });
  }

  abrirAgregarProyecto() {
    this.setState({ modalAgregarProyecto: true });
  }
  cerrarAgregarProyecto() {
    this.setState({ modalAgregarProyecto: false });
    this.consultarProyectos();
  }
  abrirEditarProyecto(id) {
    this.setState({ modalEditarProyecto: true, modalEditarProyectoId: id });
  }
  cerrarEditarProyecto() {
    this.setState({ modalEditarProyecto: false });
    this.consultarProyectos();
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Tipo de Unidad",
        dataIndex: "tipoDeUnidad",
        key: "tipoDeUnidad",
        sorter: (a, b) => {
          if (a.tipoDeUnidad < b.tipoDeUnidad) {
            return -1;
          }
          if (a.tipoDeUnidad > b.tipoDeUnidad) {
            return 1;
          }
          return 0;
        },
        render: (value) => {
          if(value === 1){
            return 'Fisico'
          }
          if(value === 2){
            return 'Movil'
          }
        }
      },
      {
        title: "Unidad de negocio",
        dataIndex: "unidaddenegocio",
        key: "unidaddenegocio",
        render: (value) => {
          return cuentaMask(value.nivel1 + value.nivel2 + value.nivel3 + value.nivel4 +value.nivel5)+' - '+value.nombre
        }
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarProyecto && !value.consignacion &&
              <EditOutlined
                onClick={() => this.abrirEditarProyecto(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarProyecto && !value.consignacion &&
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este proyecto"
                  onConfirm={() => this.deshabilitarProyecto(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este proyecto"
                  onConfirm={() => this.habilitarProyecto(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title="Proyectos / Almacenes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarProyecto &&
            <ButtonItem
              type="primary"
              key="agregarProyecto"
              onClick={this.abrirAgregarProyecto.bind(this)}
            >
              Agregar Proyecto
            </ButtonItem>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasProyectos} columns={columns} />
        {this.props.permisoAgregarProyecto &&
                <AgregarProyecto
          visible={this.state.modalAgregarProyecto}
          cerrarAgregarProyecto={this.cerrarAgregarProyecto.bind(
            this
          )}
        />
        }
        {this.props.permisoEditarProyecto &&
                        <EditarProyecto
          visible={this.state.modalEditarProyecto}
          id={this.state.modalEditarProyectoId}
          cerrarEditarProyecto={this.cerrarEditarProyecto.bind(this)}
        />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarProyecto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ced77f30e81b54183cfc9")?.activado,
    permisoEditarProyecto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ceda1f30e81b54183cfca")?.activado,
    permisoDeshabilitarProyecto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cedeff30e81b54183cfcb")?.activado,
  };
};

export default connect(mapStateToProps)(Proyectos);