import {
    message,
    Spin,
    Row,
    Col,
    Select,
    Form as FormInitial,
    Space,
    Checkbox,
  } from "antd";
  import React, { Component } from "react";
  import {
    Form,
    FormItem,
    InputNumberItem,
    SelectItem,
    dineroDisabledMask,
    DatePickerItem,
    BoxItem,
    HeaderItem,
    InputItem,
    ButtonItem,
    TextAreaItem,
    SwitchItem
  } from "../../../../Components/Items";
  import moment from "moment";
  import axios from "axios";
  import { connect } from "react-redux";
  import {
    BookOutlined,
    DollarOutlined,
    DownloadOutlined,
    SendOutlined,
  } from "@ant-design/icons";
  import facturaPdf from "../../../../Pdf/facturaPdf";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import remisionPdf from "../../../../Pdf/remisionPdf";
import MandarCorreo from "../../Ventas/MandarCorreo/MandarCorreo";
import PagosVenta from "../../Ventas/PagosVenta/PagosVenta";
import MandarCorreoFacturaGlobal from "../../Ventas/MandarCorreoFacturaGlobal/MandarCorreoFacturaGlobal";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const { Option } = Select;
  
  class VerVentaConsignacion extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      factura: false,
      nombreFactura: '',
      objetoClientes: null,
      todasClientes: null,
      objetoProyectos: null,
      todasProyectos: null,
      objetoPrecios: null,
      todasPrecios: null,
      objetoFormasDePago: null,
      todasFormasDePago: null,
      objetoInsumos: null,
      todasInsumos: null,
      objetoUsosDeCFDI: null,
      todasUsosDeCFDI: null,
      objetoBancos: null,
      todasBancos: null,
      objetoUsuarios: null,
      todasUsuarios: null,
      objetoMonedas: null,
      todasMonedas: null,
      impuestos: [],
      total: 0,
      subtotal: 0,
      arrayImpuestos: [],
      arrayRetenciones: [],
      totalPagar: 0,
      modalCorreo: false,
      modalPagos: false,
      modalCorreoId: null,
      estado: 0,
      totalPagado: 0,
      id: this.props.location.state.id,
      facturaGlobal: false,
      facturaGlobalId: null,
      modalVerFacturaGlobal: false,
      modalVerFacturaGlobalId: null
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "ventas/selectDatosParaVenta",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({
              todasClientes: res.data.clientes,
              todasProyectos: res.data.proyectos,
              todasPrecios: res.data.precios,
              todasFormasDePago: res.data.formasDePago,
              todasUsosDeCFDI: res.data.usosDeCFDI,
              todasBancos: res.data.bancosYCajas,
              todasInsumos: res.data.insumosVenta,
              todasUsuarios: res.data.usuariosEmpresa,
              todasMonedas: res.data.monedas,
            });
            this.setState({
              objetoClientes: res.data.clientes.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
              objetoProyectos: res.data.proyectos.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
              objetoPrecios: res.data.precios.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
              objetoFormasDePago: res.data.formasDePago.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.descripcion}
                  </Option>
                );
              }),
              objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.descripcion}
                  </Option>
                );
              }),
              objetoBancos: res.data.bancosYCajas.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
              objetoInsumos: res.data.insumosVenta.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
              objetoUsuarios: res.data.usuariosEmpresa.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre +
                      " " +
                      value.apellidoPaterno +
                      " " +
                      value.apellidoMaterno}
                  </Option>
                );
              }),
              objetoMonedas: res.data.monedas.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre + " - " + value.abreviacion}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "ventas/datosVenta",
          { empresaId: this.props.empresaId, ventaId: this.state.id },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            message.error("Error no se encontro informacion de la venta");
          } else {
            this.formRef.current.setFieldsValue({
              ...res.data,
              factura: res.data.factura ? 1 : 2,
              fechaVenta: res.data.fechaVenta
                ? moment(res.data.fechaVenta)
                : moment(res.data.createdAt),
              fechaPago: moment(res.data.fechaPago),
              fechaVencimiento: moment(res.data.fechaVencimiento),
              insumos: res.data.insumos.map((insumo) => {
                const precioUnitarioConImpuestos =
                  insumo.precioUnitario + insumo.impuestos / insumo.cantidad;
                return { ...insumo, precioUnitarioConImpuestos };
              }),
            });
            this.setState({
              totalPagado: res.data.totalPagado,
              estado: res.data.estado,
              factura: res.data.factura,
              nombreFactura: (res.data.remision ? "Remision: " + res.data.remisionNumero : "") + ((res.data.remision && res.data.factura) ? ' - ' : '') +
                (res.data.factura ? "Factura: " + res.data.facturaNumero : ""),
              remision: res.data.remision,
              total: res.data.total,
              subtotal: res.data.subtotal,
              arrayImpuestos: res.data.arrayImpuestos,
              totalPagar: res.data.totalPagar,
              arrayRetenciones: res.data.arrayRetenciones,
              facturaGlobal: res.data.facturaGlobal,
              facturaGlobalId: res.data.facturaGlobalId,
            });
          }
        });
      this.setState({ loading: false });
    }
    atras() {
      this.props.history.goBack();
    }
    descargarVenta(value) {
      axios
        .post(
          "ventas/datosPdf",
          { ventaId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.factura) {
            var xmltext = res.data.respuestaFactura.data.cfdi;
  
            var filename = res.data.respuestaFactura.data.uuid + ".xml";
            var pom = document.createElement("a");
            var bb = new Blob([xmltext], { type: "text/plain" });
  
            pom.setAttribute("href", window.URL.createObjectURL(bb));
            pom.setAttribute("download", filename);
  
            pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
              ":"
            );
            pom.draggable = true;
            pom.classList.add("dragout");
  
            pom.click();
  
            const pdf = facturaPdf(res.data);
  
            pdfMake.createPdf(pdf).download(res.data.respuestaFactura.data.uuid + ".pdf");
          } else {
            const pdf = remisionPdf(res.data);
  
            pdfMake.createPdf(pdf).download();
          }
        });
    }
    enviarVenta(value) {
      this.setState({ modalCorreo: true, modalCorreoId: value });
    }
    cerrarMandarCorreo() {
      this.setState({
        modalCorreo: false,
        modalCorreoId: null,
      });
    }
    cerrarPagosVenta() {
      this.consultarTodo();
      this.setState({
        modalPagos: false,
      });
    }
    cerrarMandarCorreoFacturaGlobal() {
      this.setState({
        modalVerFacturaGlobal: false,
        modalVerFacturaGlobalId: null,
      });
    }
    verFacturaGlobal(facturaGlobalId) {
      this.setState({
        modalVerFacturaGlobal: true,
        modalVerFacturaGlobalId: facturaGlobalId,
      });
    }
    render() {
      return (
        <>
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <Spin
              spinning={this.state.loading}
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              <Form
                ref={this.formRef}
                name="verVentaConsignacion"
                layout
                bottom={
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Space>
                      {this.state.totalPagado > 0 && (
                        <ButtonItem
                          type="primary"
                          icon={<DollarOutlined />}
                          style={{
                            backgroundColor: "green",
                            borderColor: "green",
                          }}
                          onClick={() => this.setState({ modalPagos: true })}
                        >
                          Pagos
                        </ButtonItem>
                      )}
                      <ButtonItem
                        icon={<DownloadOutlined />}
                        blanco={true}
                        onClick={() => this.descargarVenta(this.state.id)}
                      >
                        Descargar
                      </ButtonItem>
                      {this.state.facturaGlobal ?
                        <ButtonItem
                          type="primary"
                          icon={<BookOutlined />}
                          naranja
                          onClick={() => this.verFacturaGlobal(this.state.facturaGlobalId)}
                        >
                          Factura Global
                        </ButtonItem>
                        : null}
                      <ButtonItem
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={() => this.enviarVenta(this.state.id)}
                      >
                        Mandar Correo
                      </ButtonItem>
                      <ButtonItem
                        type="primary"
                        danger
                        onClick={this.atras.bind(this)}
                      >
                        Regresar
                      </ButtonItem>
                    </Space>
                  </div>
                }
              >
                <Row>
                  <Col span={16}>
                    <HeaderItem>DATOS GENERALES</HeaderItem>
                    <BoxItem>
                      <Row>
                        <Col span={8}>
                          <h1 style={{ marginTop: '5px', fontSize: '15px' }}>
                            {this.state.nombreFactura}
                          </h1>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Fecha"
                            name="fechaVenta"
                            required
                            margin
                            initialValue={moment()}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 20 }}
                          >
                            <DatePickerItem
                              disabled
                              placeholder="Fecha de Venta"
                              allowClear={false}
                            />
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            required
                            noStyle
                            initialValue={1}
                            name="contado"
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <SelectItem width="100%" disabled>
                              <Option value={1}>
                                Contado - PUE : Pago en una sola
                                exhibición
                              </Option>
                              <Option value={2}>
                                Crédito sin enganche - PPD : Pago en
                                parcialidades o diferido
                              </Option>
                              <Option value={3} disabled>
                                Crédito con enganche - PPD : Pago en
                                parcialidades o diferido
                              </Option>
                            </SelectItem>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <FormItem
                            noStyle
                            shouldUpdate={(prev, curr) =>
                              prev.insumos?.length !== curr.insumos?.length
                            }
                          >
                            {({ getFieldValue }) => {
                              return (
                                <FormItem
                                  label="Cliente"
                                  name="clienteId"
                                  margin
                                  required
                                  labelCol={{ span: 2 }}
                                  wrapperCol={{ span: 22 }}
                                >
                                  <SelectItem placeholder="Cliente" disabled>
                                    {this.state.objetoClientes}
                                  </SelectItem>
                                </FormItem>
                              );
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <FormItem
                            label="Vendedor"
                            name="vendedorId"
                            margin
                            required
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 22 }}
                          >
                            <SelectItem placeholder="Vendedor" disabled>
                              {this.state.objetoUsuarios}
                            </SelectItem>
                          </FormItem>
                        </Col>
                      </Row>
                    </BoxItem>
                  </Col>
  
                  {/* <Col span={8}>
                    <HeaderItem>DATOS DE CONTACTO</HeaderItem>
                    <BoxItem>
                      <Row>
                        <FormItem
                          shouldUpdate={(prev, curr) =>
                            prev.clienteId !== curr.clienteId
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            let clienteId = getFieldValue("clienteId");
                            let cliente;
                            if (clienteId) {
                              cliente = this.state.todasClientes.find(
                                (value) => value._id === clienteId
                              );
                            }
                            return (
                              <>
                                <Col span={10}>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    RFC: {cliente?.rfc ? cliente.rfc : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Correo:{" "}
                                    {cliente?.correo ? cliente.correo : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Teléfono:{" "}
                                    {cliente?.telefono ? cliente.telefono : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Celular:{" "}
                                    {cliente?.celular ? cliente.celular : ""}
                                  </p>
                                </Col>
                                <Col span={14}>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Contacto:{" "}
                                    {cliente?.nombreContacto
                                      ? cliente.nombreContacto
                                      : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Tel. Contacto:{" "}
                                    {cliente?.telefonoContacto
                                      ? cliente.telefonoContacto
                                      : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Correo Contacto:{" "}
                                    {cliente?.correoContacto
                                      ? cliente.correoContacto
                                      : ""}
                                  </p>
                                  <p style={{ margin: 0, fontSize: 12.5 }}>
                                    Días de crédito:{" "}
                                    {cliente?.diasCredito
                                      ? cliente.diasCredito
                                      : ""}
                                  </p>
                                </Col>
                              </>
                            );
                          }}
                        </FormItem>
                      </Row>
                    </BoxItem>
                  </Col> */}
  
                  <Col span={8}>
                    <HeaderItem>MONEDA</HeaderItem>
                    <BoxItem>
                      <FormItem
                        required
                        margin
                        name="monedaId"
                        label="Moneda"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        initialValue='60c7e4396465ad440c41c15a'
                      >
                        <SelectItem disabled placeholder="Moneda">
                          {this.state.objetoMonedas}
                        </SelectItem>
                      </FormItem>
                      <FormItem
                        required
                        number
                        margin
                        label="T. Cambio"
                        name="tipoDeCambio"
                        type="number"
                        initialValue={1}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                      >
                        <InputNumberItem
                          disabled
                          dinero
                          placeholder="T. Cambio"
                        />
                      </FormItem>
                    </BoxItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <HeaderItem>PAGO</HeaderItem>
                    <BoxItem>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const contado = getFieldValue("contado");
                          if (contado === 1 || contado === 3) {
                            return (
                              <>
                                <FormItem
                                  label="Cuentas XC"
                                  name="cuentasXC"
                                  margin
                                  initialValue={false}
                                  valuePropName="checked"
                                >
                                  <SwitchItem disabled />
                                </FormItem>
                                <FormItem
                                  required
                                  margin
                                  name="bancoId"
                                  label="Banco"
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 18 }}
                                >
                                  <SelectItem placeholder="Banco" disabled>
                                    {this.state.objetoBancos}
                                  </SelectItem>
                                </FormItem>
                                {contado === 3 && (
                                  <FormItem
                                    required
                                    number
                                    margin
                                    label="Enganche"
                                    name="monto"
                                    type="number"
                                    max={this.state.total}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                  >
                                    <InputNumberItem
                                      dinero
                                      placeholder="Enganche"
                                      disabled
                                    />
                                  </FormItem>
                                )}
                              </>
                            );
                          }
                        }}
                      </FormItem>
                    </BoxItem>
                  </Col>
                  <Col span={18}>
                    <HeaderItem>FORMA DE PAGO</HeaderItem>
                    <BoxItem>
                      <FormItem
                        noStyle
                        shouldUpdate={(prev, curr) =>
                          prev.contado !== curr.contado ||
                          prev.factura !== curr.factura
                        }
                      >
                        {({ getFieldValue }) => {
                          const contado = getFieldValue("contado");
                          const factura = getFieldValue("factura");
                          const diasCredito =
                            contado !== 1 ? (
                              <FormItem
                                label="Días de crédito"
                                name="diasCredito"
                                number
                                margin
                                required
                                initialValue={0}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                              >
                                <InputNumberItem
                                  disabled
                                  placeholder="Días de Crédito"
                                />
                              </FormItem>
                            ) : null;
                          const usoCFDI =
                            factura === 1 ? (
                              <FormItem
                                name="usoDeCfdiId"
                                label="Uso de CFDI"
                                margin
                                required
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                              >
                                <SelectItem placeholder="Uso de CFDI" disabled>
                                  {this.state.objetoUsosDeCFDI}
                                </SelectItem>
                              </FormItem>
                            ) : null;
                          return (
                            <>
                              <Row>
                                <FormItem
                                  noStyle
                                  shouldUpdate={(prev, curr) =>
                                    prev.insumos?.length !== curr.insumos?.length
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    return (
                                      <>
                                        <Col span={8}>
                                          <FormItem
                                            label="Proyecto"
                                            name="proyectoId"
                                            margin
                                            required
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                          >
                                            <SelectItem
                                              placeholder="Proyecto"
                                              disabled
                                            >
                                              {this.state.objetoProyectos}
                                            </SelectItem>
                                          </FormItem>
                                        </Col>
                                        <Col span={8}>
                                          <FormItem
                                            name="precioId"
                                            label="Precio"
                                            margin
                                            required
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                          >
                                            <SelectItem
                                              placeholder="Precio"
                                              disabled
                                            >
                                              {this.state.objetoPrecios}
                                            </SelectItem>
                                          </FormItem>
                                        </Col>
                                        <Col span={8}>
                                          <FormItem
                                            name="ordenDeCompra"
                                            label="O Compra"
                                            margin
                                            pattern
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                          >
                                            <InputItem
                                              placeholder="Orden de Compra"
                                              disabled
                                            />
                                          </FormItem>
                                        </Col>
                                      </>
                                    );
                                  }}
                                </FormItem>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <FormItem
                                    name="formaDePagoId"
                                    label="F Pago"
                                    initialValue="6125149afe07662acc100d58"
                                    margin
                                    required
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                  >
                                    <SelectItem
                                      placeholder="Forma de Pago"
                                      disabled
                                    >
                                      {this.state.objetoFormasDePago}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                                <Col span={8}>{diasCredito || usoCFDI}</Col>
                              </Row>
                              <Row>
                                <Col span={8}>{diasCredito ? usoCFDI : null}</Col>
                              </Row>
                            </>
                          );
                        }}
                      </FormItem>
                    </BoxItem>
                  </Col>
                </Row>
                <FormInitial.List name="insumos">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th className="th-border" style={{ width: "20%" }}>
                                Insumo
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Proyecto
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                PU
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Neto
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Cant.
                              </th>
                              <th className="th-border" style={{ width: "8%" }}>
                                Desc.
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Subtotal
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Impuestos
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Total
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody key={arrayKey}>
                              <tr>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "insumoId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      autoFocus
                                      dropdownMatchSelectWidth={600}
                                      width="100%"
                                      placeholder="Insumo"
                                      disabled
                                    >
                                      {this.state.objetoInsumos}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    shouldUpdate={(prev, curr) =>
                                      prev.proyectoId !== curr.proyectoId
                                    }
                                    noStyle
                                  >
                                    {({ getFieldValue }) => {
                                      const proyectoId =
                                        getFieldValue("proyectoId");
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "proyectoId"]}
                                          required
                                          noStyle
                                          initialValue={proyectoId}
                                        >
                                          <SelectItem
                                            sinBorde
                                            width="100%"
                                            placeholder="Proyecto"
                                            disabled
                                          >
                                            {this.state.objetoProyectos}
                                          </SelectItem>
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "precioUnitario"]}
                                    noStyle
                                    required
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Precio Unitario"
                                      dinero
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "precioUnitarioConImpuestos"]}
                                    noStyle
                                    required
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Precio Unitario con Impuestos"
                                      dineroDisabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cantidad"]}
                                    number
                                    noStyle
                                    type="number"
                                  >
                                    <InputNumberItem sinBorde disabled />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "descuento"]}
                                    required
                                    number
                                    noStyle
                                    initialValue={0}
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      placeholder="Descuento"
                                      porcentaje
                                      max={100}
                                      disabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "subtotal"]}
                                    required
                                    number
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Subtotal"
                                      dineroDisabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "impuestos"]}
                                    required
                                    number
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      dineroDisabled
                                      placeholder="Impuestos"
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "total"]}
                                    required
                                    number
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      dineroDisabled
                                      disabled
                                      placeholder="Total"
                                    />
                                  </FormItem>
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                    </>
                  )}
                </FormInitial.List>
                <Row>
                  <Col span={12}>
                    <FormItem pattern name="observaciones" label="Observaciones">
                      <TextAreaItem
                        onChange={(e) => {
                          axios
                            .post("ventas/editarObservaciones", {
                              ventaId: this.state.id,
                              empresaId: this.props.empresaId,
                              observaciones: e.target.value,
                            })
                            .then((res) => {
                              if (res.data.success !== 2) {
                                message.info("La venta no se edito");
                              }
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Fecha probable Pago"
                      name="fechaPago"
                      required
                      margin
                    >
                      <DatePickerItem
                        placeholder="Fecha probable Pago"
                        allowClear={false}
                        disabled
                      />
                    </FormItem>
                    <FormItem
                      label="Fecha Vencimiento"
                      name="fechaVencimiento"
                      required
                      margin
                    >
                      <DatePickerItem
                        placeholder="Fecha Vencimiento"
                        allowClear={false}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem
                      label="Imprimir"
                      name="imprimirObservaciones"
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => {
                          axios
                            .post("ventas/editarImprimirObservaciones", {
                              ventaId: this.state.id,
                              empresaId: this.props.empresaId,
                              imprimirObservaciones: e.target.checked,
                            })
                            .then((res) => {
                              if (res.data.success !== 2) {
                                message.info("La venta no se edito");
                              }
                            });
                        }}
                      ></Checkbox>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <table className="table" style={{ marginTop: 0 }}>
                      <tbody>
                        <tr>
                          <th className="th-totales" style={{ width: "50%" }}>
                            Subtotal
                          </th>
                          <td
                            className="td-totales-right"
                            style={{ width: "50%" }}
                          >
                            {dineroDisabledMask(this.state.subtotal)}
                          </td>
                        </tr>
                      </tbody>
                      {this.state.arrayImpuestos && this.state.arrayImpuestos.map((valueArray) => (
                        <tbody>
                          <tr key={valueArray.descripcionFactura}>
                            <th className="th-totales">
                              {valueArray.descripcionFactura}
                            </th>
                            <td className="td-totales-right">
                              {dineroDisabledMask(valueArray.totalImpuestos)}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                      <tbody>
                        <tr>
                          <th className="th-totales">Total</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(this.state.total)}
                          </td>
                        </tr>
                      </tbody>
                      {this.state.arrayRetenciones && this.state.arrayRetenciones.map(
                        (valueArrayRetenciones) => (
                          <tbody>
                            <tr key={valueArrayRetenciones.descripcionFactura}>
                              <th className="th-totales">
                                {valueArrayRetenciones.descripcionFactura}
                              </th>
                              <td className="td-totales-right">
                                {dineroDisabledMask(
                                  valueArrayRetenciones.totalRetenciones
                                )}
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                      <tbody>
                        <tr>
                          <th className="th-totales">Total Pagar</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(this.state.totalPagar)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </div>
          <MandarCorreo
            visible={this.state.modalCorreo}
            id={this.state.modalCorreoId}
            cerrarMandarCorreo={this.cerrarMandarCorreo.bind(this)}
          />
          <PagosVenta
            visible={this.state.modalPagos}
            id={this.state.id}
            cerrarPagosVenta={this.cerrarPagosVenta.bind(this)}
          />
          <MandarCorreoFacturaGlobal
            visible={this.state.modalVerFacturaGlobal}
            id={this.state.modalVerFacturaGlobalId}
            history={this.props.history}
            cerrarMandarCorreoFacturaGlobal={this.cerrarMandarCorreoFacturaGlobal.bind(this)}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(VerVentaConsignacion);
  