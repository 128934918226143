import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  PageHeader,
  Row,
  Spin,
  Form as FormInitial,
  message,
  Select,
} from "antd";
import {
  BoxItem,
  ButtonItem,
  Form,
  HeaderItem,
  InputNumberItem,
  ModalItem,
  SelectItem,
  TextAreaItem,
  FormItem
} from "../../../../Components/Items";
import axios from "axios";

const { Option } = Select;

class GenerarOrdenDeCompraConRequisicion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProveedores: null,
    todasProveedores: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: null,
    todasInsumos: null,
    impuestos: [],
    numeroRequisicion: 0,
    modalElegirTipoDeProveedor: false,
    modalElegirTipoDeProveedorId: null,
    modalElegirTipoDeProveedorNombre: null,
    valuesForm: null,
    modalConfirmacion: false,
    numOrdenesAGenerar: 0,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  async consultarTodo() {
    this.setState({ loading: true });
    const responseProveedores = axios.post(
      "proveedores/selectProveedoresConTipoDeProveedor",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responseProyectos = axios.post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responseInsumos = axios.post(
      "insumos/selectInsumosCompra",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    );

    const responses = await Promise.all([
      responseProveedores,
      responseProyectos,
      responseInsumos,
    ]);

    let newState = {};

    if (responses[0].data.status === 404) {
    } else {
      newState.todasProveedores = responses[0].data;
      newState.objetoProveedores = responses[0].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      });
    }

    if (responses[1].data.status === 404) {
    } else {
      newState.todasProyectos = responses[1].data;
      newState.objetoProyectos = responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      });
    }

    if (responses[2].data.status === 404) {
    } else {
      newState.todasInsumos = responses[2].data;
      newState.objetoInsumos = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      });
    }

    await this.promisedSetState(newState);

    let secondState = { loading: false }

    await axios
      .post(
        "requisiciones/datosRequisicion",
        {
          empresaId: this.props.empresaId,
          requisicionId: this.props.location.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la requisición");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
          secondState.numeroRequisicion = res.data.numero
        }
      });

    await this.promisedSetState(secondState);
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    
    for (const valueInsumo of values.insumos) {
      if(valueInsumo.cantidad && !valueInsumo.proveedorId){
        return message.info('Seleccione los proveedores de los insumos que requiere')
      }
    }
    
    this.setState({ loading: true });

    axios
      .post(
        "requisiciones/generarOrdenesDeCompra",
        {
          ...values,
          requisicionId: this.props.location.state.id,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se han generado las ordenes de compra");
          this.formRef.current.resetFields();
          this.atras();
        } else if (res.data.success === 1) {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        } else {
          message.error("Hubo un error y no se generaron las ordenes de compra");
        }
      });
  }

  confimacionGenerar(values){
    let proveedores = []

    let cantidades = false

    for (const valueInsumo of values.insumos) {
        const proveedor = proveedores.find((valueTodos) => valueTodos.toString() === valueInsumo.proveedorId.toString())
    
        if(!proveedor && valueInsumo.pedir && valueInsumo?.pedir > 0){
            cantidades = true
            proveedores.push(valueInsumo.proveedorId.toString())
        }   
    }

    if(!cantidades){
        return message.info('Agregue cantidades para generar las ordenes de compra')
    }

  this.setState({ modalConfirmacion: true, valuesForm: values, numOrdenesAGenerar: proveedores.length })
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Generar Orden De Compra - Requisicion #"+this.state.numeroRequisicion}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="generarOrdenDeCompraConRequisicion"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Generar
                </ButtonItem>
              }
              onFinish={this.confimacionGenerar.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Proyecto"
                          name="proyectoId"
                          margin
                          required
                        >
                          <SelectItem disabled placeholder="Proyecto">
                            {this.state.objetoProyectos}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proyectoId = getFieldValue("proyectoId");
                            if (!proyectoId)
                              return <p>Seleccione un proyecto primero</p>;
                            let proyecto = this.state.todasProyectos.find(
                              (value) => value._id === proyectoId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre: {proyecto.nombre}
                                    </h1>
                                    <p>Descripcion: {proyecto.descripcion}</p>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Dirección de envio
                                    </h1>
                                    {proyecto.calle ? (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Calle: {proyecto.calle}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Numero: {proyecto.numeroCalle}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Marca: {proyecto.marca}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Modelo: {proyecto.modelo}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Placas: {proyecto.placas}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row></Row>
                  </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>EXTRAS</HeaderItem>
                  <BoxItem>
                    <FormItem
                      name="observaciones"
                      margin
                      labelCol={{ span: 3 }}
                      wrapperCol={{ span: 21 }}
                      pattern
                    >
                      <TextAreaItem disabled placeholder="Observaciones" />
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>INSUMOS</HeaderItem>
              <BoxItem>
                <FormInitial.List name="insumos">
                  {(fields) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th
                                className="th-border"
                                style={{ width: "30%" }}
                              >
                                Insumo
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "10%" }}
                              >
                                Cantidad
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "30%" }}
                              >
                                Proveedor
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "30%" }}
                              >
                                Cantidad A Pedir
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody key={arrayKey}>
                              <FormItem noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                  const insumos = getFieldValue("insumos");
                                  const insumo = insumos[arrayKey];
                                  return (
                                    <tr>
                                      <td className="td-border">
                                        <FormItem
                                          {...restField}
                                          name={[name, "insumoId"]}
                                          required
                                          noStyle
                                        >
                                          <SelectItem
                                            sinBorde
                                            autoFocus
                                            style={{ width: "100%" }}
                                            placeholder="Insumo"
                                            disabled
                                          >
                                            {this.state.objetoInsumos}
                                          </SelectItem>
                                        </FormItem>
                                      </td>
                                      <td className="td-border">
                                        <FormItem
                                          {...restField}
                                          name={[name, "cantidad"]}
                                          required
                                          number
                                          noStyle
                                        >
                                          <InputNumberItem
                                            sinBorde
                                            placeholder="Cantidad"
                                            disabled
                                          />
                                        </FormItem>
                                      </td>
                                      <td className="td-border">
                                        <FormItem
                                          {...restField}
                                          name={[name, "proveedorId"]}
                                          margin
                                          noStyle
                                          required
                                        >
                                          <SelectItem
                                            sinBorde
                                            style={{ width: "100%" }}
                                            placeholder="Proveedor"
                                          >
                                            {this.state.objetoProveedores}
                                          </SelectItem>
                                        </FormItem>
                                      </td>
                                      <td className="td-border">
                                        <FormItem
                                          {...restField}
                                          name={[name, "pedir"]}
                                          max={insumo.cantidadOrdenada ? (insumo.cantidad - insumo.cantidadOrdenada) : insumo.cantidad}
                                          numberCero
                                          type="number"
                                          noStyle
                                        >
                                          <InputNumberItem
                                            sinBorde
                                            placeholder={"Max: "+ (insumo.cantidadOrdenada ? (insumo.cantidad - insumo.cantidadOrdenada) : insumo.cantidad)}
                                          />
                                        </FormItem>
                                      </td>
                                    </tr>
                                  );
                                }}
                              </FormItem>
                            </tbody>
                          )
                        )}
                      </table>
                    </>
                  )}
                </FormInitial.List>
              </BoxItem>
            </Form>
          </Spin>
        </div>
        <ModalItem
          title="Confirmacion"
          visible={this.state.modalConfirmacion}
          onCancel={() => this.setState({ modalConfirmacion: false })}
          onOk={() => {
            this.setState({ modalConfirmacion: false });
            this.onFinish(this.state.valuesForm);
          }}
          spinning={false}
          footer
        >
          <h1 style={{ textAlign: "center" }}>Desea continuar</h1>
          <p style={{ textAlign: "center" }}>Se generaran {this.state.numOrdenesAGenerar} ordenes de compra</p>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(GenerarOrdenDeCompraConRequisicion);
