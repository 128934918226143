import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Divider,
  Select,
  Form as FormInitial,
} from "antd";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  NotificacionPolizas,
  ButtonItem
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import ElegirMarbetes from "../../Marbetes/ElegirMarbetes/ElegirMarbetes";

const { Option } = Select;

moment.locale("es");

class EntregarInsumosVentaConsignacion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosVenta: undefined,
    todasInsumos: null,
    objetoInsumos: null,
    todasProyectos: null,
    objetoProyectos: null,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "insumos/selectInsumosVenta",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "ventas/datosVentaPRecibirP",
        {
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error(
            "Error no se encontro informacion de la venta"
          );
        } else {
          this.setState({ datosVenta: res.data[0] });
          this.formRef.current.setFieldsValue({
            insumos: res.data[0].insumos.map(value => {
              return { ...value, precioUnitarioAjustado: (value.precioUnitario * (1 - (value.descuento / 100))) }
            })
          });
          let arrayValue = this.formRef.current.getFieldValue("insumos");
          arrayValue.map((value, key) => {
            this.onChangeInsumoProyecto('', key)
            return null
          })
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {

        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        )
      } else {
        message.error(
          "Error: Falta un campo"
        )
      }
      return null
    }
    );
  }
  onFinish(values) {
    let entregarTodos = 0;
    values.insumos = values.insumos.map(value => {
      let entregar = value.entregar ? value.entregar : 0
      entregarTodos += entregar
      return { ...value, entregar }
    })
    if (entregarTodos === 0) return message.info('Se neceitan cantidades para poder hacer esta operación')
    this.setState({ loading: true });
    axios
      .post(
        "ventas/entregarInsumos",
        {
          ...values,
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se han entregado insumos de la venta: " + res.data.codigo
          );
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error(res.data.message ? res.data.message : "No se pueden entregar los insumos");
        } else {
          message.error("Hubo un error y no se entregaron los insumos");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  async onChangeInsumoProyecto(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proyectoId = arrayValue[key].proyectoId;
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
    if (!insumo) return
    if (insumo.inventariable || insumo.insumoCompuesto) {
      await axios
        .post(
          "inventarios/inventarioInsumoProyecto",
          {
            proyectoId: proyectoId,
            insumoId: insumoId,
            noPrincipal: insumo.noPrincipal,
            insumoCompuesto: insumo.insumoCompuesto,
            empresaId: this.props.empresaId,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          arrayValue[key].cantidadProyecto = res.data.cantidad;
        });
    } else {
      arrayValue[key].cantidadProyecto = "No inventariable";
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  abrirElegirMarbetes(value, marbetes, insumoId, proyectoId) {
    if(proyectoId){
      this.setState({
        modalElegirMarbetes: true,
        modalElegirMarbetesIndex: value,
        modalElegirMarbetesMarbetes: marbetes || [],
        modalElegirMarbetesInsumoId: insumoId,
        modalElegirMarbetesProyectoId: proyectoId
      })
    }else{
      message.warning('Elegir un proyecto para elegir marbetes')
    }
  }
  cerrarElegirMarbetes(value) {
    const insumos = this.formRef.current.getFieldValue('insumos')
    insumos[this.state.modalElegirMarbetesIndex].marbetes = value
    this.formRef.current.setFieldsValue({
      insumos: insumos
    })
    this.setState({
      modalElegirMarbetes: false,
      modalElegirMarbetesIndex: null,
      modalElegirMarbetesMarbetes: null,
      modalElegirMarbetesInsumoId: null,
      modalElegirMarbetesProyectoId: null
    })
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Entregar Insumos sobre Venta"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha:{" "}
              {moment(this.state.datosVenta?.createdAt).format("LL")}
            </h1>
          }
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="entregarInsumosFactura"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Entregar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proyecto: {this.state.datosVenta?.proyecto.nombre}
                  </h1>
                  <p>
                    Descripcion:{" "}
                    {this.state.datosVenta?.proyecto.descripcion}
                  </p>
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>Dirección</h1>
                  {this.state.datosVenta?.proyecto.calle ? (
                    <>
                      <p style={{ margin: 0 }}>
                        Calle: {this.state.datosVenta?.proyecto.calle}
                      </p>
                      <p style={{ margin: 0 }}>
                        Numero:{" "}
                        {this.state.datosVenta?.proyecto.numeroCalle}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ margin: 0 }}>
                        Marca: {this.state.datosVenta?.proyecto.marca}
                      </p>
                      <p style={{ margin: 0 }}>
                        Modelo: {this.state.datosVenta?.proyecto.modelo}
                      </p>
                      <p style={{ margin: 0 }}>
                        Placas: {this.state.datosVenta?.proyecto.placas}
                      </p>
                    </>
                  )}
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Cliente:{" "}
                    {this.state.datosVenta?.cliente.nombre
                      ? this.state.datosVenta?.cliente.nombre +
                      " " +
                      this.state.datosVenta?.cliente
                        .apellidoPaterno +
                      " " +
                      this.state.datosVenta?.cliente.apellidoMaterno
                      : this.state.datosVenta?.cliente.razonSocial}
                  </h1>
                  <p style={{ margin: 0 }}>
                    Rfc: {this.state.datosVenta?.cliente.rfc}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo: {this.state.datosVenta?.cliente.correo}
                  </p>
                  <p style={{ margin: 0 }}>
                    Telefono:{" "}
                    {this.state.datosVenta?.cliente.telefono}
                  </p>
                  <p style={{ margin: 0 }}>
                    Celular: {this.state.datosVenta?.cliente.celular}
                  </p>
                </Col>
                <Col span={6}>
                  <p style={{ margin: 0 }}>
                    Contacto:{" "}
                    {this.state.datosVenta?.cliente.nombreContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Tel. Contacto:{" "}
                    {this.state.datosVenta?.cliente.telefonoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo Contacto:{" "}
                    {this.state.datosVenta?.cliente.correoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Dias de crédito:{" "}
                    {this.state.datosVenta?.cliente.diasCredito}
                  </p>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Row>
                <Col span={8}></Col>
                <Col span={8}>
                  <FormItem
                    label="Fecha de Entrega"
                    name="fechaEntrega"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Entrega"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
                <Col span={8}></Col>
              </Row>
              <FormInitial.List name="insumos">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "20%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Proyecto
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad Proyecto
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Precio Unitario
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad Pedida
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad Entregada
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad a entregar
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Accion
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                        <tbody key={fieldKey}>
                          <tr>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "insumoId"]}
                                fieldKey={[fieldKey, "insumoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                  sinBorde
                                  style={{ width: "100%" }}
                                  placeholder="Insumo"
                                  disabled
                                >
                                  {this.state.objetoInsumos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "proyectoId"]}
                                fieldKey={[fieldKey, "proyectoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                  sinBorde
                                  style={{ width: "100%" }}
                                  placeholder="Proyecto"
                                  onChange={(value) =>
                                    this.onChangeInsumoProyecto(
                                      value,
                                      arrayKey
                                    )
                                  }
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "cantidadProyecto"]}
                                required
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  disabled
                                  placeholder="Cantidad en Proyecto"
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "precioUnitarioAjustado"]}
                                fieldKey={[fieldKey, "precioUnitarioAjustado"]}
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Precio Unitario"
                                  disabled
                                  dineroDisabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "cantidad"]}
                                fieldKey={[fieldKey, "cantidad"]}
                                required
                                number
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Cantidad Vendida"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "entregado"]}
                                fieldKey={[fieldKey, "entregado"]}
                                required
                                numberCero
                                noStyle
                                initialValue={0}
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Cantidad Entregada"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  let cantidad =
                                    this.state.datosVenta.insumos[
                                      fieldKey
                                    ].cantidad;
                                  let entregado =
                                    this.state.datosVenta.insumos[
                                      fieldKey
                                    ].entregado;
                                  let max;
                                  if (entregado) {
                                    max = cantidad - entregado;
                                  } else {
                                    max = cantidad;
                                  }
                                  const insumos = getFieldValue("insumos");
                                  const cantidadProyecto =
                                    insumos[arrayKey].cantidadProyecto;
                                  let maxProyecto = 0;
                                  if (
                                    cantidadProyecto === "No inventariable"
                                  ) {
                                    maxProyecto = Infinity;
                                  } else {
                                    maxProyecto = cantidadProyecto;
                                  }
                                  const maxDisabled = Number(Math.min(max, maxProyecto))
                                  return (
                                    <FormItem
                                      {...restField}
                                      name={[name, "entregar"]}
                                      fieldKey={[fieldKey, "entregar"]}
                                      number
                                      noStyle
                                      max={maxDisabled}
                                      type="number"
                                    >
                                      <InputNumberItem
                                        sinBorde
                                        placeholder={"Max: " + maxDisabled}
                                      />
                                    </FormItem>
                                  );
                                }}
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <Row>
                                <Col span={24}>
                                  <FormItem
                                    noStyle
                                    shouldUpdate
                                  >
                                    {({ getFieldValue }) => {
                                      let arrayValue = getFieldValue("insumos");
                                      let mostrarMarbetes = false
                                      let insumoId
                                      let proyectoId
                                      if (arrayValue[arrayKey]?.insumoId && this.state.todasInsumos) {
                                        const insumo = this.state.todasInsumos.find(
                                          (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                        );
                                        mostrarMarbetes = insumo.marbetes
                                        insumoId = insumo._id
                                        proyectoId = arrayValue[arrayKey]?.proyectoId
                                      }
                                      if (mostrarMarbetes) {
                                        return (
                                          <ButtonItem
                                            type="primary"
                                            size='small'
                                            style={{ width: '80%', marginLeft: '10%' }}
                                            block
                                            onClick={() => {
                                              this.abrirElegirMarbetes(arrayKey, arrayValue[arrayKey].marbetes, insumoId, proyectoId)
                                            }}
                                          >
                                            Mar.
                                          </ButtonItem>
                                        )
                                      }
                                    }}
                                  </FormItem>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>
            </Form>
          </Spin>
        </div>
        <ElegirMarbetes
          visible={this.state.modalElegirMarbetes}
          cerrarElegirMarbetes={this.cerrarElegirMarbetes.bind(this)}
          marbetes={this.state.modalElegirMarbetesMarbetes}
          insumoId={this.state.modalElegirMarbetesInsumoId}
          proyectoId={this.state.modalElegirMarbetesProyectoId}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EntregarInsumosVentaConsignacion);
