import { message, Input, Select, } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  cuentaMask,
  InputItem,
  SwitchItem,
  ButtonItem,
} from "../../../../Components/Items";
import { PlusOutlined } from "@ant-design/icons";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import TextAreItem from "../../../../Components/TextAreaItem";

const { Option } = Select;

class EditarFamilia extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoCuentaContable: null,
    todasCuentaContable: null,
    modalAgregarCuentaContable: false,
    utilizado: false,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarEditarFamilia(codigo) {
    this.props.cerrarEditarFamilia(codigo);
    this.formRef.current.resetFields()
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "cuentascontables/selectCuentasContablesFinalesIE",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContableIE: res.data });
          this.setState({
            objetoCuentaContableIE: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "familias/datosFamilia",
        { empresaId: this.props.empresaId, familiaId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la familia");
        } else {
          if (res.data.utilizado) {
            message.info(
              "No se puede modificar toda la informacion ya que la familia ya fue utilizada"
            );
          }
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
          this.setState({
            utilizado: res.data.utilizado,
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "familias/editarFamilia",
        {
          empresaId: this.props.empresaId,
          familiaId: this.props.id,
          ...values,
        },
        {
          headers: {
            ...this.props.headersToken,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha editado una familia");
          this.formRef.current.resetFields();
          this.cerrarEditarFamilia(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede editar la familia");
        } else {
          message.error("Hubo un error y la familia no se edito");
        }
      });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo();
  }
  render() {
    return (
      <>
        <ModalItem
          title="Editar Familia"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarEditarFamilia.bind(this)}
          paddingTop
        >
          <Form
            ref={this.formRef}
            name="editarFamilia"
            layout
            bottom={
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Editar
              </ButtonItem>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <FormItem
              label="Gasto No Deducible"
              name="gastoNoDeducible"
              margin
              initialValue={false}
              tooltip={{
                title:
                  "l activarse hara que todos lo insumos registrados con esta familia se vayan a una cuenta de no gastos no deducibles al igual que sus impuestos, exeptuando las retenciones correspondientes. Solamente se podra aplicar a insumos de compra y no inventariables.",
              }}
              valuePropName="checked"
            >
              <SwitchItem disabled={this.state.utilizado} />
            </FormItem>
            <FormItem
              name="nombre"
              label="Nombre"
              required
              max={100}
              margin
              pattern
            >
              <InputItem placeholder="Nombre" />
            </FormItem>
            <FormItem name="descripcion" label="Descripción" margin pattern>
              <TextAreItem placeholder="Descripción" />
            </FormItem>
            <FormItem shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const gastoNoDeducible = getFieldValue("gastoNoDeducible");
                if (gastoNoDeducible) {
                  return (
                    <>
                      <FormItem label="Cuenta Gasto" margin>
                        <Input.Group compact>
                          <FormItem
                            name="cuentaGastoNoDeducibleId"
                            required
                            noStyle
                          >
                            <SelectItem
                              disabled={this.state.utilizado}
                              placeholder="Cuenta Gasto"
                              width="80%"
                            >
                              {this.state.objetoCuentaContableIE}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Transito" margin>
                        <Input.Group compact>
                          <FormItem name="cuentaTransitoId" required noStyle>
                            <SelectItem
                              placeholder="Cuenta Transito"
                              width="80%"
                              disabled={this.state.utilizado}
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            disabled={this.state.utilizado}
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                    </>
                  );
                } else {
                  return (
                    <>
                      <FormItem label="Cuenta Inventario" margin>
                        <Input.Group compact>
                          <FormItem name="cuentaInventarioId" required noStyle>
                            <SelectItem
                              placeholder="Cuenta Inventario"
                              width="80%"
                              disabled={this.state.utilizado}
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            disabled={this.state.utilizado}
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Costo/Gasto" margin>
                        <Input.Group compact>
                          <FormItem name="cuentaCostoGastoId" required noStyle>
                            <SelectItem
                              placeholder="Cuenta Costo/Gasto"
                              width="80%"
                              disabled={this.state.utilizado}
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            disabled={this.state.utilizado}
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Transito" margin>
                        <Input.Group compact>
                          <FormItem name="cuentaTransitoId" required noStyle>
                            <SelectItem
                              placeholder="Cuenta Transito"
                              width="80%"
                              disabled={this.state.utilizado}
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            disabled={this.state.utilizado}
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Ajuste Inventario" margin>
                        <Input.Group compact>
                          <FormItem
                            name="cuentaAjusteInventarioId"
                            required
                            noStyle
                          >
                            <SelectItem
                              placeholder="Cuenta Ajuste Inventario"
                              width="80%"
                              disabled={this.state.utilizado}
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            disabled={this.state.utilizado}
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Ingreso Contado" margin>
                        <Input.Group compact>
                          <FormItem
                            name="cuentaIngresoContadoId"
                            noStyle
                          >
                            <SelectItem
                              placeholder="Cuenta Ingreso Contado"
                              width="80%"
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Ingreso Credito" margin>
                        <Input.Group compact>
                          <FormItem
                            name="cuentaIngresoCreditoId"
                            noStyle
                          >
                            <SelectItem
                              placeholder="Cuenta Ingreso Credito"
                              width="80%"
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            icon={<PlusOutlined />}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                      <FormItem label="Cuenta Poder de Terceros" margin>
                        <Input.Group compact>
                          <FormItem
                            name="cuentaTercerosId"
                            noStyle
                          >
                            <SelectItem
                              placeholder="Cuenta Poder de Terceros"
                              width="80%"
                            >
                              {this.state.objetoCuentaContable}
                            </SelectItem>
                          </FormItem>
                          <ButtonItem
                            blanco
                            icon={<PlusOutlined />}
                            disabled={this.state.utilizado}
                            style={{ width: "20%" }}
                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                          />
                        </Input.Group>
                      </FormItem>
                    </>
                  );
                }
              }}
            </FormItem>
          </Form>
        </ModalItem>
        <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(
            this
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarFamilia);
